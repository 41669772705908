import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

const ExperienceForm = ({
  onNext,
  handleBack,
  onError,
  onComplete,
  initialData,
}) => {
  const [experienceData, setExperienceData] = useState([]);

  const [errors, setErrors] = useState([]);
  // useEffect(() => {
  //   const storedData = localStorage.getItem("experienceData");

  //   if (storedData) {
  //     setExperienceData((prevData) => {
  //       // Use a callback function to ensure the latest state
  //       return JSON.parse(storedData);
  //     });
  //   } else {
  //     // If no data is available, initialize with an empty project
  //     setExperienceData([]);
  //   }
  // }, []);
  useEffect(() => {
    const storedData = localStorage.getItem("experienceData");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        // console.log("Setting experienceData from localStorage:", parsedData);
        setExperienceData(parsedData);
      } catch (error) {
        console.error("Failed to parse stored experienceData data:", error);
      }
    } else {
      setExperienceData(initialData ? initialData?.internship : []);
    }
  }, [initialData]);

  useEffect(() => {
    localStorage.setItem("experienceData", JSON.stringify(experienceData));
  }, [experienceData]);

  const handleInputChange = (index, field, value) => {
    setExperienceData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [field]: value };
      return newData;
    });

    // Clear the error when user starts typing
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = { ...newErrors[index], [field]: false };
      return newErrors;
    });
  };
  const handleValidate = () => {
    let newErrors = [...errors];
    let isValid = true;

    experienceData?.forEach((project, index) => {
      Object.keys(project).forEach((key) => {
        if (!project[key]) {
          newErrors[index] = { ...newErrors[index], [key]: true };
          isValid = false;
        } else {
          newErrors[index] = { ...newErrors[index], [key]: false };
        }
      });
      const fromDate = new Date(project.fromDate);
      const toDate = new Date(project.toDate);

      if (fromDate > toDate) {
        newErrors[index] = {
          ...newErrors[index],
          fromDate: true,
          toDate: true,
        };
        isValid = false;
      } else {
        newErrors[index] = {
          ...newErrors[index],
          fromDate: false,
          toDate: false,
        };
      }
     // ✅ Count words in Role Description
     const wordCount = project.roleDescription
     ? project.roleDescription.trim().split(/\s+/).filter(Boolean).length
     : 0;

   // ✅ Check for minimum 100 words
   if (wordCount < 100) {
     newErrors[index] = { ...newErrors[index], roleDescription: true };
     isValid = false;
   } else {
     newErrors[index] = { ...newErrors[index], roleDescription: false };
   }
 });

    

    setErrors(newErrors);

    if (isValid) {
      onError(false);
      onComplete();
      localStorage.setItem("experienceData", JSON.stringify(experienceData));
    } else {
      onError(true);
    }
    return isValid;
  };

  const handleAddExperience = () => {
    const isFormvalid = handleValidate();
    if (isFormvalid) {
      const newExperience = {
        jobTitle: "",
        companyName: "",
        location: "",
        fromDate: "",
        toDate: "",
        roleDescription: "",
      };
      setExperienceData((prevData) => {
        const prevDataArray = Array.isArray(prevData) ? prevData : [];
        const newData = [...prevDataArray, newExperience];
        localStorage.setItem("experienceData", JSON.stringify(newData));
        return newData;
      });

      setErrors((prevErrors) => [
        ...prevErrors,
        {
          jobTitle: false,
          companyName: false,
          location: false,
          fromDate: false,
          toDate: false,
          roleDescription: false,
        },
      ]);
      handleValidate();
    }
  };
  const handleDeleteExperience = (index) => {
    setExperienceData((prevData) => {
      const newData = [...prevData];
      newData.splice(index, 1);
      localStorage.setItem("experienceData", JSON.stringify(newData));
      return newData;
    });

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors.splice(index, 1);
      return newErrors;
    });

    handleValidate();
  };
  const handleNext = () => {
    if (!experienceData || experienceData?.length === 0) {
      onNext([]);
      // alert("Please add at least one experience.");
      return;
    }
    let isFormValid = handleValidate();
    if (isFormValid) {
      onNext([...experienceData]);
      onError(false);
      onComplete();
    } else {
      alert("Please fill all the required fields");
      onError(true);
    }
  };

  return (
    <div className=" relative min-h-[620px] max-h-auto">
      <div className="p-2 md:p-2 lg:p-4">
        <h2 className=" text-lg text-center font-semibold text-slate-400">
          Add Your Work Experience
        </h2>
        {experienceData?.map((experience, index) => (
          <div
            key={index}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 border-2  rounded-md p-3 border-gray-200 my-10"
          >
            <div className="mb-4">
              <label
                htmlFor={`jobTitle_${index}`}
                className="block text-gray-700 text-sm font-bold"
              >
                Job Title
              </label>
              <input
                type="text"
                id={`jobTitle_${index}`}
                placeholder="Job Title"
                value={experience.jobTitle}
                onChange={(e) =>
                  handleInputChange(index, "jobTitle", e.target.value)
                }
                required
                className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                  errors[index]?.jobTitle ? "border-red-500" : ""
                }`}
              />
              {errors[index]?.jobTitle && (
                <p className="text-red-500 text-sm">This field is required</p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor={`companyName_${index}`}
                className="block text-gray-700 text-sm font-bold"
              >
                Company Name
              </label>
              <input
                type="text"
                id={`companyName_${index}`}
                placeholder="Company Name"
                value={experience.companyName}
                onChange={(e) =>
                  handleInputChange(index, "companyName", e.target.value)
                }
                required
                className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                  errors[index]?.companyName ? "border-red-500" : ""
                }`}
              />
              {errors[index]?.companyName && (
                <p className="text-red-500 text-sm">This field is required</p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor={`location_${index}`}
                className="block text-gray-700 text-sm font-bold md:mr-4"
              >
                Location
              </label>
              <input
                type="text"
                id={`location_${index}`}
                placeholder="Location"
                value={experience.location}
                onChange={(e) =>
                  handleInputChange(index, "location", e.target.value)
                }
                required
                className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                  errors[index]?.location ? "border-red-500" : ""
                }`}
              />
              {errors[index]?.location && (
                <p className="text-red-500 text-sm">This field is required</p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor={`fromDate_${index}`}
                className="block text-gray-700 text-sm font-bold md:mr-4"
              >
                From Date
              </label>
              <input
                type="date"
                id={`fromDate_${index}`}
                value={experience.fromDate}
                onChange={(e) =>
                  handleInputChange(index, "fromDate", e.target.value)
                }
                required
                className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                  errors[index]?.fromDate ? "border-red-500" : ""
                }`}
              />
              {errors[index]?.fromDate && (
                <p className="text-red-500 text-sm">This field is required</p>
              )}
            </div>
            <div className="mb-4">
              <label
                htmlFor={`toDate_${index}`}
                className="block text-gray-700 text-sm font-bold md:mr-4"
              >
                To Date
              </label>
              <input
                type="date"
                id={`toDate_${index}`}
                value={experience.toDate}
                onChange={(e) =>
                  handleInputChange(index, "toDate", e.target.value)
                }
                required
                className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                  errors[index]?.toDate ? "border-red-500" : ""
                }`}
              />
              {errors[index]?.toDate && (
                <p className="text-red-500 text-sm">
                  "From Date" must be earlier than "To Date".
                </p>
              )}
            </div>
            <div className="mb-4">
  <label
    htmlFor={`roleDescription_${index}`}
    className="block text-gray-700 text-sm font-bold"
  >
    Role Description
  </label>
  <textarea
    id={`roleDescription_${index}`}
    placeholder="Write at least 100 words"
    value={experience.roleDescription}
    onChange={(e) =>
      handleInputChange(index, "roleDescription", e.target.value)
    }
    rows={6}
    required
    className={`border-2 text-gray-950 mt-1 pl-1 h-20 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
      errors[index]?.roleDescription ? "border-red-500" : ""
    }`}
  />
  {errors[index]?.roleDescription && (
    <p className="text-red-500 text-sm">
      Role description must be at least 100 words.
    </p>
  )}
  <p className="text-sm text-gray-600 mt-1">
    {experience.roleDescription
      ? experience.roleDescription.trim().split(/\s+/).filter(Boolean).length
      : 0}
    /100
  </p>
</div>

            <div className="flex justify-between items-center">
              {experienceData.length > 1 && (
                <button
                  onClick={() => handleDeleteExperience(index)}
                  className="py-1 px-1 flex rounded-md border-2 border-red-300 gap-1 text-red-400 hover:bg-red-400 hover:text-white"
                >
                  <Icon
                    icon="bx:bx-trash"
                    className="font-bold"
                    height={20}
                    width={20}
                  />
                  <h2 className="">Delete</h2>
                </button>
              )}
            </div>
          </div>
        ))}
        <div className="flex justify-end mb-5">
          <button
            onClick={handleAddExperience}
            className=" py-1 px-1 flex rounded-md border-2 border-green-300 gap-1 text-green-400 hover:bg-green-400 hover:text-white"
          >
            <Icon
              icon="material-symbols:add"
              className=" font-bold"
              height={20}
              width={20}
            />
            <h2 className="">Add more</h2>
          </button>
        </div>
      </div>
      <div className="left-0 w-full mt-20 flex justify-between">
        <div className="ml-2 flex justify-start md:absolute md:bottom-2 mb-2">
          <Button
            className="mt-4"
            variant="contained"
            color="success"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        <div className=" flex justify-end md:absolute md:bottom-2 mb-2 mr-2 md:right-2">
          <Button
            className="mt-4 mb-4"
            variant="contained"
            color="secondary"
            onClick={handleNext}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExperienceForm;
