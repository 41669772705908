import { Icon } from "@iconify/react";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";

const ProjectDetailsForm = ({
  onNext,
  handleBack,
  onError,
  onComplete,
  initialData,
}) => {
  const [projectData, setProjectData] = useState([]);
  const [errors, setErrors] = useState([]);

  const errorMessages = {
    projectTitleRequired: "Project Title is required",
    projectDescriptionRequired: "Project Description is required",
    projectDescriptionMinLimit: "Project Description must be at least 100 words",
  };

  useEffect(() => {
    const storedData = localStorage.getItem("projectData");
    if (storedData) {
      try {
        setProjectData(JSON.parse(storedData));
      } catch (error) {
        console.error("Failed to parse stored project data:", error);
      }
    } else if (initialData?.projects?.length > 0) {
      setProjectData(initialData.projects);
    } else {
      setProjectData([{ projectTitle: "", projectDescription: "" }]);
    }
  }, [initialData]);

  useEffect(() => {
    localStorage.setItem("projectData", JSON.stringify(projectData));
  }, [projectData]);

  const countWords = (text) => text.trim().split(/\s+/).filter(Boolean).length;

  const handleInputChange = (index, field, value) => {
    setProjectData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], [field]: value };
      return newData;
    });

    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = { ...newErrors[index], [field]: false };
      return newErrors;
    });
  };

  const handleValidate = () => {
    let isValid = true;
    const newErrors = projectData.map((project) => {
      const wordCount = countWords(project.projectDescription);
      const errors = {
        projectTitle: !project.projectTitle,
        projectDescriptionRequired: !project.projectDescription,
        projectDescriptionMinLimit: wordCount < 100,
      };

      if (Object.values(errors).some((error) => error)) {
        isValid = false;
      }

      return errors;
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleAddProject = () => {
    if (handleValidate()) {
      setProjectData((prevData) => [
        ...prevData,
        { projectTitle: "", projectDescription: "" },
      ]);
      setErrors((prevErrors) => [
        ...prevErrors,
        { projectTitle: false, projectDescription: false },
      ]);
    }
  };

  const handleDeleteProject = (index) => {
    setProjectData((prevData) => prevData.filter((_, i) => i !== index));
    setErrors((prevErrors) => prevErrors.filter((_, i) => i !== index));
  };

  const handleNext = () => {
    if (projectData.length === 0) {
      alert("Please add at least one project.");
      return;
    }

    if (handleValidate()) {
      if (typeof onNext === "function") {
        onNext([...projectData]);
      }
      if (typeof onError === "function") {
        onError(false);
      }
      if (typeof onComplete === "function") {
        onComplete();
      }
    } else {
      if (typeof onError === "function") {
        onError(true);
      }
    }
  };

  return (
    <div className="relative min-h-[620px] max-h-auto">
      <div className="p-2 md:p-2 lg:p-4">
        <h2 className="text-lg text-center font-semibold text-slate-400">
          Add your project
        </h2>
        {projectData.map((project, index) => {
          const wordCount = countWords(project.projectDescription);

          return (
            <div
              key={index}
              className="grid grid-cols-1 md:grid-cols-1 gap-4 border-2 rounded-md p-3 border-gray-200 my-10"
            >
              <div className="mb-4">
                <label
                  htmlFor={`projectTitle_${index}`}
                  className="block text-gray-700 text-sm font-bold md:mr-4"
                >
                  Project Title
                </label>
                <input
                  type="text"
                  id={`projectTitle_${index}`}
                  placeholder="Project Title"
                  value={project.projectTitle}
                  onChange={(e) =>
                    handleInputChange(index, "projectTitle", e.target.value)
                  }
                  required
                  className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                    errors[index]?.projectTitle ? "border-red-500" : ""
                  }`}
                />
                {errors[index]?.projectTitle && (
                  <p className="text-red-500 text-sm">
                    {errorMessages.projectTitleRequired}
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor={`projectDescription_${index}`}
                  className="block text-gray-700 text-sm font-bold md:mr-4"
                >
                  Project Description
                </label>
                <textarea
                  id={`projectDescription_${index}`}
                  placeholder="Write at least 100 words"
                  value={project.projectDescription}
                  onChange={(e) =>
                    handleInputChange(index, "projectDescription", e.target.value)
                  }
                  required
                  className={`border-2 text-gray-950 mt-1 pl-1 h-20 md:h-32 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
                    errors[index]?.projectDescriptionMinLimit ? "border-red-500" : ""
                  }`}
                />
                {wordCount < 100 && (
                  <p className="text-red-500 text-sm">
                    {errorMessages.projectDescriptionMinLimit}
                  </p>
                )}
                <p className="text-sm text-gray-600 mt-1">{wordCount}/100</p>
              </div>

              <div className="flex justify-between items-center">
                {projectData.length > 1 && (
                  <button
                    onClick={() => handleDeleteProject(index)}
                    className="py-1 px-1 flex rounded-md border-2 border-red-300 gap-1 text-red-400 hover:bg-red-400 hover:text-white"
                  >
                    <Icon icon="bx:bx-trash" height={20} width={20} />
                    <h2>Delete</h2>
                  </button>
                )}
              </div>
            </div>
          );
        })}

        <div className="flex justify-end mb-5">
          <button
            onClick={handleAddProject}
            className="py-1 px-1 flex rounded-md border-2 border-green-300 gap-1 text-green-400 hover:bg-green-400 hover:text-white"
          >
            <Icon icon="material-symbols:add" height={20} width={20} />
            <h2>Add more</h2>
          </button>
        </div>
      </div>

       <div className="left-0 w-full flex justify-between">
             <div className="ml-4 md:bottom-5 mb-5">
               <Button
                 className="mt-4"
                 variant="contained"
                 color="success"
                 onClick={handleBack}
               >
                 Back
               </Button>
             </div>
             <div className="mr-2 md:bottom-1 mb-5 md:mb-10 md:right-12">
                       <Button
                         className="mt-4"
                         variant="contained"
                         color="secondary"
                         onClick={handleNext}
                       >
                         Continue
                       </Button>
                     </div>
           </div>
    </div>
  );
};

export default ProjectDetailsForm;
