import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import CustomError from "../../components/Error/CustomError";
import { Helmet } from "react-helmet-async";
import { JobDescriptionService } from "../../services/dataService";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Card, CardContent, Typography, Chip, Box, Backdrop
} from "@mui/material";
import { BASE_URL } from "../../services/configUrls";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from "@mui/icons-material/Work";
import PeopleIcon from "@mui/icons-material/People";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ReactHtmlParser from 'react-html-parser';
import ApartmentIcon from '@mui/icons-material/Apartment';
import Company_Logo from '../../assets/Talent-connect.png'
import {
  Briefcase,
  MapPin,
  BadgeIndianRupee,
  Calendar,
  Clock,
  Users,
  GraduationCap,
  Building2,
} from "lucide-react";
import Cookies from "js-cookie"

const JobDetails = () => {
  const location = useLocation();
  const rowData = location.state?.rowData;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jobDetailsData, setJobDetailsData] = useState();
  const [jobDetailsData1, setJobDetailsData1] = useState();
  const [isJobApplied, setIsJobApplied] = useState(false);
  const [isHaveResume, setIsHaveResume] = useState(false);
  const [error, setError] = useState(null);
  const [isEligible, setIsEligible] = useState(false);
  const [eligibilityError, setEligibilityError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAgreementDialog, setOpenAgreementDialog] = useState(false);
  const [bondAgreement, setBondAgreement] = useState(false);
  const [relocationAgreement, setRelocationAgreement] = useState(false);
  const [agreementResponses, setAgreementResponses] = useState({});
  const [documentForwardingAgreement, setDocumentForwardingAgreement] =
    useState(false);
  const jobId = rowData?.job_id;

  const handleBack = () => {
    navigate("/jobs/opening");
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    // setEligibilityError(null);
  };

  const handleCloseAgreementDialog = () => {
    setOpenAgreementDialog(false);
  };

  const checkEligibility = async (jobId) => {
    try {
      setLoading(true);
      const accessToken = localStorage.getItem("accessToken");
      const response = await fetch(
        `${BASE_URL}/talent/connect/checkEligibility`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ jobId: jobId.toString() }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        if (data.eligibility === 1) {
          setIsEligible(true);
          setEligibilityError(null);
          setOpenDialog(true);
          // setLoading(true);
        }  else if (data.eligibility === 0) {
          setIsEligible(false);
          setEligibilityError(
            "You are not eligible for this position based on the requirements."
          );
          setOpenDialog(true);
          // setLoading(true);
          // setTimeout(() => {
          //   setOpenDialog(false); // Ensure dialog closes properly
          // }, 100); // Adding a small delay to prevent state race condition
        }
      
      } else {
        setEligibilityError(
          data.message || "Failed to check eligibility. Please try again."
        );
        setOpenDialog(true);
      }
    } catch (error) {
      setEligibilityError(
        "An error occurred while checking eligibility. Please try again."
      );
      setOpenDialog(true);
    } finally {
      setLoading(false);
    }
  };

   // UseEffect to log the questions and answers
  
  const handleContinueApply = (job_id) => {
    setEligibilityError(null);
    checkEligibility(job_id);
  };

  const handleApply = () => {
    handleCloseDialog();
    setOpenAgreementDialog(true);
  };

  const handleProceed = () => {
    localStorage.removeItem("experienceData");
    localStorage.removeItem("profileData");
    localStorage.removeItem("careerObjective");
    localStorage.removeItem("educationData");
    localStorage.removeItem("skillData");
    localStorage.removeItem("projectData");
    localStorage.removeItem("form_data");
    localStorage.removeItem("step_completion");
    localStorage.removeItem("active_step")
    handleCloseAgreementDialog();
    navigate("/jobs/descriptions", {
      state: {
        rowData: {
          job_id: jobId,
          bondAgreement: bondAgreement,
          relocationAgreement: relocationAgreement,
          documentForwardingAgreement: documentForwardingAgreement,
        },
      },
    });
  };

  // Function to get cookie value
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  // Function to set cookie
  const setCookie = (name, value) => {
    document.cookie = `${name}=${value}; path=/; max-age=31536000`; // 1 year expiry
  };

  useEffect(() => {
    // Check for existing preferences in cookies
    const savedBondAgreement = getCookie("bondAgreement");
    const savedRelocationAgreement = getCookie("relocationAgreement");
    const savedDocumentForwardingAgreement = getCookie(
      "documentForwardingAgreement"
    );

    if (savedBondAgreement !== null) {
      setBondAgreement(savedBondAgreement === "1");
    }
    if (savedRelocationAgreement !== null) {
      setRelocationAgreement(savedRelocationAgreement === "1");
    }
    if (savedDocumentForwardingAgreement !== null) {
      setDocumentForwardingAgreement(savedDocumentForwardingAgreement === "1");
    }
  }, []);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        setLoading(true);
        const response = await JobDescriptionService.jobDescription(jobId);
        setJobDetailsData1(response?.data);
        console.log("the jobDetails In JobDetailsJsx:",response?.data);
        setIsJobApplied(response?.data?.is_applied_successfully);
        setIsHaveResume(response?.data?.is_resume);
        setJobDetailsData(response?.data?.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchJobDetails();
  }, [jobId]);
   // Parse company_agreement_question JSON string into an array
   const agreementQuestions = jobDetailsData?.company_agreement_question
   ? JSON.parse(jobDetailsData.company_agreement_question)
   : [];

 // Handle checkbox change
//  const handleCheckboxChange = (id, checked) => {
//    setAgreementResponses((prev) => ({
//      ...prev,
//      [id]: checked,
//    }));
//  };
useEffect(() => {
  // Get the current timestamp in ISO format
  const currentTimestamp = new Date().toISOString();

  // Prepare the responses to be logged with the timestamp
  const responsesToLog = agreementQuestions.map((question) => {
    return {
      question: question.question,  // The question text
      questionId: question.id,      // The question ID
      answer: agreementResponses[question.id] || "", // The user's answer, or empty if not filled
      timestamp: currentTimestamp,  // Add timestamp
    };
  });

  // Log the responses to the console as JSON
  Cookies.set("responsesToLog",JSON.stringify(responsesToLog))
// console.log(JSON.stringify(responsesToLog, null, 2));
}, [agreementResponses, agreementQuestions]); // Depend on agreementResponses and agreementQuestions

const handleChange = (id, value, type) => {
  setAgreementResponses((prevResponses) => ({
    ...prevResponses,
    [id]: type === "checkbox" ? value : value,
  }));
};
const handleInputChange = (id, value) => {
  setAgreementResponses((prevResponses) => ({
    ...prevResponses,
    [id]: value, // Properly updating state
  }));
};

const isProceedDisabled = !documentForwardingAgreement || agreementQuestions.some(
  (question) => question.isMandatory && !agreementResponses[question.id]
);

  const parseJsonSafely = (jsonString) => {
    try {
      return jsonString ? JSON.parse(jsonString) : [];
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return [];
    }
  };

  const skills = parseJsonSafely(jobDetailsData?.skill_required);
  const qualifications = parseJsonSafely(jobDetailsData?.qualification);
  const responsibilities = parseJsonSafely(jobDetailsData?.responsibility);
  // const selectionProcess = parseJsonSafely(jobDetailsData?.selection_process);
  const selectionProcess = Array.isArray(parseJsonSafely(jobDetailsData?.selection_process))
  ? parseJsonSafely(jobDetailsData?.selection_process)
  : [];

  const InfoCard = ({ icon: Icon, label, value }) => (
    <div className="flex items-start gap-3 bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors">
      <div className="bg-blue-100 p-2 rounded-lg flex-shrink-0">
        <Icon className="w-5 h-5 text-blue-600" />
      </div>
      <div className="w-[100%] flex-1">
        <p className="text-sm text-gray-600 mb-1">{label}</p>
        <p className="font-semibold text-gray-900">{value}</p>
      </div>
    </div>
  );

  const Section = ({ title, children, className = "" }) => (
    <div className={`mt-8 ${className}`}>
      <h2 className="text-xl font-semibold text-gray-900 mb-4 flex items-center gap-2">
        {title}
      </h2>
      {children}
    </div>
  );

  const ListSection = ({ title, items }) => (
    <Section title={title}>
      <div className="bg-white rounded-lg border border-gray-200">
        <ul className="divide-y divide-gray-200">
          {items.map((item, index) => (
            <li key={index} className="p-4 hover:bg-gray-50">
              <div className="flex items-start gap-3">
                <div className="flex-shrink-0 mt-1">
                  <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
                </div>
                <p className="text-gray-700 flex-1">{item}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </Section>
  );

  return (
    <>
    
        {jobDetailsData1?.usePreviousTemplate === 0 ? (

        <Card sx={{ maxWidth: "lg", margin: "auto",background:'#F4F2EE' }}>
        <Backdrop open={loading} sx={{ color: "#fff", zIndex: 1300 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {!loading && (
        <Card sx={{ maxWidth: "md", margin: "auto", padding: 2, boxShadow: 3 ,marginTop:'20px' ,borderRadius:'9px', marginBottom:'20px'}}>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="eligibility-dialog-title"
          aria-describedby="eligibility-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="eligibility-dialog-title">
            Eligibility Check Result
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="eligibility-dialog-description"
              className="mb-4"
            >
              {eligibilityError ||
                "Congratulations! You are eligible for this position."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {eligibilityError ? (
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            ) : (
              <>
                <Button onClick={handleCloseDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleApply}
                  color="primary"
                  variant="contained"
                  style={{ backgroundColor: "#22C55E" }}
                >
                  Apply Now
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
    
        {/* Agreements Dialog */}
        <Dialog
          open={openAgreementDialog}
          onClose={handleCloseAgreementDialog}
          aria-labelledby="agreement-dialog-title"
          aria-describedby="agreement-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="agreement-dialog-title">
            Terms and Conditions
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="agreement-dialog-description" className="mb-4">
              I accept and agree to the following Terms and contitions.
            </DialogContentText>
            <div className="mt-4 space-y-4">
              {/* Bond Agreement - Only show if bond details exist */}
              {jobDetailsData?.bond && jobDetailsData.bond.trim() !== "" && (
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-start gap-3">
                    <input
                      type="checkbox"
                      id="bondAgreement"
                      checked={bondAgreement}
                      onChange={(e) => {
                        setBondAgreement(e.target.checked);
                        setCookie("bondAgreement", e.target.checked ? "1" : "0");
                      }}
                      className="mt-1"
                    />
                    <label
                      htmlFor="bondAgreement"
                      className="text-sm text-gray-700"
                    >
                      I understand and acknowledge the bond agreement of{" "}
                      {jobDetailsData.bond}
                    </label>
                  </div>
                </div>
              )}
    
              {/* Relocation Agreement - Only show if location exists */}
              {jobDetailsData?.location &&
                jobDetailsData.location.trim() !== "" && (
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-start gap-3">
                      <input
                        type="checkbox"
                        id="relocationAgreement"
                        checked={relocationAgreement}
                        onChange={(e) => {
                          setRelocationAgreement(e.target.checked);
                          setCookie(
                            "relocationAgreement",
                            e.target.checked ? "1" : "0"
                          );
                        }}
                        className="mt-1"
                      />
                      <label
                        htmlFor="relocationAgreement"
                        className="text-sm text-gray-700"
                      >
                        I am willing to relocate to {jobDetailsData.location} if
                        required
                      </label>
                    </div>
                  </div>
                )}
                  {/* Dynamic Company Agreement Questions */}
                  {agreementQuestions.map((question) => (
  <div key={question.id} className="p-4 bg-gray-50 rounded-lg">
    <div className="flex items-start gap-3">
      {question.responseType !== "Yes/No" && question.responseType !== "Number" && question.responseType !== "Text" && (
        <input
          type="checkbox"
          id={`agreement-${question.id}`}
          checked={agreementResponses[question.id] || false}
          onChange={(e) => handleChange(question.id, e.target.checked, "checkbox")}
          className="mt-1"
        />
      )}
      <label htmlFor={`agreement-${question.id}`} className="text-sm text-gray-700 w-full">
        {question.question}
        {question.isMandatory && <span className="text-red-500 ml-1">*</span>}
      </label>
    </div>

    {/* Show input fields based on responseType */}
    {question.responseType === "Yes/No" && (
      <div className="mt-2 flex gap-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            name={`yesno-${question.id}`}
            value="Yes"
            checked={agreementResponses[question.id] === "Yes"}
            onChange={(e) => handleChange(question.id, e.target.value, "radio")}
            className="mr-2"
          />
          Yes
        </label>
        <label className="flex items-center">
          <input
            type="checkbox"
            name={`yesno-${question.id}`}
            value="No"
            checked={agreementResponses[question.id] === "No"}
            onChange={(e) => handleChange(question.id, e.target.value, "radio")}
            className="mr-2"
          />
          No
        </label>
      </div>
    )}

    {question.responseType === "Number" && (
      <input
        type="number"
        id={`input-${question.id}`}
        value={agreementResponses[question.id] || ""}
        onChange={(e) => handleInputChange(question.id, e.target.value)}
        className="mt-2 p-2 w-full border rounded-md focus:ring-2 focus:ring-blue-500"
        placeholder="Enter your response"
      />
    )}

    {question.responseType === "Text" && (
      <input
      type="text"
      id={`input-${question.id}`}
      value={agreementResponses[question.id] || ""}
      onChange={(e) => handleInputChange(question.id, e.target.value)}
      className="mt-2 p-2 w-full border rounded-md focus:ring-2 focus:ring-blue-500"
      placeholder="Enter your response"
    />
    
    )}
  </div>
))}

              {/* Document Forwarding Agreement - Always show */}
              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-start gap-3">
                  <input
                    type="checkbox"
                    id="documentForwardingAgreement"
                    checked={documentForwardingAgreement}
                    onChange={(e) => {
                      setDocumentForwardingAgreement(e.target.checked);
                      setCookie(
                        "documentForwardingAgreement",
                        e.target.checked ? "1" : "0"
                      );
                    }}
                    className="mt-1"
                    required
                  />
                  <label
                    htmlFor="documentForwardingAgreement"
                    className="text-sm text-gray-700"
                  >
                    I hereby grant permission to EduSkills to share my profile
                    with the employer for the job application process.
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAgreementDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleProceed}
              color="primary"
              variant="contained"
              style={{ backgroundColor: "#22C55E" }}
              disabled={isProceedDisabled}
              
            >
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
        <CardContent>
          <Box>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 6, justifyContent: "space-between", padding: 3  ,borderBottom:'solid 1px rgba(0, 0, 0, 0.2) '}}>
          {/* Company Logo */}
          <Box sx={{ flexShrink: 0  ,borderRadius:'9px'}}>
            <img src={Company_Logo} alt="Company Logo" style={{ width: "150px", height: "auto", boxShadow: 3,borderRadius:'9px',padding:'13%' }}  />
            {/* <ApartmentIcon sx={{ width: "150px", height: "auto", boxShadow: 3,borderRadius:'9px',color:'grey',padding:'13%'  }}/> */}
          </Box>
    
          {/* Job Details */}
          <Box sx={{ flex: 1 }}>
            <Typography variant="h5" component="div" gutterBottom>
              {jobDetailsData1?.data?.jd_title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {jobDetailsData1?.data?.company_name}
            </Typography>
    
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}>
              <Box sx={{background: '#1976d2', color: '#fff' ,borderRadius:'9px'  , padding: '2px 8px', display:'flex',alignItems:'center' }}>
              <LocationOnIcon fontSize="small" sx={{ 
                color: '#fff', // Light blue with transparency
        padding: '2px 4px', 
        borderRadius: '4px',fontSize:'22px' }} />
              <Typography variant="body2">{jobDetailsData1?.data ?.jd_location}</Typography>
              </Box>
              <Box sx={{background: '#1976d2',color: '#fff'  ,borderRadius:'9px'  , padding: '2px 8px', display:'flex',alignItems:'center' }}>
              <WorkIcon fontSize="small" sx={{ 
                color: '#fff' , // Light blue with transparency
        padding: '2px 4px', 
        borderRadius: '4px' ,fontSize:'22px'}} />
              <Typography variant="body2">{jobDetailsData1?.data ?.workplace_type}</Typography>
              </Box>
              <Box sx={{background: '#1976d2', color: '#fff' ,borderRadius:'9px'  , padding: '2px 8px', display:'flex',alignItems:'center' }}>
              <PeopleIcon fontSize="small" sx={{ // Light blue with transparency
        padding: '2px 4px', 
        borderRadius: '4px',fontSize:'22px',color: '#fff'  }} />
              <Typography variant="body2">Openings {jobDetailsData1?.data ?.no_of_opening}</Typography>
              </Box>
            </Box>
    
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}>
            <Box sx={{background: '#1976d2',color: '#fff' ,borderRadius:'9px'  , padding: '2px 8px', display:'flex',alignItems:'center' }}>
              <CurrencyRupeeIcon fontSize="small"  sx={{
        padding: '2px 4px', 
        borderRadius: '4px',fontSize:'22px',color: '#fff'  }} />
              <Typography variant="body2">{jobDetailsData1?.data ?.package}</Typography>
              </Box>
              <Box sx={{background: '#1976d2',color: '#fff' ,borderRadius:'9px'  , padding: '2px 8px', display:'flex',alignItems:'center' }}>
              <CalendarTodayIcon fontSize="small" sx={{ 
        padding: '2px 4px', 
        borderRadius: '4px',fontSize:'22px',color: '#fff'   }}/>
              <Typography variant="body2" >
                {jobDetailsData1?.data ?.reg_end
                  ? `${Math.floor((new Date(jobDetailsData1?.data ?.reg_end) - new Date()) / (1000 * 3600 * 24))} days remaining`
                  : ''}
              </Typography>
              </Box>
              <Box sx={{background: '#1976d2', color: '#fff' ,borderRadius:'9px'  , padding: '2px 8px', display:'flex',alignItems:'center' }}>
              <BusinessIcon fontSize="small" sx={{ 
                color: '#fff' ,
        padding: '2px 4px', 
        borderRadius: '4px',fontSize:'22px'  }} />
              
              <Typography variant="body2">{jobDetailsData1?.data ?.jobtype}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
          <Typography sx={{ mt: 2,fontWeight:'bold' }}>About The Company : </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
          {jobDetailsData1 ? ReactHtmlParser(jobDetailsData1?.data ?.company_description) : 'Description not available'}
         </Typography>
         <Box sx={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
       { jobDetailsData1?.is_applied_successfully === false ? (
        <Button variant="contained"  onClick={() =>
          handleContinueApply(jobDetailsData1?.data?.job_id)
        }  sx={{ mt: 2, background: 'blue' ,'&:hover': { backgroundColor: 'blue' } }}>
          {loading ? <><div style={{display:'flex',gap:1}}><CircularProgress size={18 } color="inherit" sx={{disabled:'true', marginRight: '8px'}}/>{' checking....'}</div></>: 'Check Eligibility'}
        </Button>
      ) : (
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          Already Applied
        </Typography>
      )}
    </Box>
    {/* <Section title="" className="mt-12">
                    <div className="flex flex-col sm:flex-row gap-4 justify-center">
                      {isJobApplied === false &&
                      jobDetailsData?.status === "Publish" ? (
                        <button
                          onClick={() =>
                            handleContinueApply(jobDetailsData?.job_id)
                          }
                          disabled={loading}
                          className={`px-8 py-3 text-white rounded-lg font-medium transition-colors min-w-[200px] ${
                            isEligible
                              ? "bg-green-500 hover:bg-green-600"
                              : "bg-blue-600 hover:bg-blue-700"
                          } disabled:bg-gray-400`}
                        >
                          {loading ? (
                            <div className="flex items-center justify-center gap-2">
                              <Oval
                                visible={true}
                                height="20"
                                width="20"
                                color="#ffffff"
                                secondaryColor="#ffffff"
                                ariaLabel="oval-loading"
                                strokeWidth={4}
                              />
                              <span>Checking...</span>
                            </div>
                          ) : isEligible ? (
                            "Check Eligibility"
                          ) : (
                            "Check Eligibility"
                          )}
                        </button>
                      ) : (
                        <button
                          disabled
                          className="px-8 py-3 bg-gray-400 text-white rounded-lg font-medium min-w-[200px]"
                        >
                          {isJobApplied ? "Already Applied" : "Closed"}
                        </button>
                      )}
                      <button
                        onClick={handleBack}
                        className="px-8 py-3 bg-gray-100 text-gray-700 rounded-lg font-medium hover:bg-gray-200 transition-colors min-w-[200px]"
                      >
                        Go Back
                      </button>
                    </div>
                  </Section> */}
          
    </Box>
        </CardContent>
      </Card>
       )}
      </Card>  ) :(
        <div className="min-h-screen bg-gray-100 py-6 px-4 sm:px-6 pb-24 md:pb-6">
        <Helmet>
          <title>EduSkills | Jobs Description</title>
        </Helmet>
    
        {/* Eligibility Check Dialog */}
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="eligibility-dialog-title"
          aria-describedby="eligibility-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="eligibility-dialog-title">
            Eligibility Check Result
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="eligibility-dialog-description"
              className="mb-4"
            >
              {eligibilityError ||
                "Congratulations! You are eligible for this position."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {eligibilityError ? (
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
            ) : (
              <>
                <Button onClick={handleCloseDialog} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={handleApply}
                  color="primary"
                  variant="contained"
                  style={{ backgroundColor: "#22C55E" }}
                >
                  Apply Now
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
    
        {/* Agreements Dialog */}
        <Dialog
          open={openAgreementDialog}
          onClose={handleCloseAgreementDialog}
          aria-labelledby="agreement-dialog-title"
          aria-describedby="agreement-dialog-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle id="agreement-dialog-title">
            Terms and Conditions
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="agreement-dialog-description" className="mb-4">
              I accept and agree to the following Terms and contitions.
            </DialogContentText>
            <div className="mt-4 space-y-4">
              {/* Bond Agreement - Only show if bond details exist */}
              {jobDetailsData?.bond && jobDetailsData.bond.trim() !== "" && (
                <div className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-start gap-3">
                    <input
                      type="checkbox"
                      id="bondAgreement"
                      checked={bondAgreement}
                      onChange={(e) => {
                        setBondAgreement(e.target.checked);
                        setCookie("bondAgreement", e.target.checked ? "1" : "0");
                      }}
                      className="mt-1"
                    />
                    <label
                      htmlFor="bondAgreement"
                      className="text-sm text-gray-700"
                    >
                      I understand and acknowledge the bond agreement of{" "}
                      {jobDetailsData.bond}
                    </label>
                  </div>
                </div>
              )}
    
              {/* Relocation Agreement - Only show if location exists */}
              {jobDetailsData?.location &&
                jobDetailsData.location.trim() !== "" && (
                  <div className="p-4 bg-gray-50 rounded-lg">
                    <div className="flex items-start gap-3">
                      <input
                        type="checkbox"
                        id="relocationAgreement"
                        checked={relocationAgreement}
                        onChange={(e) => {
                          setRelocationAgreement(e.target.checked);
                          setCookie(
                            "relocationAgreement",
                            e.target.checked ? "1" : "0"
                          );
                        }}
                        className="mt-1"
                      />
                      <label
                        htmlFor="relocationAgreement"
                        className="text-sm text-gray-700"
                      >
                        I am willing to relocate to {jobDetailsData.location} if
                        required
                      </label>
                    </div>
                  </div>
                )}
    
              {/* Document Forwarding Agreement - Always show */}
              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-start gap-3">
                  <input
                    type="checkbox"
                    id="documentForwardingAgreement"
                    checked={documentForwardingAgreement}
                    onChange={(e) => {
                      setDocumentForwardingAgreement(e.target.checked);
                      setCookie(
                        "documentForwardingAgreement",
                        e.target.checked ? "1" : "0"
                      );
                    }}
                    className="mt-1"
                    required
                  />
                  <label
                    htmlFor="documentForwardingAgreement"
                    className="text-sm text-gray-700"
                  >
                    I hereby grant permission to EduSkills to share my profile
                    with the employer for the job application process.
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAgreementDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleProceed}
              color="primary"
              variant="contained"
              style={{ backgroundColor: "#22C55E" }}
              disabled={!documentForwardingAgreement}
            >
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
    
        {error ? (
          <CustomError message={error} />
        ) : (
          <div className="max-w-5xl mx-auto">
            {jobDetailsData && (
              <div className="space-y-6">
                {/* Header Section */}
                <div className="bg-white rounded-xl shadow-sm p-6">
                  <div className="flex-1 min-w-0">
                    <h1 className="text-2xl font-bold text-gray-900 mb-2 break-words">
                      {jobDetailsData.job_title}
                    </h1>
                    <p className="text-xl text-gray-700 mb-4 break-words">
                      {jobDetailsData.company}
                    </p>
    
                    {/* Key Details Grid */}
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-4 mt-6">
                      <InfoCard
                        icon={Briefcase}
                        label="Job Role"
                        value={jobDetailsData.job_role}
                      />
                      <InfoCard
                        icon={MapPin}
                        label="Location"
                        value={jobDetailsData.location}
                      />
                      <InfoCard
                        icon={BadgeIndianRupee}
                        label="Package"
                        value={jobDetailsData.Package}
                      />
                      <InfoCard
                        icon={Users}
                        label="Openings"
                        value={jobDetailsData.no_of_post}
                      />
                      <InfoCard
                        icon={Clock}
                        label="Shifting Time"
                        value={jobDetailsData.shifting_time}
                      />
                    </div>
                  </div>
    
                  {/* Important Dates */}
                  <div className="mt-6 bg-gradient-to-r from-orange-50 to-amber-50 rounded-lg border border-orange-100 overflow-hidden">
                    <div className="p-4 border-b border-orange-100 bg-white/50">
                      <div className="flex items-center gap-2 text-orange-800">
                        <Calendar className="w-5 h-5" />
                        <span className="font-semibold">
                          Registration Deadline
                        </span>
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="flex items-baseline gap-3">
                        <div className="flex-1">
                          <div className="inline-flex items-center px-3 py-1 rounded-full bg-red-50 border border-red-100 text-red-600 text-sm font-medium">
                            Closes on
                          </div>
                          <p className="mt-2 text-xl font-semibold text-gray-900">
                            {(() => {
                              const date = new Date(jobDetailsData.reg_end);
                              const day = date.getDate();
                              const month = date.toLocaleString("en-US", {
                                month: "long",
                              });
                              const year = date.getFullYear();
    
                              // Add ordinal suffix
                              const getOrdinalSuffix = (day) => {
                                if (day > 3 && day < 21) return "th";
                                switch (day % 10) {
                                  case 1:
                                    return "st";
                                  case 2:
                                    return "nd";
                                  case 3:
                                    return "rd";
                                  default:
                                    return "th";
                                }
                              };
    
                              return `${day}${getOrdinalSuffix(
                                day
                              )} ${month} ${year}`;
                            })()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
                {/* Main Content */}
                <div className="bg-white rounded-xl shadow-sm p-6">
                  {/* Educational Requirements */}
                  <Section title="Educational Requirements">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <InfoCard
                        icon={GraduationCap}
                        label="10th Percentage"
                        value={`${jobDetailsData["10th"]}%`}
                      />
                      <InfoCard
                        icon={GraduationCap}
                        label="12th Percentage"
                        value={`${jobDetailsData["12th"]}%`}
                      />
                      <InfoCard
                        icon={GraduationCap}
                        label="Graduation CGPA"
                        value={`${jobDetailsData.Graduation} CGPA`}
                      />
                      <InfoCard
                        icon={Calendar}
                        label="Year of Passing"
                        value={jobDetailsData.year_of_passing}
                      />
                    </div>
                  </Section>
    
                  {/* Job Details Sections */}
                  {responsibilities && responsibilities.length > 0 && (
                    <ListSection
                      title="Roles and Responsibilities"
                      items={responsibilities}
                    />
                  )}
                  {skills && skills.length > 0 && (
                    <ListSection title="Required Skills" items={skills} />
                  )}
                  {qualifications && qualifications.length > 0 && (
                    <ListSection title="Qualifications" items={qualifications} />
                  )}
    
                  {/* Selection Process */}
                  <Section title="Selection Process">
                    <div className="space-y-4">
                      {selectionProcess.map((step, index) => (
                        <div
                          key={index}
                          className="flex items-start gap-4 bg-gray-50 p-4 rounded-lg"
                        >
                          <div className="flex-shrink-0 w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 font-semibold">
                            {index + 1}
                          </div>
                          <div className="flex-1 pt-1">
                            <p className="text-gray-700">{step}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Section>
    
                  {/* Additional Information */}
                  <Section title="Additional Information">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <InfoCard
                        icon={Clock}
                        label="Shifting Time"
                        value={jobDetailsData.shifting_time}
                      />
                      <InfoCard
                        icon={Calendar}
                        label="Bond Details"
                        value={
                          jobDetailsData.bond || "No bond information available"
                        }
                      />
                    </div>
                  </Section>
    
                  {/* Apply Button Section */}
                  <Section title="" className="mt-12">
                    <div className="flex flex-col sm:flex-row gap-4 justify-center">
                      {isJobApplied === false &&
                      jobDetailsData?.status === "Publish" ? (
                        <button
                          onClick={() =>
                            handleContinueApply(jobDetailsData?.job_id)
                          }
                          disabled={loading}
                          className={`px-8 py-3 text-white rounded-lg font-medium transition-colors min-w-[200px] ${
                            isEligible
                              ? "bg-green-500 hover:bg-green-600"
                              : "bg-blue-600 hover:bg-blue-700"
                          } disabled:bg-gray-400`}
                        >
                          {loading ? (
                            <div className="flex items-center justify-center gap-2">
                              <Oval
                                visible={true}
                                height="20"
                                width="20"
                                color="#ffffff"
                                secondaryColor="#ffffff"
                                ariaLabel="oval-loading"
                                strokeWidth={4}
                              />
                              <span>Checking...</span>
                            </div>
                          ) : isEligible ? (
                            "Check Eligibility"
                          ) : (
                            "Check Eligibility"
                          )}
                        </button>
                      ) : (
                        <button
                          disabled
                          className="px-8 py-3 bg-gray-400 text-white rounded-lg font-medium min-w-[200px]"
                        >
                          {isJobApplied ? "Already Applied" : "Closed"}
                        </button>
                      )}
                      <button
                        onClick={handleBack}
                        className="px-8 py-3 bg-gray-100 text-gray-700 rounded-lg font-medium hover:bg-gray-200 transition-colors min-w-[200px]"
                      >
                        Go Back
                      </button>
                    </div>
                  </Section>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    
      )}
      </>
  );
};

export default JobDetails;
