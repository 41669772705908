import { Button } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import React, { useEffect, useRef, useState } from "react";
import pp from "../../assets/imgs/pp.png";

const CandidateProfileForm = ({ onNext, onError, onComplete, initialData }) => {
  const [profileState, setProfileState] = useState({
    name: "",
    profile_pic: null,
    email: "",
    alt_email: "",
    phone: "",
    alt_phone: "",
    city: "",
  });
  const inputRef = useRef(null);

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    city: false,
    profile_pic: false,
  });
  //rgex validator
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[0-9]{10}$/;
  //...............load initial data if provided
  useEffect(() => {
    // console.log("initialData:", initialEductionData);
    const storedData = localStorage.getItem("profileData");
    // console.log(storedData, "data from local storage");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        // console.log("Setting educationData from localStorage:", parsedData);
        setProfileState(parsedData);
      } catch (error) {
        console.error("Failed to parse stored education data:", error);
      }
    } else {
      // console.log(initialData?.profile, "profile data");
      setProfileState(initialData ? initialData?.profile : profileState);
    }
  }, [initialData]);

  const handleInputChange = (field, value) => {
    setProfileState((prevState) => ({
      ...prevState,
      [field]: value || "",
    }));
    localStorage.setItem(
      "profileData",
      JSON.stringify({
        ...profileState,
        [field]: value || "",
      })
    );
  };
  const handleImageClick = () => {
    inputRef.current.click();
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 200 * 1024) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setProfileState((prevState) => ({
            ...prevState,
            profile_pic: reader.result, // Set Base64 data directly
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            profile_pic: false,
          }));
        };

        // Preserve the original file extension
        const extension = file.name.split(".").pop();
        const mimeType = `image/${extension}`;

        // Create a Blob with the correct MIME type
        const blob = new Blob([file], { type: mimeType });
        reader.readAsDataURL(blob); // Convert to Base64
      } else {
        setProfileState((prevState) => ({
          ...prevState,
          profile_pic: null,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          profile_pic: true,
        }));
      }
    }
  };

  const handleNext = () => {
    let newErrors = { ...errors };
    let isValid = true;

    if (!profileState || typeof profileState !== "object") {
      alert("please fill the form");
      return;
    }
    const mandatoryFields = ["name", "email", "phone", "city"];
    for (const key of mandatoryFields) {
      if (!profileState[key] || String(profileState[key]).trim() === "") {
        alert("Please fill your name, email, phone, city");
        isValid = false;
        return;
      }
    }
    if (profileState.email && !emailRegex.test(profileState.email)) {
      alert("Please fill valid email address");
      isValid = false;
      return;
    }

    if (profileState.phone && !phoneRegex.test(profileState.phone)) {
      alert("Please fill 10 digit phone number");
      isValid = false;
      return;
    }
    if (!isValid) {return;}

    Object.keys(profileState).forEach((key) => {
      if (
        !profileState[key] &&
        key !== "profile_pic" &&
        key !== "alt_email" &&
        key !== "alt_phone"
      ) {
        newErrors[key] = true;
        isValid = false;
      } else {
        newErrors[key] = false;
      }
      if (key === "email" && !emailRegex.test(profileState[key])) {
        newErrors[key] = true;
        isValid = false;
      }
      if (key === "phone" && !phoneRegex.test(profileState[key])) {
        newErrors[key] = true;
        isValid = false;
      }
    });

    if (!profileState.profile_pic) {
      newErrors.profile_pic = true;
      isValid = false;
    } else {
      newErrors.profile_pic = false;

      if (profileState.profile_pic instanceof File) {
        // Convert the profile picture to a data URL and store it in the profile state
        const reader = new FileReader();
        reader.onloadend = () => {
          const updatedProfileState = {
            ...profileState,
            profile_pic: reader.result,
          };

          setProfileState(updatedProfileState);
          // Store the updated profile data (including the profile picture) in local storage
          localStorage.setItem(
            "profileData",
            JSON.stringify({
              ...profileState,
              profile_pic: reader.result, // Save the data URL in local storage
            })
          );
        };

        reader.readAsDataURL(profileState.profile_pic);
      }
    }

    setErrors(newErrors);

    if (isValid) {
      onNext(profileState);
      onError(false);
      onComplete();
    } else {
      onError(true);
    }
  };

  return (
    <div className="">
      <div className="p-2 md:p-2 lg:p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
        <div className="mb-2 col-span-2 md:col-span-1 lg:col-span-1">
          <label
            htmlFor="name"
            className="block text-gray-700 text-sm font-bold md:mr-4"
          >
            Full Name
          </label>
          <input
            type="text"
            id="name"
            placeholder="Enter your Name"
            value={profileState?.name || ""}
            onChange={(e) => handleInputChange("name", e.target.value)}
            required
            className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
              errors.name ? "border-red-500" : ""
            }`}
          />
          {errors.name && (
            <p className="text-red-500 text-sm">
              Please provide your "Full Name"
            </p>
          )}
        </div>
        <div className="mb-2 col-span-2 md:col-span-1 lg:col-span-1">
          <div className=" md:ml-10">
            {profileState?.profile_pic ? (
              <img
                src={
                  profileState.profile_pic instanceof File
                    ? URL.createObjectURL(profileState.profile_pic)
                    : profileState.profile_pic
                }
                className="h-20 w-20 rounded-full"
                alt="profile"
              />
            ) : (
              <img src={pp} alt="profile" className="h-20 w-20 rounded-full" />
            )}

            <input
              type="file"
              id="profile_pic"
              accept="image/*"
              onChange={handleFileChange}
              ref={inputRef}
              required
              className="ml-4 hidden"
            />
            <Button
              variant="contained"
              startIcon={<CameraAltIcon />}
              color="info"
              onClick={handleImageClick}
              size="small"
              sx={{ marginTop: 1 }}
            >
              upload
            </Button>
          </div>
          {errors.profile_pic && (
            <p className="text-red-500 text-sm">
              Error: Please upload an image file with a size of 200KB or less.
            </p>
          )}
        </div>
        <div className="mb-4 md:-my-16 col-span-2 md:col-span-1 lg:col-span-1">
          <label
            htmlFor="email"
            className="block text-gray-700 text-sm font-bold"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            placeholder="Email"
            value={profileState?.email || ""}
            onChange={(e) => handleInputChange("email", e.target.value)}
            required
            className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
              errors.email ? "border-red-500" : ""
            }`}
          />
          {errors.email && (
            <p className="text-red-500 text-sm">
              {" "}
              Please provide a valid email address.
            </p>
          )}
        </div>
        <div className="mb-2 md:my-4 col-span-2 md:col-span-1 lg:col-span-1">
          <label
            htmlFor="altereEmail"
            className="block text-gray-700 text-sm font-bold"
          >
            Alter Email
          </label>
          <input
            type="email"
            id="altereEmail"
            placeholder="Enter an optional email"
            value={profileState?.alt_email || ""}
            onChange={(e) => handleInputChange("alt_email", e.target.value)}
            className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 `}
          />
        </div>
        <div className="mb-2 md:-my-24 col-span-2 md:col-span-1 lg:col-span-1">
          <label
            htmlFor="phone"
            className="block text-gray-700 text-sm font-bold md:mr-4"
          >
            Phone Number
          </label>
          <input
            type="tel"
            id="phone"
            placeholder="Phone Number"
            value={profileState?.phone || ""}
            onChange={(e) => handleInputChange("phone", e.target.value)}
            required
            className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
              errors.phone ? "border-red-500" : ""
            }`}
          />
          {errors.phone && (
            <p className="text-red-500 text-sm">
              {" "}
              Please provide a valid 10-digit phone number.
            </p>
          )}
        </div>
        <div className="mb-4 col-span-2 md:col-span-1 lg:col-span-1">
          <label
            htmlFor="alt_phone"
            className="block text-gray-700 text-sm font-bold md:mr-4"
          >
            Secondary phone
          </label>
          <input
            type="tel"
            id="alt_phone"
            placeholder="Enter your secondary phone"
            value={profileState?.alt_phone || ""}
            onChange={(e) => handleInputChange("alt_phone", e.target.value)}
            className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400`}
          />
        </div>
        <div className="mb-4 md:-my-24 col-span-2 md:col-span-1 lg:col-span-1">
          <label
            htmlFor="city"
            className="block text-gray-700 text-sm font-bold"
          >
            Present City
          </label>
          <input
            type="text"
            id="city"
            placeholder="Present City"
            v
            value={profileState?.city || ""}
            onChange={(e) => handleInputChange("city", e.target.value)}
            required
            className={`border-2 text-gray-950 mt-1 pl-1 h-10 rounded-md bg-gray-100 w-full focus:outline-sky-400 ${
              errors.city ? "border-red-500" : ""
            }`}
          />
          {errors.city && (
            <p className="text-red-500 text-sm">
              {" "}
              The field city name is required and must have a value
            </p>
          )}
        </div>
      </div>
      <div className=" md:absolute md:bottom-10 md:right-12 flex mr-2 justify-end mb-2">
        <Button variant="contained" color="secondary" onClick={handleNext}>
          Continue
        </Button>
      </div>
    </div>
  );
};

export default CandidateProfileForm;
