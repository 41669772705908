import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import OngoingTable from "./OngoingTable";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  Card,
  Drawer,
  useTheme,
  Tabs, Tab,Chip,CircularProgress,CardContent
} from "@mui/material";

import { styled } from "@mui/material/styles";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import FormTable from "./FormTable";
import {
  getInternshipCourseDetails,
  getOngoingInternships,
  profileGetDataService,
} from "../../services/dataService";
import { motion } from "framer-motion";
import { Hourglass } from "react-loader-spinner"; // Example loader component
// import DomainChoose from "../Domains Choose/DomainChoose";
import AvailableDomain from "../Domains Choose/AvailableDomain";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../services/configUrls";
import CopyDomainChoose from "../Domains Choose/CopyDomainChoose";
import { useNavigate } from "react-router-dom";
import { red } from "@mui/material/colors";
import OngoingTableOld from "./OngoingTableCopy";
import { tokens } from "../../theme";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import Certificate from "../../pages/Internship/Certificate";
import LongTermCertificate from "../../pages/Internship/ApscheLongTermCertificate";
import ApscheCertificate from "../../pages/Internship/ApscheCertificate";


/**
 * SearchableTable component renders a table with search and filter functionalities.
 * It fetches domain and internship data, and conditionally renders content based on the fetched data.
 *
 * @component
 * @param {Object[]} data - The data to be displayed in the table.
 * @param {string[]} columns - The columns to be displayed in the table.
 *
 * @example
 * const data = [
 *   { id: 1, name: 'John Doe', status: 'Completed' },
 *   { id: 2, name: 'Jane Doe', status: 'Enrolled' }
 * ];
 * const columns = ['id', 'name', 'status'];
 * <SearchableTable data={data} columns={columns} />
 *
 * @returns {JSX.Element} The rendered component.
 * 
 */
// const currentItems1 = [
//   {
//     sl_no: 1,
//     Domain: "MICROCHIP EMBEDDED SYSTEM DEVELOPER VIRTUAL INTERNSHIP",
//     Cohort: "AICTE Summer 2024",
//     Status: "Completed",
//   },
//   {
//     sl_no: 2,
//     Domain: "Data Science ",
//     Cohort: "AICTE Winter 2023",
//     Status: "Completed",
//   },
//   {
//     sl_no: 3,
//     Domain: "Cyber Security",
//     Cohort: "AICTE Spring 2024",
//     Status: "Completed",
//   },
//   {
//     sl_no: 4,
//     Domain: "Cloud Computing",
//     Cohort: "AICTE Summer 2024",
//     Status: "Completed",
//   },
//   {
//     sl_no: 5,
//     Domain: "Artificial Intelligence",
//     Cohort: "AICTE Fall 2023",
//     Status: "Completed",
//   },
//   {
//     sl_no: 6,
//     Domain: "Blockchain Technology",
//     Cohort: "AICTE Winter 2024",
//     Status: "Completed",
//   },
// ];


const SearchableTable = ({ data, columns }) => {
 
  const [searchQuery, setSearchQuery] = useState("");
  const [filterCriteria, setFilterCriteria] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true); // Loading state for data
  const [dataReady, setDataReady] = useState(false); // New state for conditional rendering
  const itemsPerPage = 5;
  const [domainId, setDomainId] = React.useState(0);
  const [preferedDomains, setPreferedDomains] = useState(null);
  const [profileData, setProfileData] = useState('');
  const [submitStatus, setSubmitStatus] = React.useState(false);
  const [processDocument, setProcessDocument] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [internship, setInternship] = useState([]);
  const [domains, setDomains] = useState([]);
  const [status, setStatus] = useState(0);
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const [isLongTermApplied, setIsLongTermApplied] = useState(0); // Assuming you get this from API response
  const [showEligibilityDialog, setShowEligibilityDialog] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [profileUpdateStatus, setProfileUpdateStatus] = useState(1);
  const [isFullScreen, setIsFullScreen] = useState(false);

useEffect(() => {
  const handleResize = () => {
    setIsFullScreen((activeTab === 1 || activeTab === 2) && window.innerWidth >= 800);
  };
  window.addEventListener("resize", handleResize);
  handleResize();
  return () => window.removeEventListener("resize", handleResize);
}, [activeTab]);

  useEffect(() => {
    getInternshipCourseDetails
      .coursesDetails()
      .then((response) => {
        // Check if response is an array or an object
        if (Array.isArray(response.data)) {
          // Handle if it's an array and not empty
          if (response.data.length > 0) {
            const { is_eligible_for_longterm } = response.data[0]; // Assuming eligibility info is in the first element
            setIsLongTermApplied(is_eligible_for_longterm);
          } else {
            // If data is an empty array, avoid showing the modal
            setIsLongTermApplied(null);
          }
        } else if (
          typeof response.data === "object" &&
          response.data !== null
        ) {
          // Handle if it's an object and has the required properties
          const { is_eligible_for_longterm } = response.data;
          if (is_eligible_for_longterm !== undefined) {
            setIsLongTermApplied(is_eligible_for_longterm);
          } else {
            // If the object doesn't contain eligibility information, avoid showing the modal
            setIsLongTermApplied(null);
          }
        }
      })
      .catch((error) => {
        // console.log("An error occurred while fetching course details:", error);
        setIsLongTermApplied(null); // Set to null in case of an error
      });
  }, []);

  useEffect(() => {
    // Show dialog based on eligibility when the user reaches the page
    if (
      isLongTermApplied !== null &&
      (isLongTermApplied === 0 || isLongTermApplied === 1)
    ) {
      setShowEligibilityDialog(true);
    } else {
      setShowEligibilityDialog(false);
    }
  }, [isLongTermApplied]);

  const handleCloseEligibilityDialog = () => {
    setShowEligibilityDialog(false);
  };

  // const handleCloseEligibilityDialog = () => {
  //   setShowEligibilityDialog(false);
  // };

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await profileGetDataService.profileData(); // Fetch the data from your service
        const data = response?.data;
        if (data?.profile_update_status === 0) {
          navigate("/profile");
          window.location.reload();
          return; // Don't continue with setting the profile form data
        }
        setProfileData(data);
        // console.log('The profile Data are:',data)
        setProfileUpdateStatus(data?.profile_update_status);
      } catch (error) {
        // console.error("Failed to fetch profile data:", error);
      }
    };

    fetchProfileData();
  }, []);

//   useEffect(() => {
//     if (activeTab === 1) {
//         navigate("/internship/certificate/long-term"); // Redirect to the new page
//     }
// }, [activeTab, navigate]);

  if (profileUpdateStatus === 0) {
    navigate("/profile");
  }

  useEffect(() => {
    // Simulate data loading
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/domain/domainChoose`, {
          headers: {
            Authorization: `Bearer ${token}`, // Including the token in Authorization header
          },
        });
        setPreferedDomains(response);
        setIsLoading(true);
        // console.log("pratyukti:",response)
        
        // console.log(response);
        setDomains(response.data.is_status); // Assuming response.data is the array of domains
        // setStatus(response.data[0]); // Assuming response.data[0] contains the status
      } catch (error) {
        // console.error("Error fetching domains:", error);
        // toast.error("Failed to load domains", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      } finally {
        setIsLoading(false);
      }
    };

    fetchDomains();
  }, [token]);

  useEffect(() => {
    const fetchOngoingInternshipData = async () => {
      try {
        const response = await getOngoingInternships.ongoingInternshipsData();
        if (response.data && response.data.internships) {
          const data = response.data.internships;
          setInternship(data);
          setDomainId(data[0].domain_id);
          setSubmitStatus(data[0].submit_status);
          setProcessDocument(data[0].process_document);
          setPercentage(data[0].percent);
        } else {
          throw new Error("Invalid response structure");
        }
      } catch (error) {
        // console.error("Error fetching initial data:", error);
      } finally {
        setDataReady(true); // Data is now ready for conditional rendering
      }
    };
    fetchOngoingInternshipData();
  }, []);

  const filteredData = data
    .filter((item) =>
      Object.values(item).some((value) =>
        value.toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
    )
    .filter((item) =>
      filterCriteria === "all" ? true : item.status === filterCriteria
    ); 

  const handleFilterChange = (newFilter) => {
    setFilterCriteria(newFilter);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (
      pageNumber >= 1 &&
      pageNumber <= Math.ceil(filteredData.length / itemsPerPage)
    ) {
      setCurrentPage(pageNumber);
    }
  };

  // Conditionally render based on the dataReady state
  if (!dataReady) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Hourglass type="ThreeDots" color="#00BFFF" height={60} width={60} />
      </div>
    );
  }

  // if (domainId === 32 && submitStatus === false) {
  //   return <FormTable />;
  // }

  // if (submitStatus === true && domainId === 32) {
  //   return (
  //     <div className="flex flex-col items-center justify-center h-screen text-center bg-gray-100">
  //       <div className="bg-white p-8 rounded-lg shadow-lg max-w-md flex flex-col items-center">
  //         <Icon
  //           icon="mdi:check-circle"
  //           width={64}
  //           height={64}
  //           className="text-green-500 mb-4"
  //         />
  //         <h1 className="text-3xl font-semibold text-gray-800">
  //           Form Submitted Successfully!
  //         </h1>
  //         <p className="mt-4 text-sm text-gray-600">
  //           You have already submitted your genAI public request url
  //           successfully. Thank you for your submission.
  //         </p>
  //         <button
  //           //onClick={() => (window.location.href = "/home")}
  //           className="mt-6 px-6 py-3 bg-blue-500 text-white rounded-full shadow hover:bg-blue-600 transition-all duration-200"
  //         >
  //           You Can Logout Now
  //         </button>
  //       </div>
  //     </div>
  //   );
  // }
  const StatusBanner = styled("div")(({ status }) => ({
    backgroundColor:
    status === "Completed"
        ? "#4CAF50" // Green
        : status === "In Progress"
        ? "#1976D2" // Blue
        : "#D32F2F", // Red
    color: "white",
    textAlign: "center",
    fontWeight: "bold",
    padding: "8px",
    // borderTopLeftRadius: "8px",
    // borderTopRightRadius: "8px",
    borderRadius:4
  }));
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
   
    // Fetch the appropriate data based on the selected tab
    // if (newValue === 0) {
    //   fetchCurrentJobs();
    // } else {
    //   fetchArchivedJobs();
    // }
  };
  const userName = localStorage.getItem("userName");
  const formattedUserName = userName
    ? userName
        .split("@")[0] // Get the part before '@'
        .replace(/(?:^|\s|\.)(.)/g, (match, letter) => letter.toUpperCase()) // Capitalize the first letter after space or dot
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add a space between camelCase
    : "";
  
  return (
 <Card sx={{borderRadius: 3,margin:'0 auto', maxWidth: "100%",marginBottom:'30px', background: '#fff', minHeight: "88vh" ,width:'100%' ,border:'none'}}>
    {/* <div> */}
       <motion.div
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
       <Card
        sx={{
          p: 2,
          borderRadius: 3,
          boxShadow: 3,
          backgroundColor: "#eee",
         
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: "95%",
          mx: "auto",
          my: 2,
        }}
      >
        {/* Left Section */}
        <Box>
          
          {/* <Typography variant="h5" fontWeight="bold">
          We're excited to see what you'll achieve,{profileData.first_name} 
          </Typography> */}
         
      <Typography fontWeight="bold" variant="body1" mt={1}>
        You have completed {currentItems.length} internships.
      </Typography>

        </Box>

        {/* Right Section (Days Left) */}
        {/* <Box
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            borderRadius: "50%",
            width: 80,
            height: 80,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        > */}
          {/* <Typography variant="h5" fontWeight="bold" color="#60A5FA">
            {/* {daysLeft}
          </Typography>
          <Typography variant="body2" fontSize={12}>
            DAYS LEFT
          </Typography> */}
        {/* </Box> */}
      </Card>
    </motion.div>
    <Tabs
  value={tabValue}
  onChange={handleTabChange}
  sx={{
    marginBottom: '1px',
    marginLeft: '10px',
    // backgroundColor: '#f5f5f5', // Light background
    borderRadius: '12px', // Rounded corners
    padding: '4px', // Adds some padding around the tabs
    '& .MuiTabs-indicator': {
      display: 'none', // Hides the default underline
    },
  }}
>
<Tab
    label="Apply"
    sx={{
      borderRadius: 6, // Rounded tab shape
      textTransform: 'none', // Keeps text normal
      padding: '2px 6px',

      margin: '4px',
      minHeight: '40px',
      fontWeight: 'bold',
      border:'1px solid #163ad9',
     
      color: '#163ad9',
      '&.Mui-selected': {
        backgroundColor: '#3452d9', // Active tab color
        color: 'white',
      },
     
    }}
  />
  <Tab
    label="Ongoing"
    sx={{
      borderRadius: 6, // Rounded tab shape
      textTransform: 'none', // Keeps text normal
      padding: '2px 6px',

      margin: '4px',
      minHeight: '40px',
      fontWeight: 'bold',
      border:'1px solid #163ad9',
     
      color: '#163ad9',
      '&.Mui-selected': {
        backgroundColor: '#3452d9', // Active tab color
        color: 'white',
      },
     
    }}
  />
  <Tab
    label="Completed"
    sx={{
      borderRadius: 6,
      textTransform: 'none',
      padding: '2px 6px',
      margin: '4px',
      minHeight: '40px',
      border:'1px solid #163ad9',
      color: '#163ad9',
      fontWeight: 'bold',
      '&.Mui-selected': {
        backgroundColor: '#3452d9', // Active tab color
        color: 'white',
      },
      // '&:hover': {
      //   backgroundColor: 'rgba(30, 10, 242, 0.95)',
      //   border:'1px solid black', // Even lighter on hover
      // },
      
    }}
  />
</Tabs>



      {/* <Card sx={{boxShadow:3 ,margin:"20px",background: `${colors.primary[400]} !important`,}}><Box sx={{padding:' 2px 10px'}}>
        <Typography  >Hello Welcome {formattedUserName}</Typography>
        </Box></Card> */}
      {tabValue === 2 &&
       <div className="w-full overflow-x-auto  p-4 rounded-md">
       <div className="md:flex justify-between mb-4">
         <div className="mb-4 md:mb-0 md:mr-4">
           <input
             type="text"
             placeholder="Search..."
             value={searchQuery}
             onChange={(e) => setSearchQuery(e.target.value)}
             className="p-2 rounded-md w-full md:w-60 shadow-sm outline-sky-300 border-2 border-gray-300 text-gray-900"
           />
         </div>
         <div className="text-gray-900">
           <label className="mr-2 md:mt-0">Filter by:</label>
           <select
             value={filterCriteria}
             onChange={(e) => handleFilterChange(e.target.value)}
             className="border-2 border-gray-300 p-2 rounded-md"
           >
             <option value="all">All</option>
             <option value="Apply">Apply</option>
             <option value="Enrolled">Enrolled</option>
             <option value="Completed">Completed</option>
           </select>
         </div>
       </div>
       <div className="table-container">
         {isLoading ? (
           <SkeletonTable columns={columns} itemsPerPage={itemsPerPage} />
         ) : (
           <table className="w-full mb-5">
             <thead className="text-base bg-gray-800 text-gray-100 shadow-sm rounded-md">
               <tr className="shadow-sm">
                 {columns.map((column) => (
                   <th
                     key={column}
                     className={`py-2 px-4 text-base ${
                       column === "title" ? "text-left pl-20" : "text-center"
                     } border-b`}
                   >
                     {column}
                   </th>
                 ))}
               </tr>
             </thead>
             {filteredData.length === 0 ? (
               <tbody>
                 <tr>
                   <td
                     colSpan={columns.length}
                     className="text-center text-gray-500 pt-10"
                   >
                     No records found.
                   </td>
                 </tr>
               </tbody>
             ) : (
               <tbody className="table-body">
                 {currentItems.map((item, index) => (
                   <tr
                     key={item.sl_no}
                     className={`border-b text-center text-base ${
                       index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
                     }`}
                   >
                     {columns.map((column) => (
                       <td
                         key={column}
                         className={`py-2 px-4 text-base ${
                           column === "Domain"
                             ? "text-left pl-20"
                             : "text-center"
                         } border-b`}
                       >
                         {column === "status" ? (
                           item["status"] === "Completed" ? (
                             <span className="bg-green-100 text-green-800 py-1 px-3 rounded-full">
                               Completed
                             </span>
                           ) : item["status"] === "Failed" ? (
                             <span className="bg-blue-100 text-blue-800 py-1 px-3 rounded-full">
                               Fail
                             </span>
                           ) : (
                             <span className="bg-yellow-100 text-yellow-800 py-1 px-3 rounded-full">
                               Enrolled
                             </span>
                           )
                         ) : column === "Action" ? (
                           item["Status"] === "Completed" ? (

                            <button
                        onClick={() => setShowPopup(true)}
                        className="inline-flex items-center justify-center px-4 py-2 text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 rounded-md"
                      >
                        Download Certificate
                      </button>
                            //  <a
                            //    href="https://aicte-internship.eduskillsfoundation.org/pages/home/"
                            //    target="_blank"
                            //    rel="noopener noreferrer"
                            //    className="inline-flex items-center justify-center px-4 py-2 text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 rounded-md"
                            //  >
                            //    {/* <Icon
                            //      icon="fluent:document-save-20-filled"
                            //      height={22}
                            //      width={22}
                            //      className="ml-12 cursor-pointer text-green-400"
                            //    /> */}
                            //    Download Certificate
                            //  </a>
                           ) : item["status"] === "Failed" ? (
                             <Icon
                               icon="codicon:git-stash-apply"
                               width={22}
                               height={22}
                               className="ml-12 cursor-pointer"
                             />
                           ) : (
                             <span className="bg-red-700 text-lg text-white py-1 px-5 rounded-full">
                               Fail
                             </span>
                           )
                         ) : (
                           item[column]
                         )}
                       </td>
                     ))}
                   </tr>
                 ))}
               </tbody>
             )}
           </table>
           
         )}
       </div>
       {!isLoading && (
         <div className="flex justify-end mt-6">
           <button
             className={`mx-2 px-3 py-1 ${
               currentPage === 1
                 ? "bg-gray-300 text-gray-600"
                 : "bg-blue-500 text-white"
             } rounded-md shadow-md`}
             onClick={() => paginate(currentPage - 1)}
             disabled={currentPage === 1}
           >
             Previous
           </button>
           <span className="mx-2 text-base">
             Page {currentPage} of{" "}
             {Math.ceil(filteredData.length / itemsPerPage)}
           </span>
           <button
             className={`mx-2 px-3 py-1 ${
               indexOfLastItem >= filteredData.length
                 ? "bg-gray-300 text-gray-600"
                 : "bg-blue-500 text-white"
             } rounded-md shadow-md`}
             onClick={() => paginate(currentPage + 1)}
             disabled={indexOfLastItem >= filteredData.length}
           >
             Next
           </button>
         </div>
       )}
       {/* <Divider sx={{ marginTop: "60px" }} /> */}
       {/* {(percentage !== 100 && internship.length !== 0) && <OngoingTable />} */}
       {/* {domains.length === 0 && status === "1" && (
         <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
           <div className="max-w-sm w-full bg-white shadow-lg rounded-lg p-6 text-center">
             <h1 className="text-2xl font-bold text-gray-800 mb-4">
               Successfully applied for the AICTE - EduSkills Virtual
               Internship.
             </h1>
             <p className="text-gray-600 text-xl mb-4">
               Application has been sent to your institute for approval.
             </p>
           </div>
         </div>
       )} */}

       {/* <OngoingTable /> */}

       {/* <div className="flex items-center justify-center mt-8 px-8 py-8 bg-gray-100">
         <div className="max-w-sm w-full bg-white shadow-lg rounded-lg p-6 text-center">
           <h1 className="text-2xl font-bold text-gray-800 mb-4">
             Cohort-10 Will Open Soon!
           </h1>
           <p className="text-gray-600 mb-4">
             Stay tuned for updates and be ready to join us!
           </p>
         </div>
       </div> */}
     </div>
//       <div className="w-full overflow-x-auto   rounded-md">
//         {/* <div className="md:flex justify-between mb-4">
//           <div className="mb-4 md:mb-0 md:mr-4">
//             <input
//               type="text"
//               placeholder="Search..."
//               value={searchQuery}
//               onChange={(e) => setSearchQuery(e.target.value)}
//               className="p-2 rounded-md w-full md:w-60 shadow-sm outline-sky-300 border-2 border-gray-300 text-gray-900"
//             />
//           </div>
//           <div className="text-gray-900">
//             <label className="mr-2 md:mt-0">Filter by:</label>
//             <select
//               value={filterCriteria}
//               onChange={(e) => handleFilterChange(e.target.value)}
//               className="border-2 border-gray-300 p-2 rounded-md"
//             >
//               <option value="all">All</option>
//               <option value="Apply">Apply</option>
//               <option value="Enrolled">Enrolled</option>
//               <option value="Completed">Completed</option>
//             </select>
//           </div>
//         </div> */}
//         <div className="table-container" style={{  borderTopLeftRadius: 3,
//            borderTopRightRadius: 3, height:'auto' , width:' 98%'}}>
//       {isLoading ? (
//         <CircularProgress />
//       ) : filteredData.length === 0 ? (
//         <Typography variant="h6" align="center" color="textSecondary">
//           No records found.
//         </Typography>
//       ) : (
//         <Grid container spacing={2} sx={{margin:0, marginBottom:'20px',paddingRight:'16px' ,alignItems: 'stretch'}}>
//       {currentItems.map((item) => (
//         <Grid item xs={12} sm={12} md={6} key={item.sl_no}>
//           <Card sx={{  marginBottom:'1px',boxShadow: 3 , borderRadius:3,backgroundColor: "#eee",}}>
//             {/* Styled Status Banner */}
//             <div className="d-flex">
//             <StatusBanner status={item.Status}  sx={{display:'flex',justifyContent:'space-between', textTransform: "uppercase" , }}>
//               <Typography>{item.Status}</Typography>
//               <Typography variant="body1" color="textPrimary" className="column-value" sx={{ textAlign: "center" }}>
//               {item.Status === "Completed" ? (
//                 <Button
//                   variant="contained"
//                   href="https://aicte-internship.eduskillsfoundation.org/pages/home/"
//                   target="_blank"
//                   startIcon={<DownloadOutlinedIcon />} // Added Download Icon
//                   sx={{
//                     padding: "2px 8px",
//                     fontSize: "11px",
//                     fontWeight: "bold",
//                     borderRadius: "6px",
//                     backdropFilter: "blur(10px)",
//                     backgroundColor: "rgba(255, 255, 255, 0.2)", // Glassmorphism Effect
//                     boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", 
//                     border: "1px solid rgba(255, 255, 255, 0.3)", 
//                     color: "#fff",
//                     transition: "0.3s",
//                     "&:hover": {
//                       backgroundColor: "rgba(255, 255, 255, 0.3)",
//                       boxShadow: "0 6px 10px rgba(0, 0, 0, 0.15)",
//                     },
//                   }}
//                 >
//                   Download Certificate
//                 </Button>
//               ) : null}
//             </Typography>
//             </StatusBanner>
            
//           </div>

//             {/* <CardContent>
//               {columns.map((column) => (
//                 <div key={column} className="column-container flex flex-direction-column">
//                   {/* <Typography variant="body2" color="textSecondary" className="column-label">
//                     {column}:
//                   </Typography> 
//                   <Typography variant="body2" color="textSecondary" className="column-label">
//                     {column.omain}:
//                   </Typography>
//                   <Typography variant="body2" color="textSecondary" className="column-label">
//                     {item.Domain}:
//                   </Typography>
//                   <Typography variant="body1" color="textPrimary" className="column-value">
//                     {column === "status" ? (
//                       <Chip label={item.Status} color={item.Status === "Completed" ? "success" : item.Status === "Failed" ? "error" : "warning"} />
//                     ) : column === "Action" ? (
//                       item.Status === "Completed" ? (
//                         <Button
//                           variant="contained"
//                           color="success"
//                           href="https://aicte-internship.eduskillsfoundation.org/pages/home/"
//                           target="_blank"
//                         >
//                           Download Certificate
//                         </Button>
//                       ) : item["Status"] === "Failed" ? (
//                         <Icon icon="codicon:git-stash-apply" width={22} height={22} />
//                       ) : (
//                         <Chip label="Fail" color="error" />
//                       )
//                     ) : (
//                       item[column]
//                     )}
//                   </Typography>
//                 </div>
//               ))}
//             </CardContent> */}
//             <CardContent sx={{backgroundColor: "#eee",}}>
//   {/* Manually Set Labels and Display Corresponding Values */}
  
//   {/* Domain */}
//   <div className="column-container flex flex-direction-column">
//     {/* <Typography variant="body2" color="textSecondary" className="column-label">
//     Domain:
//   </Typography> */}
//   <Typography variant="body1" color="textPrimary" className="column-value"sx={{ fontWeight:'bold',fontSize:'11px' }}>
//     {item.Domain}
//   </Typography></div>
//   <div className="column-container flex flex-direction-column" style={{textAlign: 'center'}}><Typography variant="body2" color="textSecondary" className="column-label" >
//     virtual Internship:
//   </Typography>
//   <Typography variant="p" color="textPrimary" className="column-value" sx={{textAlign:'center', fontWeight:'bold',fontSize:'11px'}}>
//     {item.Cohort}
//   </Typography></div>

//   {/* Status */}
  
// </CardContent>

//           </Card>
//         </Grid>
//       ))}
//     </Grid>
//       )}
//     </div>
//         {/* {!isLoading && (
//           <div className="flex justify-end mt-6">
//             <button
//               className={`mx-2 px-3 py-1 ${
//                 currentPage === 1
//                   ? "bg-gray-300 text-gray-600"
//                   : "bg-blue-500 text-white"
//               } rounded-md shadow-md`}
//               onClick={() => paginate(currentPage - 1)}
//               disabled={currentPage === 1}
//             >
//               Previous
//             </button>
//             <span className="mx-2 text-base">
//               Page {currentPage} of{" "}
//               {Math.ceil(filteredData.length / itemsPerPage)}
//             </span>
//             <button
//               className={`mx-2 px-3 py-1 ${
//                 indexOfLastItem >= filteredData.length
//                   ? "bg-gray-300 text-gray-600"
//                   : "bg-blue-500 text-white"
//               } rounded-md shadow-md`}
//               onClick={() => paginate(currentPage + 1)}
//               disabled={indexOfLastItem >= filteredData.length}
//             >
//               Next
//             </button>
//           </div>
//         )} */}
//         {/* <Divider sx={{ marginTop: "60px" }} /> */}
//         {/* {(percentage !== 100 && internship.length !== 0) && <OngoingTable />} */}
//         {/* {domains.length === 0 && status === "1" && (
//           <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
//             <div className="max-w-sm w-full bg-white shadow-lg rounded-lg p-6 text-center">
//               <h1 className="text-2xl font-bold text-gray-800 mb-4">
//                 Successfully applied for the AICTE - EduSkills Virtual
//                 Internship.
//               </h1>
//               <p className="text-gray-600 text-xl mb-4">
//                 Application has been sent to your institute for approval.
//               </p>
//             </div>
//           </div>
//         )} */}

//         {/* <OngoingTable /> */}

//         {/* <div className="flex items-center justify-center mt-8 px-8 py-8 bg-gray-100">
//           <div className="max-w-sm w-full bg-white shadow-lg rounded-lg p-6 text-center">
//             <h1 className="text-2xl font-bold text-gray-800 mb-4">
//               Cohort-10 Will Open Soon!
//             </h1>
//             <p className="text-gray-600 mb-4">
//               Stay tuned for updates and be ready to join us!
//             </p>
//           </div>
//         </div> */}
//       </div>
     }
           {/* Dialog Popup */}
         {/* <Dialog open={showPopup} onClose={() => setShowPopup(false)} fullWidth maxWidth="sm"> */}
         <Dialog 
  key={activeTab}
  open={showPopup} 
  onClose={() => setShowPopup(false)} 
  fullWidth 
  // maxWidth={activeTab === 1 || activeTab === 2 ? "lg" : "sm"}
  maxWidth="sm"
>
  <DialogTitle>Select Certificate</DialogTitle>
  <DialogContent>
    
    {/* Sticky Tabs */}
    <Box 
      sx={{ 
        position: "sticky", 
        top: 0, 
        zIndex: 1000, 
        backgroundColor: "white", 
        paddingTop: "8px", 
        paddingBottom: "8px", 
      }}
    >
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        variant="fullWidth"
        textColor="primary"
        indicatorColor="primary"
        sx={{
          borderRadius: "12px",
          padding: "4px",
          "& .MuiTabs-indicator": { display: "none" }, // Hides default underline
        }}
      >
        <Tab 
          label="Final Certificate"  
          sx={{
            borderRadius: 6,
            textTransform: "none",
            padding: "2px 6px",
            margin: "4px",
            minHeight: "40px",
            border: "1px solid #163ad9",
            color: "#163ad9",
            fontWeight: "bold",
            "&.Mui-selected": { backgroundColor: "#3452d9", color: "white" },
          }} 
        />
         {data[0]?.longTermCertificates && (
    <Tab 
      label="Long-term Certificate"  
      sx={{
        borderRadius: 6,
        textTransform: "none",
        padding: "2px 6px",
        margin: "4px",
        minHeight: "40px",
        border: "1px solid #163ad9",
        color: "#163ad9",
        fontWeight: "bold",
        "&.Mui-selected": { backgroundColor: "#3452d9", color: "white" },
      }} 
    />
  )}
    {data[0]?.shortTermCertificates && (
    <Tab 
      label="Short-term Certificate"  
      sx={{
        borderRadius: 6,
        textTransform: "none",
        padding: "2px 6px",
        margin: "4px",
        minHeight: "40px",
        border: "1px solid #163ad9",
        color: "#163ad9",
        fontWeight: "bold",
        "&.Mui-selected": { backgroundColor: "#3452d9", color: "white" },
      }} 
    />
  )}
      </Tabs>
    </Box>

    {/* Tab Content */}
    <div className="p-4 text-center">
      {activeTab === 0 && (
        <a
          href="https://aicte-internship.eduskillsfoundation.org/pages/home/"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center justify-center px-4 py-2 text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-50 rounded-md"
        >
          <p>Open Final Certificate</p> 
        </a>
      )}

      {activeTab === 1 && Array.isArray(data) && data.length > 0 && (
        data[0]?.longTermCertificates === null ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <LongTermCertificate initial={data[0]?.longTermCertificates} />
        )
      )}
      {activeTab === 2 && Array.isArray(data) && data.length > 0 && (
        data[0]?.shortTermCertificates === null ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
            <CircularProgress />
          </Box>
        ) : (
          <ApscheCertificate initial={data[0]?.shortTermCertificates} />
        )
      )}
    </div>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setShowPopup(false)} color="error">Close</Button>
  </DialogActions>
</Dialog>

      <Dialog
        open={showEligibilityDialog}
        onClose={handleCloseEligibilityDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Apsche Certificate Application Eligibility</DialogTitle>
        <DialogContent>
          <Typography
            sx={{
              fontSize: "1rem", // Increase the font size
              color: "black", // Set the color to Material UI's red
              fontWeight: "bold", // Set the font
            }}
          >
            {isLongTermApplied === 1 &&
              "You are eligible to apply for both long-term and short-term APSCHE certificates. Please proceed to the certificate menu to apply for either or both. If you have already applied, please ignore this message."}
            {isLongTermApplied === 0 &&
              "You are eligible to apply for a Short-Term APSCHE certificate. Please visit the certificate menu to proceed with your application. If you have already applied, kindly ignore this message."}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseEligibilityDialog}
            variant="contained"
            color="info"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {tabValue === 1 && (
     domains === "4" ? (
    <OngoingTableOld />
  ) : (
    // <Box padding={2} width="100%">
    //   <Grid container spacing={2}>
    //     <Grid item xs={12} md={12}>
    //       {/* <DomainChoose /> */}
    //       {preferedDomains===null?<Box sx={{ display:'flex',justifyContent:'center',marginTop:'50px',alignItems:'center'}}><CircularProgress /></Box>:<CopyDomainChoose  preferedDomains={preferedDomains}/>}
    //     </Grid>
    //   </Grid>
    // </Box>
    <>
    <div className="flex items-center justify-center  " style={{width:'100%',marginTop:'20px'}}>
  <div className="flex items-center justify-center  " style={{width:'95%',backgroundColor:'#eee',boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)' ,borderRadius:'12px'}}>
            <div className="max-w-4xl w-full bg-white rounded-lg p-6 text-center" style={{backgroundColor:'#eee'}}>
              <h3 className="text-2xl font-bold text-gray-600 mb-1" style={{fontSize:'16px'}}>
              There is no ongoing internship
              </h3>
             
            </div>
          </div>
  </div></>
  )
   )}
   {tabValue === 0 && (
     domains === "4" ? (
 <>
<div className="flex items-center justify-center  " style={{width:'100%',marginTop:'20px'}}>
  <div className="flex items-center justify-center  " style={{width:'95%',backgroundColor:'#eee',boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)' ,borderRadius:'12px'}}>
            <div className="max-w-4xl w-full bg-white rounded-lg p-6 text-center" style={{backgroundColor:'#eee'}}>
              <h3 className="text-2xl font-bold text-gray-600 mb-1" style={{fontSize:'16px'}}>
             You can apply for the new Domain in the next Cohort
              </h3>
             
            </div>
          </div>
  </div>
  </>
  ) : (
    <Box padding={2} width="100%">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          {/* <DomainChoose /> */}
          {preferedDomains===null?<Box sx={{ display:'flex',justifyContent:'center',marginTop:'50px',alignItems:'center'}}><CircularProgress /></Box>:<CopyDomainChoose  preferedDomains={preferedDomains}/>}
        </Grid>
      </Grid>
    </Box>
  )
   )}
 
    {/* </div>  */}
    </Card>
  );
};

const SkeletonTable = ({ columns, itemsPerPage }) => (
  <table className="w-full mb-5">
    <thead className="text-base bg-gray-800 text-gray-100 shadow-sm rounded-md">
      <tr className="shadow-sm">
        {columns.map((column) => (
          <th
            key={column}
            className={`py-2 px-4 text-base ${
              column === "title" ? "text-left pl-20" : "text-center"
            } border-b`}
          >
            {column}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {[...Array(itemsPerPage)].map((_, index) => (
        <tr
          key={index}
          className={`border-b text-center text-base ${
            index % 2 === 0 ? "bg-gray-100" : "bg-gray-200"
          }`}
        >
          {columns.map((column) => (
            <td
              key={column}
              className={`py-2 px-4 text-base ${
                column === "title" ? "text-left pl-20" : "text-center"
              } border-b`}
            >
              <Skeleton />
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);

export default SearchableTable;
