// import React, { useState, useEffect } from "react";
// import {
//   Button,
//   Box,
//   CircularProgress,
//   Typography,
//   Card,
//   CardContent,
//   TextField,
//   Alert,
//   AlertTitle,
//   alpha,
//   Paper,
// } from "@mui/material";
// import axios from "axios";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { getInternshipCertificateDetails } from "../../services/dataService";
// import {
//   CloudUpload,
//   Verified,
//   Pending,
//   ErrorOutline,
//   CancelOutlined,
//   VerifiedUser,
// } from "@mui/icons-material";
// import { AlertCircle, VerifiedIcon, XCircle } from "lucide-react";
// import { BASE_URL } from "../../services/configUrls";
// import { useNavigate } from "react-router-dom";

// const domainRegexMap = {
//   1: /^https:\/\/www\.credly\.com\//,
//   2: /^https:\/\/www\.credly\.com\//,
//   3: /^https:\/\/www\.credly\.com\//,
//   11: /^https:\/\/verify\.skilljar\.com\//,
//   13: /^https:\/\/verify\.skilljar\.com\//,
//   16: /^https:\/\/g\.dev\//,
//   18: /^https:\/\/g\.dev\//,
//   25: /^https:\/\/verify\.skilljar\.com\//,
//   32: /^https:\/\/www\.cloudskillsboost\.google\/public_profiles\//,
//   6: /^https:\/\/www\.credly\.com\//,
//   20: /^https:\/\/www\.credly\.com\//,
//   12: /^https:\/\/training\.fortinet\.com\//,
//   9: /^https:\/\/www\.credly\.com\//,
//   19: /^https:\/\/www\.credly\.com\//,
//   17: /^https:\/\/www\.credly\.com\//,
// };

// const FileUpload = () => {
//   const [formData, setFormData] = useState({});
//   const [courseData, setCourseData] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [uploading, setUploading] = useState(false);
//   const [error, setError] = useState(null);
//   const [verifying, setVerifying] = useState(false);
//   const navigate = useNavigate();
//   const [errors, setErrors] = useState({});
//   const [isFormValid, setIsFormValid] = useState(false);

//   const fetchCourseData = async () => {
//     setLoading(true);
//     try {
//       const response =
//         await getInternshipCertificateDetails.certificateDetails();
//       setCourseData(response?.data);
//     } catch (error) {
//       console.error("Error fetching course data:", error);
//       setError(error);
//       toast.error("Failed to fetch course data");
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchCourseData();
//   }, []);

//   const handleFileChange = (course_id, event) => {
//     const selectedFile = event.target.files[0];
//     if (selectedFile && selectedFile.type === "application/pdf") {
//       setFormData((prev) => ({
//         ...prev,
//         [course_id]: {
//           ...prev[course_id],
//           file: selectedFile,
//         },
//       }));
//     } else {
//       toast.error("Please select a PDF file");
//     }
//   };

//   // const handleTextChange = (course_id, domain_id, event) => {
//   //   const inputValue = event.target.value;
//   //   const regex = domainRegexMap[domain_id];

//   //   // Validate the input based on the domain regex
//   //   if (regex && !regex.test(inputValue)) {
//   //     toast.error(`Invalid URL format for the domain: ${inputValue}`);
//   //     return;
//   //   }

//   //   setFormData((prev) => ({
//   //     ...prev,
//   //     [course_id]: {
//   //       ...prev[course_id],
//   //       text: inputValue,
//   //     },
//   //   }));
//   // };

//   // const handleVerification = async () => {
//   //   try {
//   //     setVerifying(true);
//   //     await axios.get(`${BASE_URL}/certificate/verify`, {
//   //       headers: {
//   //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//   //       },
//   //     });
//   //     await fetchCourseData(); // Re-fetch course data after verification completes
//   //   } catch (error) {
//   //     console.error("Verification error:", error);
//   //   } finally {
//   //     setVerifying(false);
//   //   }
//   // };

//   const handleTextChange = (course_id, domain_id, event) => {
//     const inputValue = event.target.value.trim();

//     setFormData((prev) => ({
//       ...prev,
//       [course_id]: {
//         ...prev[course_id],
//         text: inputValue,
//       },
//     }));

//     // Optional: Show warning for non-matching URLs but don't block submission
//     const regex = domainRegexMap[domain_id];
//     if (inputValue && regex && !regex.test(inputValue)) {
//       setErrors((prev) => ({
//         ...prev,
//         [course_id]: `Warning: It is not a valid URL for the domain, it will be rejected.`,
//       }));
//     } else {
//       setErrors((prev) => ({
//         ...prev,
//         [course_id]: null,
//       }));
//     }
//   };

//   // Function to check if form has any input
//   const hasAnyInput = () => {
//     if (!courseData?.courses) return false;

//     return courseData.courses.some((course) => {
//       const courseDetails = formData[course.course_id];
//       return courseDetails?.file || courseDetails?.text;
//     });
//   };

//   const handleVerification = async () => {
//     try {
//       setVerifying(true);

//       // Step 1: Fetch course data (Verification)
//       try {
//         await axios.get(`${BASE_URL}/certificate/verify`, {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//           },
//         });

//         // Fetch updated course data after verification
//         await fetchCourseData();

//         // Check if any courses are rejected after verification
//         const updatedCourseData =
//           await getInternshipCertificateDetails.certificateDetails();
//         const hasRejectedCourses = updatedCourseData?.data?.courses?.some(
//           (course) => course.status === "3"
//         );

//         if (hasRejectedCourses) {
//           toast.error(
//             "Some certificates were rejected. Please check and resubmit."
//           );
//           return; // Exit early if any certificates are rejected
//         }

//         // If no rejections, wait 3 seconds before proceeding with assessment
//         await new Promise((resolve) => setTimeout(resolve, 3000));

//         // Step 2: Check domain_id and conditionally hit the assessment API
//         const shouldSkipAssessment = updatedCourseData?.data?.courses?.some(
//           (course) => [9, 25, 6, 20, 13, 33].includes(course.domain_id)
//         );

//         if (!shouldSkipAssessment) {
//           try {
//             await axios.get(`${BASE_URL}/assessment/generate_assessment`, {
//               headers: {
//                 Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//               },
//             });

//             // Show success message for assessment generation
//             // toast.success("You can now start your assessment.");

//             // Navigate to internship page
//             navigate("/internship");
//           } catch (assessmentError) {
//             console.error("Assessment generation error:", assessmentError);
//             toast.error("Assessment generation failed. Please try again.");
//             return;
//           }
//         } else {
//           toast.info("Assessment generation skipped for this domain.");
//           navigate("/internship");
//         }
//       } catch (verificationError) {
//         console.error("Verification error:", verificationError);
//         toast.error("Verification failed. Please try again.");
//         return;
//       }
//     } finally {
//       setVerifying(false);
//     }
//   };

//   useEffect(() => {
//     const validateForm = () => {
//       if (!courseData?.courses) return false;

//       let hasAtLeastOneValidInput = false;

//       for (const course of courseData.courses) {
//         if (course.status !== "0" && course.status !== "3") continue;

//         const courseDetails = formData[course.course_id];

//         // For URL input
//         if (course.is_url === "1") {
//           if (courseDetails?.text) {
//             const regex = domainRegexMap[course.domain_id];
//             if (regex?.test(courseDetails.text)) {
//               hasAtLeastOneValidInput = true;
//             }
//           }
//         }
//         // For file input
//         else if (course.is_url === "0") {
//           if (courseDetails?.file) {
//             hasAtLeastOneValidInput = true;
//           }
//         }
//       }

//       return hasAtLeastOneValidInput;
//     };

//     setIsFormValid(validateForm());
//   }, [formData, courseData]);

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     const validationErrors = {};
//     courseData?.courses?.forEach((course) => {
//       const courseDetails = formData[course.course_id];
//       if (course.status === "0" || course.status === "3") {
//         if (
//           course.is_url === "1" &&
//           (!courseDetails?.text || errors[course.course_id])
//         ) {
//           validationErrors[course.course_id] = "Invalid or missing URL.";
//         }
//       }
//     });

//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       toast.error("Please fix the errors before submitting.");
//       return;
//     }

//     const courseDataArray = [];

//     const encodeFileToBase64 = (file) => {
//       return new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.onloadend = () => {
//           resolve(reader.result.split(",")[1]);
//         };
//         reader.onerror = reject;
//         reader.readAsDataURL(file);
//       });
//     };

//     // First, collect all courses with actual data
//     const coursesWithData = [];

//     for (const course of courseData?.courses || []) {
//       const courseDetails = formData[course.course_id];

//       // Skip if no data provided for this course
//       if (!courseDetails) continue;

//       // Skip courses that don't need submission
//       if (course.status !== "0" && course.status !== "3") continue;

//       // Check if there's actual data (file or text)
//       const hasFile = !!courseDetails.file;
//       const hasText = !!courseDetails.text;

//       if (hasFile || hasText) {
//         coursesWithData.push({
//           course,
//           details: courseDetails,
//           hasFile,
//           hasText,
//         });
//       }
//     }

//     // Process only courses that have data
//     for (const { course, details, hasFile, hasText } of coursesWithData) {
//       let courseObject = {
//         course_name: course.course_name,
//         domain_id: course.domain_id,
//         course_id: course.course_id,
//       };

//       // Only include text if it exists
//       if (hasText) {
//         courseObject.text = details.text;
//       }

//       // Only include file if it exists
//       if (hasFile) {
//         courseObject.file = await encodeFileToBase64(details.file);
//       }

//       // Don't include null fields
//       if (courseObject.text === undefined) delete courseObject.text;
//       if (courseObject.file === undefined) delete courseObject.file;

//       courseDataArray.push(courseObject);
//     }

//     // Validate that we have at least one course to submit
//     if (courseDataArray.length === 0) {
//       toast.error("Please provide at least one certificate or URL to submit");
//       return;
//     }

//     try {
//       setUploading(true);

//       console.log("Submitting data:", courseDataArray); // For debugging

//       await axios.post(
//         `${BASE_URL}/domain/course_certificate_upload`,
//         { data: courseDataArray },
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       toast.success("Submission successful");
//       // handleVerification();
//       if (courseDataArray.length > 0) {
//         setTimeout(() => {
//           window.location.reload();
//         }, 2000);
//       }
//     } catch (error) {
//       console.error("Submission error:", error);
//       toast.error("Failed to submit network error check your network");
//     } finally {
//       setUploading(false);
//     }
//   };

//   // Status check functions
//   const hasVerifiedAndUploadedCourses = (courses) => {
//     return (
//       courses?.some((course) => course.status === "1") &&
//       courses?.some((course) => course.status === "2")
//     );
//   };

//   const isAllCoursesVerified = courseData?.courses?.every(
//     (course) => course.status === "2"
//   );

//   const isAnyCourseInProgress = courseData?.courses?.every(
//     (course) => course.status === "1"
//   );

//   const isAnyCourseInRejected = courseData?.courses?.some(
//     (course) => course.status === "3"
//   );

//   // const showComingSoonMessage = courseData?.courses?.some(
//   //   (course) => course.domain_id === 13 || course.domain_id === 9
//   // );

//   if (error) {
//     return <ErrorComponent error={error} />;
//   }

//   return (
//     <Box
//       component="form"
//       onSubmit={handleSubmit}
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         minHeight: "100vh",
//         backgroundColor: "#f0f0f0",
//         padding: 2,
//       }}
//     >
//       {loading ? (
//         <CircularProgress />
//       ) : verifying ? (
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//           }}
//         >
//           <CircularProgress />
//           <Typography sx={{ marginTop: 2 }}>
//             Verification under Process
//           </Typography>
//         </Box>
//       ) : (
//         <Card
//           sx={{
//             width: "100%",
//             maxWidth: { xs: "100%", sm: "600px", md: "900px" },
//             padding: { xs: 2, sm: 3 },
//             boxShadow: 5,
//             borderRadius: 3,
//             backgroundColor: "#ffffff",
//           }}
//         >
//           <CardContent>
//             {/* {showComingSoonMessage && (
//               <Typography
//                 variant="body2"
//                 sx={{
//                   color: "blue",
//                   fontWeight: "bold",
//                   textAlign: "center",
//                   marginBottom: 2,
//                   fontSize: "1rem",
//                 }}
//               >
//                 The Certificate Upload Will Open Soon.
//               </Typography>
//             )} */}

//             {hasVerifiedAndUploadedCourses && (
//               <>
//                 <Box sx={{ mb: 4 }}>
//                   <Alert severity="info" icon={<VerifiedUser />} sx={{ mb: 3 }}>
//                     <AlertTitle sx={{ fontWeight: "bold" }}>
//                       Certificate Status Overview
//                     </AlertTitle>
//                     <Typography>
//                       Some certificates have been verified while others are
//                       still pending verification.
//                     </Typography>
//                   </Alert>

//                   <Box sx={{ mb: 3 }}>
//                     <Typography
//                       variant="h6"
//                       sx={{
//                         mb: 2,
//                         color: "success.main",
//                         display: "flex",
//                         alignItems: "center",
//                         gap: 1,
//                       }}
//                     >
//                       <Verified /> Verified Certificates
//                     </Typography>
//                     <Paper
//                       elevation={0}
//                       sx={{ p: 2, bgcolor: "success.lighter" }}
//                     >
//                       {courseData?.courses
//                         ?.filter((course) => course.status === "2")
//                         .map((course) => (
//                           <Alert
//                             key={course.course_id}
//                             severity="success"
//                             icon={<Verified />}
//                             sx={{ mb: 1, "&:last-child": { mb: 0 } }}
//                           >
//                             {course.course_name}
//                           </Alert>
//                         ))}
//                     </Paper>
//                   </Box>

//                   <Box>
//                     <Typography
//                       variant="h6"
//                       sx={{
//                         mb: 2,
//                         color: "warning.main",
//                         display: "flex",
//                         alignItems: "center",
//                         gap: 1,
//                       }}
//                     >
//                       <Pending /> Pending Verification
//                     </Typography>
//                     <Paper
//                       elevation={0}
//                       sx={{ p: 2, bgcolor: "warning.lighter" }}
//                     >
//                       {courseData?.courses
//                         ?.filter((course) => course.status === "1")
//                         .map((course) => (
//                           <Alert
//                             key={course.course_id}
//                             severity="warning"
//                             icon={<Pending />}
//                             sx={{ mb: 1, "&:last-child": { mb: 0 } }}
//                           >
//                             {course.course_name}
//                           </Alert>
//                         ))}
//                     </Paper>
//                   </Box>
//                 </Box>
//               </>
//             )}

//             {isAnyCourseInProgress && (
//               <Typography
//                 variant="body2"
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   color: "green",
//                   fontWeight: "bold",
//                   textAlign: "center",
//                   marginBottom: 2,
//                   fontSize: "1rem",
//                   backgroundColor: "#f0f4f7",
//                   padding: "20px",
//                   borderRadius: "8px",
//                   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//                 }}
//               >
//                 <Pending
//                   className="text-yellow-500"
//                   fontSize="inherit"
//                   sx={{ fontSize: "4rem", marginBottom: "8px" }}
//                 />
//                 Your certificate has been successfully uploaded. The
//                 verification status will be notified soon.
//               </Typography>
//             )}

//             {isAllCoursesVerified && (
//               <Typography
//                 variant="body2"
//                 sx={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   color: "green",
//                   fontWeight: "bold",
//                   textAlign: "center",
//                   marginBottom: 2,
//                   fontSize: "1rem",
//                   backgroundColor: "#f0f4f7",
//                   padding: "20px",
//                   borderRadius: "8px",
//                   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//                 }}
//               >
//                 <VerifiedIcon
//                   className="text-green-500"
//                   fontSize="inherit"
//                   sx={{ fontSize: "4rem", marginBottom: "8px" }}
//                 />
//                 Your all certificates are verified successfully.
//               </Typography>
//             )}

//             {!isAnyCourseInProgress &&
//               !isAllCoursesVerified &&
//                (
//                 <>
//                   {/* Certificate upload or URL input section */}
//                   <Typography
//                     variant="h5"
//                     component="h1"
//                     sx={{
//                       fontWeight: "bold",
//                       textAlign: "center",
//                       marginBottom: 2,
//                     }}
//                   >
//                     Upload Certificate or URLs for Courses
//                   </Typography>

//                   <Box
//                     sx={{
//                       display: "grid",
//                       gridTemplateColumns: { xs: "1fr", sm: "repeat(2, 1fr)" },
//                       gap: 2,
//                     }}
//                   >
//                     {courseData?.courses
//                       ?.filter(
//                         (course) =>
//                           course.status === "0" || course.status === "3"
//                       )
//                       .map((course) => (
//                         <div
//                           key={course.course_id}
//                           style={{ marginBottom: "16px" }}
//                         >
//                           {/* Input for course status 0 */}
//                           {courseData.status === 0 && (
//                             <div className="flex items-center gap-4">
//                               {/* <CloudUpload
//                               className="text-gray-500"
//                               fontSize="large"
//                             /> */}
//                               <div className="w-full">
//                                 <input
//                                   type="file"
//                                   required
//                                   onChange={(e) =>
//                                     handleFileChange(course.course_id, e)
//                                   }
//                                   className="w-full text-gray-500 font-medium text-base bg-gray-100 file:cursor-pointer cursor-pointer file:border-0 file:py-2.5 file:px-4 file:mr-4 file:bg-gray-800 file:hover:bg-gray-700 file:text-white rounded"
//                                 />
//                                 <Typography
//                                   variant="body2"
//                                   color="textSecondary"
//                                   sx={{ marginTop: 1 }}
//                                 >
//                                   Only PDF files are allowed for{" "}
//                                   {course.course_name}.
//                                 </Typography>
//                                 {/* <IconButton
//                                   onClick={() =>
//                                     handleOpenDialog(course.domain_id)
//                                   }
//                                   size="small"
//                                   sx={{ marginLeft: 1 }}
//                                 >
//                                   <Info />
//                                 </IconButton> */}
//                               </div>
//                             </div>
//                           )}

//                           {course.status === "1" && (
//                             <Alert
//                               severity="warning"
//                               icon={<Pending />}
//                               sx={{ mb: 2 }}
//                             >
//                               <Typography
//                                 sx={{
//                                   display: "flex",
//                                   alignItems: "center",
//                                   gap: 1,
//                                 }}
//                               >
//                                 Pending verification: {course.course_name}
//                               </Typography>
//                             </Alert>
//                           )}

//                           {course.status === "2" && (
//                             <Alert
//                               severity="success"
//                               icon={<Verified />}
//                               sx={{ mb: 2 }}
//                             >
//                               <Typography
//                                 sx={{
//                                   display: "flex",
//                                   alignItems: "center",
//                                   gap: 1,
//                                 }}
//                               >
//                                 Verified: {course.course_name}
//                               </Typography>
//                             </Alert>
//                           )}

//                           {course.status === "3" && courseData.status === 0 && (
//                             <Paper
//                               elevation={0}
//                               sx={{
//                                 mb: 3,
//                                 overflow: "hidden",
//                                 border: "1px solid",
//                                 borderColor: "error.main",
//                                 borderRadius: 2,
//                                 background:
//                                   "linear-gradient(135deg, #FFF5F5 0%, #FFF 100%)",
//                               }}
//                             >
//                               <Box
//                                 sx={{
//                                   p: 0.5,
//                                   bgcolor: "error.main",
//                                   display: "flex",
//                                   alignItems: "center",
//                                   gap: 1,
//                                   pl: 2,
//                                 }}
//                               >
//                                 <ErrorOutline sx={{ color: "white" }} />
//                                 <Typography
//                                   sx={{
//                                     color: "white",
//                                     fontWeight: 500,
//                                     fontSize: "0.9rem",
//                                     py: 0.5,
//                                   }}
//                                 >
//                                   Certificate Status: Rejected
//                                 </Typography>
//                               </Box>

//                               <Box sx={{ p: 2 }}>
//                                 <Box
//                                   sx={{
//                                     display: "flex",
//                                     alignItems: "flex-start",
//                                     gap: 2,
//                                     p: 2,
//                                     bgcolor: alpha("#FFF", 0.5),
//                                     borderRadius: 1,
//                                     border: "1px dashed",
//                                     borderColor: "error.light",
//                                   }}
//                                 >
//                                   <Box
//                                     sx={{
//                                       minWidth: 40,
//                                       height: 40,
//                                       borderRadius: "50%",
//                                       bgcolor: "error.lighter",
//                                       display: "flex",
//                                       alignItems: "center",
//                                       justifyContent: "center",
//                                     }}
//                                   >
//                                     <CancelOutlined color="error" />
//                                   </Box>

//                                   <Box>
//                                     <Typography
//                                       sx={{
//                                         fontWeight: 600,
//                                         color: "error.dark",
//                                         mb: 0.5,
//                                       }}
//                                     >
//                                       Rejection Reason:
//                                     </Typography>
//                                     <Typography
//                                       sx={{
//                                         color: "error.dark",
//                                         lineHeight: 1.5,
//                                       }}
//                                     >
//                                       {course.reason || "No reason provided"}
//                                     </Typography>
//                                   </Box>
//                                 </Box>
//                               </Box>
//                             </Paper>
//                           )}

//                           {courseData.status === 1 && (
//                             <div className="flex items-center gap-2">
//                               <TextField
//                                 label={`Enter URL for ${course.course_name}`}
//                                 fullWidth
//                                 size="small"
//                                 required
//                                 value={formData[course.course_id]?.text || ""}
//                                 onChange={(e) =>
//                                   handleTextChange(
//                                     course.course_id,
//                                     course.domain_id,
//                                     e
//                                   )
//                                 }
//                                 error={!!errors[course.course_id]}
//                                 helperText={errors[course.course_id]}
//                               />
//                               {/* <IconButton
//                                 onClick={() =>
//                                   handleOpenDialog(course.domain_id)
//                                 }
//                                 size="small"
//                                 sx={{ marginLeft: 1 }}
//                               >
//                                 <Info />
//                               </IconButton> */}
//                             </div>
//                           )}
//                           {/* Show the rejected message for courses with status "3" */}
//                           {course.status === "3" && courseData.status === 1 && (
//                             <Paper
//                               elevation={0}
//                               sx={{
//                                 mb: 3,
//                                 overflow: "hidden",
//                                 border: "1px solid",
//                                 borderColor: "error.main",
//                                 borderRadius: 2,
//                                 background:
//                                   "linear-gradient(135deg, #FFF5F5 0%, #FFF 100%)",
//                               }}
//                             >
//                               <Box
//                                 sx={{
//                                   p: 0.5,
//                                   bgcolor: "error.main",
//                                   display: "flex",
//                                   alignItems: "center",
//                                   gap: 1,
//                                   pl: 2,
//                                 }}
//                               >
//                                 <ErrorOutline sx={{ color: "white" }} />
//                                 <Typography
//                                   sx={{
//                                     color: "white",
//                                     fontWeight: 500,
//                                     fontSize: "0.9rem",
//                                     py: 0.5,
//                                   }}
//                                 >
//                                   Certificate Status: Rejected
//                                 </Typography>
//                               </Box>

//                               <Box sx={{ p: 2 }}>
//                                 <Box
//                                   sx={{
//                                     display: "flex",
//                                     alignItems: "flex-start",
//                                     gap: 2,
//                                     p: 2,
//                                     bgcolor: alpha("#FFF", 0.5),
//                                     borderRadius: 1,
//                                     border: "1px dashed",
//                                     borderColor: "error.light",
//                                   }}
//                                 >
//                                   <Box
//                                     sx={{
//                                       minWidth: 40,
//                                       height: 40,
//                                       borderRadius: "50%",
//                                       bgcolor: "error.lighter",
//                                       display: "flex",
//                                       alignItems: "center",
//                                       justifyContent: "center",
//                                     }}
//                                   >
//                                     <CancelOutlined color="error" />
//                                   </Box>

//                                   <Box>
//                                     <Typography
//                                       sx={{
//                                         fontWeight: 600,
//                                         color: "error.dark",
//                                         mb: 0.5,
//                                       }}
//                                     >
//                                       Rejection Reason:
//                                     </Typography>
//                                     <Typography
//                                       sx={{
//                                         color: "error.dark",
//                                         lineHeight: 1.5,
//                                       }}
//                                     >
//                                       {course.reason || "No reason provided"}
//                                     </Typography>
//                                   </Box>
//                                 </Box>
//                               </Box>
//                             </Paper>
//                           )}

//                           {courseData.status === 2 && (
//                             <div>
//                               {course.is_url === "0" &&
//                               (course.status === "0" ||
//                                 course.status === "3") ? (
//                                 <div className="flex items-center gap-4">
//                                   {/* <CloudUpload
//                                     className="text-gray-500"
//                                     fontSize="large"
//                                   /> */}
//                                   <div className="w-full">
//                                     <input
//                                       type="file"
//                                       required
//                                       onChange={(e) =>
//                                         handleFileChange(course.course_id, e)
//                                       }
//                                       className="w-full text-gray-500 font-medium text-base bg-gray-100 file:cursor-pointer cursor-pointer file:border-0 file:py-2.5 file:px-4 file:mr-4 file:bg-gray-800 file:hover:bg-gray-700 file:text-white rounded"
//                                     />
//                                     <Typography
//                                       variant="body2"
//                                       color="textSecondary"
//                                       sx={{ marginTop: 1 }}
//                                     >
//                                       Only PDF files are allowed for{" "}
//                                       {course.course_name}.
//                                     </Typography>

//                                     {course.status === "3" &&
//                                       courseData.status === 2 && (
//                                         <Paper
//                                           elevation={0}
//                                           sx={{
//                                             mb: 3,
//                                             overflow: "hidden",
//                                             border: "1px solid",
//                                             borderColor: "error.main",
//                                             borderRadius: 2,
//                                             background:
//                                               "linear-gradient(135deg, #FFF5F5 0%, #FFF 100%)",
//                                           }}
//                                         >
//                                           <Box
//                                             sx={{
//                                               p: 0.5,
//                                               bgcolor: "error.main",
//                                               display: "flex",
//                                               alignItems: "center",
//                                               gap: 1,
//                                               pl: 2,
//                                             }}
//                                           >
//                                             <ErrorOutline
//                                               sx={{ color: "white" }}
//                                             />
//                                             <Typography
//                                               sx={{
//                                                 color: "white",
//                                                 fontWeight: 500,
//                                                 fontSize: "0.9rem",
//                                                 py: 0.5,
//                                               }}
//                                             >
//                                               Certificate Status: Rejected
//                                             </Typography>
//                                           </Box>

//                                           <Box sx={{ p: 2 }}>
//                                             <Box
//                                               sx={{
//                                                 display: "flex",
//                                                 alignItems: "flex-start",
//                                                 gap: 2,
//                                                 p: 2,
//                                                 bgcolor: alpha("#FFF", 0.5),
//                                                 borderRadius: 1,
//                                                 border: "1px dashed",
//                                                 borderColor: "error.light",
//                                               }}
//                                             >
//                                               <Box
//                                                 sx={{
//                                                   minWidth: 40,
//                                                   height: 40,
//                                                   borderRadius: "50%",
//                                                   bgcolor: "error.lighter",
//                                                   display: "flex",
//                                                   alignItems: "center",
//                                                   justifyContent: "center",
//                                                 }}
//                                               >
//                                                 <CancelOutlined color="error" />
//                                               </Box>

//                                               <Box>
//                                                 <Typography
//                                                   sx={{
//                                                     fontWeight: 600,
//                                                     color: "error.dark",
//                                                     mb: 0.5,
//                                                   }}
//                                                 >
//                                                   Rejection Reason:
//                                                 </Typography>
//                                                 <Typography
//                                                   sx={{
//                                                     color: "error.dark",
//                                                     lineHeight: 1.5,
//                                                   }}
//                                                 >
//                                                   {course.reason ||
//                                                     "No reason provided"}
//                                                 </Typography>
//                                               </Box>
//                                             </Box>
//                                           </Box>
//                                         </Paper>
//                                       )}
//                                   </div>
//                                 </div>
//                               ) : (
//                                 <div>
//                                   <TextField
//                                     label={`Enter URL for ${course.course_name}`}
//                                     fullWidth
//                                     size="small"
//                                     required
//                                     onChange={(e) =>
//                                       handleTextChange(
//                                         course.course_id,
//                                         course.domain_id,
//                                         e
//                                       )
//                                     }
//                                     sx={{ marginTop: 2 }}
//                                   />
//                                   {course.status === "3" &&
//                                     courseData.status === 2 && (
//                                       <Paper
//                                         elevation={0}
//                                         sx={{
//                                           mb: 3,
//                                           overflow: "hidden",
//                                           border: "1px solid",
//                                           borderColor: "error.main",
//                                           borderRadius: 2,
//                                           background:
//                                             "linear-gradient(135deg, #FFF5F5 0%, #FFF 100%)",
//                                         }}
//                                       >
//                                         <Box
//                                           sx={{
//                                             p: 0.5,
//                                             bgcolor: "error.main",
//                                             display: "flex",
//                                             alignItems: "center",
//                                             gap: 1,
//                                             pl: 2,
//                                           }}
//                                         >
//                                           <ErrorOutline
//                                             sx={{ color: "white" }}
//                                           />
//                                           <Typography
//                                             sx={{
//                                               color: "white",
//                                               fontWeight: 500,
//                                               fontSize: "0.9rem",
//                                               py: 0.5,
//                                             }}
//                                           >
//                                             Certificate Status: Rejected
//                                           </Typography>
//                                         </Box>

//                                         <Box sx={{ p: 2 }}>
//                                           <Box
//                                             sx={{
//                                               display: "flex",
//                                               alignItems: "flex-start",
//                                               gap: 2,
//                                               p: 2,
//                                               bgcolor: alpha("#FFF", 0.5),
//                                               borderRadius: 1,
//                                               border: "1px dashed",
//                                               borderColor: "error.light",
//                                             }}
//                                           >
//                                             <Box
//                                               sx={{
//                                                 minWidth: 40,
//                                                 height: 40,
//                                                 borderRadius: "50%",
//                                                 bgcolor: "error.lighter",
//                                                 display: "flex",
//                                                 alignItems: "center",
//                                                 justifyContent: "center",
//                                               }}
//                                             >
//                                               <CancelOutlined color="error" />
//                                             </Box>

//                                             <Box>
//                                               <Typography
//                                                 sx={{
//                                                   fontWeight: 600,
//                                                   color: "error.dark",
//                                                   mb: 0.5,
//                                                 }}
//                                               >
//                                                 Rejection Reason:
//                                               </Typography>
//                                               <Typography
//                                                 sx={{
//                                                   color: "error.dark",
//                                                   lineHeight: 1.5,
//                                                 }}
//                                               >
//                                                 {course.reason ||
//                                                   "No reason provided"}
//                                               </Typography>
//                                             </Box>
//                                           </Box>
//                                         </Box>
//                                       </Paper>
//                                     )}
//                                 </div>
//                               )}
//                             </div>
//                           )}
//                         </div>
//                       ))}
//                   </Box>

//                   <Button
//                     type="submit"
//                     variant="contained"
//                     color="success"
//                     fullWidth
//                     disabled={uploading || !hasAnyInput()}
//                     sx={{ marginTop: 3 }}
//                   >
//                     {uploading ? <CircularProgress size={24} /> : "Submit"}
//                   </Button>
//                 </>
//               )}
//           </CardContent>
//         </Card>
//       )}

//       {/* <Dialog
//         open={dialogOpen}
//         onClose={handleCloseDialog}
//         fullWidth
//         maxWidth="md"
//       >
//         <DialogTitle>Certificate Upload Instructions</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             {selectedImages.map((image, index) => (
//               <Grid item xs={12} sm={6} key={index}>
//                 <img
//                   src={image} // Replace with actual image paths or URLs
//                   alt={`Certificate guideline ${index + 1}`}
//                   style={{ width: "100%", height: "auto", borderRadius: 8 }}
//                 />
//               </Grid>
//             ))}
//           </Grid>
//         </DialogContent>
//       </Dialog> */}
//     </Box>
//   );
// };

// const ErrorComponent = ({ error }) => {
//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-100">
//       <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
//         <div className="flex items-center justify-center mb-6">
//           <XCircle className="text-red-500 w-16 h-16" />
//         </div>
//         <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">
//           Oops! Something went wrong
//         </h2>
//         <p className="text-center text-gray-600 mb-6">
//           We couldn't fetch the course data. Please try again later.
//         </p>
//         <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
//           <p className="text-sm text-red-700">Error details: {error.message}</p>
//         </div>
//         <div className="flex justify-center">
//           <button
//             className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300 ease-in-out"
//             onClick={() => window.location.reload()}
//           >
//             Try Again
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FileUpload;

import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  TextField,
  Alert,
  AlertTitle,
  Paper,
  Stack,
  IconButton,
  Grid,
  LinearProgress,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { getInternshipCertificateDetails } from "../../services/dataService";
import {
  CloudUpload,
  Verified,
  Pending,
  ErrorOutline,
  CancelOutlined,
  VerifiedUser,
  InsertDriveFile,
  Close,
  HourglassEmpty,
} from "@mui/icons-material";
import { BASE_URL } from "../../services/configUrls";
import { useNavigate } from "react-router-dom";
import FortinetInstructions from "./FortinetInstruction";
import PdfWarning from "./PdfWarning";

const domainRegexMap = {
  1: /^https:\/\/www\.credly\.com\//,
  2: /^https:\/\/www\.credly\.com\//,
  3: /^https:\/\/www\.credly\.com\//,
  11: /^https:\/\/verify\.skilljar\.com\//,
  13: /^https:\/\/verify\.skilljar\.com\//,
  16: /^https:\/\/g\.dev\//,
  18: /^https:\/\/g\.dev\//,
  25: /^https:\/\/verify\.skilljar\.com\//,
  32: /^https:\/\/www\.cloudskillsboost\.google\/public_profiles\//,
  6: /^https:\/\/www\.credly\.com\//,
  20: /^https:\/\/www\.credly\.com\//,
  12: /^https:\/\/training\.fortinet\.com\//,
  9: /^https:\/\/www\.credly\.com\//,
  19: /^https:\/\/www\.credly\.com\//,
  17: /^https:\/\/www\.credly\.com\//,
};

const SubmitButton = ({ uploading, handleSubmit, disabled }) => (
  <Button
    variant="contained"
    color="success"
    fullWidth
    disabled={disabled || uploading}
    onClick={handleSubmit}
    sx={{
      mt: 3,
      py: 1.5,
      position: "relative",
    }}
  >
    {uploading ? (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CircularProgress size={24} sx={{ mr: 1, color: "inherit" }} />
        <Typography>Uploading Certificates...</Typography>
      </Box>
    ) : (
      <>
        <CloudUpload sx={{ mr: 1 }} />
        Submit Certificates
      </>
    )}
  </Button>
);

const FileUpload = () => {
  const [formData, setFormData] = useState({});
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [verifying, setVerifying] = useState(false);
  const navigate = useNavigate();

  const VerificationStatus = () => (
    <Box
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 9999,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "grey.100",
      }}
    >
      <Card
        sx={{
          maxWidth: 600,
          width: "90%",
          textAlign: "center",
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box sx={{ mb: 3 }}>
          <HourglassEmpty
            sx={{
              fontSize: 64,
              color: "info.main",
              animation: "spin 2s linear infinite",
              "@keyframes spin": {
                "0%": { transform: "rotate(0deg)" },
                "100%": { transform: "rotate(360deg)" },
              },
            }}
          />
        </Box>

        <Typography
          variant="h4"
          component="h1"
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          Verifying Certificates
        </Typography>

        <Typography color="text.secondary" sx={{ mb: 4 }}>
          Please wait while we verify your certificates. This process may take a
          few moments.
        </Typography>

        <Box sx={{ position: "relative", mb: 3 }}>
          <LinearProgress
            color="info"
            sx={{
              height: 8,
              borderRadius: 4,
              bgcolor: "grey.200",
              "& .MuiLinearProgress-bar": {
                borderRadius: 4,
              },
            }}
          />
        </Box>

        <Typography
          variant="caption"
          sx={{ fontWeight: "bold" }}
          color="text.warning"
        >
          Do not close or refresh this page
        </Typography>
      </Card>
    </Box>
  );

  const fetchCourseData = async () => {
    setLoading(true);
    try {
      const response =
        await getInternshipCertificateDetails.certificateDetails();
      setCourseData(response?.data);
    } catch (error) {
      console.error("Error fetching course data:", error);
      setError(error);
      toast.error("Failed to fetch course data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourseData();
  }, []);

  const validateField = (course_id, value, course) => {
    if (!value) {
      return "This field is required";
    }

    if (
      courseData.status === 2 ? course.is_url === "1" : courseData.status === 1
    ) {
      const regex = domainRegexMap[course.domain_id];
      if (regex && !regex.test(value)) {
        return "Invalid URL format for this domain";
      }
    }

    return "";
  };

  const handleFileChange = (course_id, event) => {
    const file = event.target.files[0];
    setTouched((prev) => ({ ...prev, [course_id]: true }));

    if (!file) {
      setErrors((prev) => ({ ...prev, [course_id]: "File is required" }));
      return;
    }

    if (file.type !== "application/pdf") {
      setErrors((prev) => ({
        ...prev,
        [course_id]: "Please select a PDF file",
      }));
      toast.error("Please select a PDF file");
      return;
    }

    if (file.size > 5 * 1024 * 1024) {
      setErrors((prev) => ({
        ...prev,
        [course_id]: "File size should be less than 5MB",
      }));
      toast.error("File size should be less than 5MB");
      return;
    }

    setFormData((prev) => ({
      ...prev,
      [course_id]: { ...prev[course_id], file: file, fileName: file.name },
    }));
    setErrors((prev) => {
      const newErrors = { ...prev };
      delete newErrors[course_id];
      return newErrors;
    });
    toast.success(`File selected: ${file.name}`);
  };

  const handleRemoveFile = (course_id) => {
    setFormData((prev) => {
      const newData = { ...prev };
      delete newData[course_id];
      return newData;
    });
    if (touched[course_id]) {
      setErrors((prev) => ({ ...prev, [course_id]: "File is required" }));
    }
  };

  const handleUrlChange = (course_id, domain_id, event) => {
    const url = event.target.value.trim();
    setTouched((prev) => ({ ...prev, [course_id]: true }));

    setFormData((prev) => ({
      ...prev,
      [course_id]: { ...prev[course_id], text: url },
    }));

    const error = validateField(course_id, url, { domain_id });
    if (error) {
      setErrors((prev) => ({ ...prev, [course_id]: error }));
    } else {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[course_id];
        return newErrors;
      });
    }
  };

  const handleBlur = (course_id, course) => {
    setTouched((prev) => ({ ...prev, [course_id]: true }));

    const value = formData[course_id]?.text || formData[course_id]?.file;
    const error = validateField(course_id, value, course);

    if (error) {
      setErrors((prev) => ({ ...prev, [course_id]: error }));
    }
  };

  const renderFilePreview = (course_id) => {
    const fileData = formData[course_id];
    if (!fileData?.file) return null;

    return (
      <Paper
        sx={{
          p: 2,
          mt: 1,
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          bgcolor: "grey.50",
          border: "1px solid",
          borderColor: errors[course_id] ? "error.main" : "grey.200",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <InsertDriveFile color={errors[course_id] ? "error" : "primary"} />
          <Typography
            variant="body2"
            sx={{
              wordBreak: "break-all",
              color: errors[course_id] ? "error.main" : "inherit",
            }}
          >
            {fileData.fileName}
          </Typography>
        </Box>
        <IconButton
          size="small"
          onClick={() => handleRemoveFile(course_id)}
          sx={{ ml: 1 }}
        >
          <Close />
        </IconButton>
      </Paper>
    );
  };

  const renderPdfUpload = (course) => {
    const hasError = touched[course.course_id] && errors[course.course_id];

    return (
      <Box sx={{ mb: 2 }}>
        <PdfWarning course={course} />
        <input
          type="file"
          accept=".pdf"
          required
          onChange={(e) => handleFileChange(course.course_id, e)}
          onBlur={() => handleBlur(course.course_id, course)}
          style={{ display: "none" }}
          id={`file-upload-${course.course_id}`}
        />
        <label htmlFor={`file-upload-${course.course_id}`}>
          <Button
            variant="outlined"
            component="span"
            startIcon={<CloudUpload />}
            fullWidth
            sx={{
              mb: 1,
              height: 56,
              borderStyle: "dashed",
              borderWidth: 2,
              borderColor: hasError ? "error.main" : "inherit",
              color: hasError ? "error.main" : "inherit",
              "&:hover": {
                borderStyle: "dashed",
                borderWidth: 2,
                borderColor: hasError ? "error.main" : "inherit",
              },
            }}
          >
            Upload PDF Certificate
          </Button>
        </label>
        {renderFilePreview(course.course_id)}
        {hasError && (
          <Typography
            variant="caption"
            color="error.main"
            sx={{ display: "block", mt: 1 }}
          >
            {errors[course.course_id]}
          </Typography>
        )}
        <Typography variant="caption" color="textSecondary">
          Only PDF files are accepted (max 5MB) for {course.course_name}
        </Typography>
      </Box>
    );
  };

  const renderUrlInput = (course) => {
    return (
      <>
        <FortinetInstructions course={course} />
        <TextField
          fullWidth
          label={`Enter URL for ${course.course_name}`}
          variant="outlined"
          size="small"
          required
          value={formData[course.course_id]?.text || ""}
          onChange={(e) =>
            handleUrlChange(course.course_id, course.domain_id, e)
          }
          onBlur={() => handleBlur(course.course_id, course)}
          error={touched[course.course_id] && !!errors[course.course_id]}
          helperText={touched[course.course_id] && errors[course.course_id]}
          sx={{ mb: 2 }}
        />
      </>
    );
  };

  const renderCertificateInput = (course) => {
    if (courseData.status === 0) {
      return renderPdfUpload(course);
    }

    if (courseData.status === 1) {
      return renderUrlInput(course);
    }

    if (courseData.status === 2) {
      return course.is_url === "1"
        ? renderUrlInput(course)
        : renderPdfUpload(course);
    }

    return null;
  };

  const renderCertificateStatus = (course) => {
    switch (course.status) {
      case "1":
        return (
          <Alert severity="info" icon={<Pending />} sx={{ mb: 2 }}>
            <AlertTitle>Verification in Progress</AlertTitle>
            Your certificate for {course.course_name} has been submitted and is
            under review
          </Alert>
        );

      case "2":
        return (
          <Alert severity="success" icon={<Verified />} sx={{ mb: 2 }}>
            <AlertTitle>Verified</AlertTitle>
            Your certificate for {course.course_name} has been verified
            successfully
          </Alert>
        );

      case "3":
        return (
          <Paper
            elevation={0}
            sx={{
              mb: 2,
              border: "1px solid",
              borderColor: "error.main",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                p: 2,
                bgcolor: "error.light",
                borderBottom: "1px solid",
                borderColor: "error.main",
              }}
            >
              <Typography
                variant="subtitle1"
                color="error.contrastText"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontWeight: "medium",
                }}
              >
                <ErrorOutline />
                Certificate Rejected
              </Typography>
            </Box>
            <Box sx={{ p: 2 }}>
              <Typography
                variant="body2"
                color="error.main"
                sx={{ mb: 2, fontWeight: "bold" }}
              >
                Reason: {course.reason || "No reason provided"}
              </Typography>
              {renderCertificateInput(course)}
            </Box>
          </Paper>
        );

      default:
        return renderCertificateInput(course);
    }
  };

  const validateAllFields = () => {
    const newErrors = {};
    const submittableCourses = courseData.courses.filter(
      (course) => course.status === "0" || course.status === "3"
    );

    submittableCourses.forEach((course) => {
      const data = formData[course.course_id];
      const value = data?.text || data?.file;
      const error = validateField(course.course_id, value, course);
      if (error) {
        newErrors[course.course_id] = error;
      }

      // Mark all fields as touched
      setTouched((prev) => ({ ...prev, [course.course_id]: true }));
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateAllFields()) {
      toast.error("Please fill in all required fields correctly");
      return;
    }

    const submittableCourses = courseData.courses.filter(
      (course) => course.status === "0" || course.status === "3"
    );

    setUploading(true);

    try {
      const courseDataArray = await Promise.all(
        submittableCourses.map(async (course) => {
          const data = formData[course.course_id];
          const submission = {
            course_name: course.course_name,
            domain_id: course.domain_id,
            course_id: course.course_id,
          };

          if (data?.file) {
            const base64File = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result.split(",")[1]);
              reader.readAsDataURL(data.file);
            });
            submission.file = base64File;
          }

          if (data?.text) {
            submission.text = data.text;
          }

          return submission;
        })
      );

      await axios.post(
        `${BASE_URL}/domain/course_certificate_upload2`,
        { data: courseDataArray },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Certificates submitted successfully");

      // setVerifying(true);
      try {
        // await axios.get(`${BASE_URL}/certificate/verify`, {
        //   headers: {
        //     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        //   },
        // });
        // const updatedData = await fetchCourseData();
        // if (updatedData?.data?.courses) {
        //   updatedData.data.courses.forEach((course) => {
        //     if (course.status === "2") {
        //       toast.success(
        //         `${course.course_name} certificate verified successfully`
        //       );
        //     } else if (course.status === "3") {
        //       toast.error(`${course.course_name} certificate rejected`);
        //     }
        //   });
        // }
      } catch (verificationError) {
        console.error("Verification error:", verificationError);
        setUploading(false);
        setVerifying(false);
        toast.error("Certificate verification failed. Please try again later.");
      } finally {
        setVerifying(false);
        setUploading(false);
      }
    } catch (error) {
      console.error("Submission error:", error);
      toast.error("Failed to submit certificates. Please try again.");
      setUploading(false);
      // setVerifying(false);
    } finally {
      setUploading(false);
       setTimeout(() => window.location.reload(), 1200);
      // setVerifying(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          bgcolor: "grey.100",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <ErrorComponent error={error} />;
  }

  // If verifying, show only the verification status
  if (verifying) {
    return <VerificationStatus />;
  }

  const pendingOrRejectedCourses = courseData?.courses?.filter(
    (course) => course.status === "0" || course.status === "3"
  );

  const halfLength = Math.ceil((courseData?.courses?.length || 0) / 2);
  const leftColumnCourses = courseData?.courses?.slice(0, halfLength);
  const rightColumnCourses = courseData?.courses?.slice(halfLength);

  return (
    <Box sx={{ p: { xs: 2, sm: 3 }, minHeight: "100vh", bgcolor: "grey.100" }}>
      <Card
        sx={{
          maxWidth: 1200,
          mx: "auto",
          borderRadius: 2,
          boxShadow: (theme) => theme.shadows[3],
        }}
      >
        <CardContent sx={{ p: { xs: 2, sm: 3 } }}>
          <Typography
            variant="h5"
            component="h1"
            sx={{ mb: 3, fontWeight: "bold", textAlign: "center" }}
          >
            Certificate Upload
          </Typography>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  {leftColumnCourses?.map((course) => (
                    <Box key={course.course_id}>
                      <Typography
                        variant="h6"
                        sx={{ mb: 2, fontWeight: "medium" }}
                      >
                        {course.course_name}
                      </Typography>
                      {renderCertificateStatus(course)}
                    </Box>
                  ))}
                </Stack>
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack spacing={3}>
                  {rightColumnCourses?.map((course) => (
                    <Box key={course.course_id}>
                      <Typography
                        variant="h6"
                        sx={{ mb: 2, fontWeight: "medium" }}
                      >
                        {course.course_name}
                      </Typography>
                      {renderCertificateStatus(course)}
                    </Box>
                  ))}
                </Stack>
              </Grid>
            </Grid>

            {pendingOrRejectedCourses?.length > 0 && (
              <div>
                {!verifying && (
                  <SubmitButton
                    uploading={uploading}
                    handleSubmit={handleSubmit}
                    disabled={false} // Add your form validation logic here
                  />
                )}
              </div>
            )}
          </form>
        </CardContent>
      </Card>
    </Box>
  );
};

const ErrorComponent = ({ error }) => {
  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Card sx={{ maxWidth: 400, width: "100%" }}>
        <CardContent sx={{ textAlign: "center", p: 3 }}>
          <ErrorOutline color="error" sx={{ fontSize: 48, mb: 2 }} />
          <Typography variant="h6" sx={{ mb: 1 }}>
            Something Went Wrong
          </Typography>
          <Typography color="text.secondary" sx={{ mb: 2 }}>
            We couldn't fetch the course data. Please try again later.
          </Typography>
          <Paper
            sx={{ p: 1, bgcolor: "error.light", color: "error.dark", mb: 2 }}
          >
            <Typography variant="body2">Error: {error.message}</Typography>
          </Paper>
          <Button
            fullWidth
            variant="contained"
            onClick={() => window.location.reload()}
          >
            Try Again
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FileUpload;
