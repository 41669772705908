import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
const Instructions = () => {
  const [agreeChecked, setAgreeChecked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handlePrev = () => {
    navigate("/assessment");
  };

  const handleNext = () => {
    const screenWidth = window.innerWidth;
    const rootElement = document.documentElement;
    if (agreeChecked && screenWidth >= 991) {
      rootElement.requestFullscreen();
      if (location.pathname === "/login") {
        rootElement.exitFullscreen();
      }
    }
    if (agreeChecked) {
      navigate("/cohort/assessment");
    } else {
      navigate("/login");
    }
  };

  return (
    <div>
      <div className=" flex items-center justify-center h-screen">
        <div className=" w-8/12 h-auto p-10 rounded-sm bg-slate-200">
          <h2 className=" text-center p-2 text-lg">Instruction page</h2>
          <div className=" p-2 text-base">
            <h2 className=" font-bold text-lg">Technical Setup:</h2>
            <div>
              <h2 className=" font-medium">
                .This exam is designed to be taken exclusively on a laptop or
                Desktop.
              </h2>
              <h2>
                .Ensure your device (Laptop or Desktop) is in good working
                condition with a reliable internet connection.
              </h2>
              <h2>
                .Confirm that the webcam and microphone are functioning properly
                for video and audio monitoring during the exam.
              </h2>
              <h2>
                .Use a supported web browser and update it to the latest version
                to avoid compatibility issues with the online exam platform.
              </h2>
            </div>
            <h2 className=" font-bold text-lg">Time Management:</h2>
            <div>
              <h2>
                .Plan your time effectively, allocating specific time slots for
                each section or question to ensure completion within the
                designated time frame.
              </h2>
              <h2>
                .Keep an eye on the clock and pace yourself throughout the exam
                to avoid rushing through questions or running out of time.
              </h2>
            </div>
            <h2 className=" font-bold text-lg">Secure Environment:</h2>
            <div>
              <h2>
                .Choose a quiet and well-lit room with minimal distractions to
                create an exam-friendly environment.
              </h2>
              <h2>
                .Remove any unnecessary items from your desk or surrounding area
                to minimize the risk of academic misconduct.
              </h2>
              <h2>
                .Inform family members or roommates about the scheduled exam
                time to avoid interruptions.
              </h2>
              <h2 className=" font-medium">
                Reminder: Violating exam rules can have serious consequences. If you are found cheating, you will have one chance to retake the exam. We encourage you to uphold academic integrity and take the exam fairly, so you can progress to your internship exam without issue
              </h2>
            </div>
            <FormGroup>
              <FormControlLabel
                required
                control={
                  <Checkbox
                    checked={agreeChecked}
                    onChange={(e) => setAgreeChecked(e.target.checked)}
                  />
                }
                label="Agree"
              />
            </FormGroup>
            <div className=" flex justify-between mt-2">
              <button
                className=" w-14 h-8 rounded-md bg-red-400 text-white"
                onClick={handlePrev}
              >
                Back
              </button>
              <button
                className={`w-14 h-8 rounded-md ${
                  agreeChecked ? "bg-sky-400" : "bg-gray-400"
                } text-white`}
                onClick={handleNext}
                disabled={!agreeChecked}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Instructions;
