import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { Icon } from "@iconify/react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

const ResumePreview = ({ data, onSubmit, handleUpdate }) => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onAgree = () => {
    setIsAgreed(!isAgreed);
  };
  const renderCareerObjective = () => {
    const objective = data?.objective;
    return (
      <div className=" mb-8">
        <h2 className="text-lg font-bold mb-2">Career Objective:</h2>
        <p className=" text-base font-medium text-justify">{objective}</p>
      </div>
    );
  };
  const renderCandidateProfile = () => {
    const { name, email, alt_email, alt_phone, phone, city, profile_pic } =
      data?.profile;
    // console.log(typeof data?.profile, "all data");
    return (
      <div className="mb-8 border-b-2 border-slate-200 pb-5">
        {/* <h3 className="text-lg font-bold mb-2">Candidate Profile:</h3> */}
        <div className="flex justify-between gap-10">
          <div className=" text-base font-medium">
            <p className=" text-lg font-semibold">{name}</p>
            <p className=" flex gap-2">
              <Icon
                icon="material-symbols:mail"
                height={20}
                width={20}
                className="mt-1"
              />
              {email} /{alt_email}
            </p>
            <p className=" flex gap-2">
              <Icon
                icon="ri:phone-fill"
                height={20}
                width={20}
                className=" mt-1"
              />
              {phone} / {alt_phone}
            </p>
            <p className=" flex gap-2">
              <Icon
                icon="ic:baseline-place"
                height={20}
                width={20}
                className=" mt-1"
              />
              {city}
            </p>
          </div>
          {/* {profilePic && profilePic instanceof File && (
            <img
              src={URL.createObjectURL(profilePic)}
              alt="Profile Pic"
              className="h-20 w-20 rounded-full"
            />
          )} */}
          {profile_pic && (
            <div>
              <img
                src={profile_pic}
                alt="Profile Pic"
                className=" h-14 w-14 md:h-32 md:w-28 rounded-md"
              />
            </div>
          )}
        </div>
        {/* <div className=" h-0.5 w-full bg-slate-200 mt-5"></div> */}
      </div>
    );
  };

  const renderEducationDetails = () => {
    // console.log(data.education);
    const educationDetails = data?.education?.map((education, index) => (
      <tr key={index} className=" p-2 border-b-2 border-gray-100">
        <td className="font-bold pl-5">
          {" "}
          {education?.degree === "Other"
            ? education?.otherDegree
            : education?.degree}
        </td>
        <td className=" font-semibold pl-5">{education?.school}</td>
        <td className=" font-semibold pl-5">{education?.percentage}</td>
        <td className=" font-semibold ">{education?.passoutYear}</td>
      </tr>
    ));

    return (
      <div>
        <h3 className="text-lg font-bold mb-2">Education Details:</h3>
        <div className="border-2 border-gray-200 rounded-md overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className=" text-left font-semibold text-base h-4 bg-slate-300 w-full shadow-sm text-slate-800">
                <th className=" pl-3">Course/Degree</th>
                <th className=" pl-5">School/University</th>
                <th>Grade/Score</th>
                <th>Year</th>
              </tr>
            </thead>
            <tbody>{educationDetails}</tbody>
          </table>
        </div>
      </div>
    );
  };
  const renderExperienceDetails = () => {
    const experienceDetails = data?.internship?.map((experience, index) => {
      // Format fromDate and toDate
      const formattedFromDate = new Date(experience?.fromDate).toLocaleString(
        "en-us",
        {
          month: "short",
          year: "numeric",
        }
      );
      const formattedToDate = new Date(experience?.toDate).toLocaleString(
        "en-us",
        {
          month: "short",
          year: "numeric",
        }
      );
      const formattedFromDateDisplay = formattedFromDate.replace(
        /(\w+) (\d+)/,
        "$1. $2"
      );
      const formattedToDateDisplay = formattedToDate.replace(
        /(\w+) (\d+)/,
        "$1. $2"
      );

      return (
        <div key={index} className="mb-8">
          <div>
            <div className="flex justify-between text-base">
              <p className="font-bold">{experience?.jobTitle}</p>
              <p className=" font-medium italic text-sm">{`${formattedFromDateDisplay} - ${formattedToDateDisplay}`}</p>
            </div>
            <p className="font-semibold italic">{experience?.companyName}</p>
            <p>{experience?.location}</p>
            <p>{experience?.roleDescription}</p>
          </div>
        </div>
      );
    });

    return (
      <div>
        <h3 className="text-lg font-bold mb-2">Internships Details:</h3>
        {experienceDetails}
      </div>
    );
  };

  // const renderExperienceDetails = () => {
  //   const experienceDetails =
  //     data?.experienceDetails[0]?.experiencesDetails?.map(
  //       (experience, index) => (
  //         <div key={index} className="mb-8">
  //           <div>
  //             <div className=" flex justify-between">
  //               <p className="font-bold text-base">{experience?.jobTitle}</p>
  //               <p>{`${experience?.fromDate} - ${experience?.toDate}`}</p>
  //             </div>
  //             <p className=" font-semibold italic">{experience?.companyName}</p>
  //             <p>{experience?.location}</p>
  //             <p>{experience?.roleDescription}</p>
  //           </div>
  //         </div>
  //       )
  //     );

  //   return (
  //     <div>
  //       <h3 className="text-lg font-bold mb-2">Internships Details:</h3>
  //       {experienceDetails}
  //     </div>
  //   );
  // };

  const renderProjectDetails = () => {
    const projectDetails = data?.projects?.map((project, index) => (
      <div key={index} className="mb-8">
        <div>
          <p className="font-bold">{project?.projectTitle}</p>
          <p>{project?.projectDescription}</p>
        </div>
      </div>
    ));

    return (
      <div>
        <h3 className="text-lg font-bold mb-2 mt-8">Project Details:</h3>
        {projectDetails}
      </div>
    );
  };

  const renderSkillsDetails = () => {
    const skills = data?.skills;

    return (
      <div className="mb-8">
        {Array.isArray(skills) && skills.length > 0 && (
          <div>
            <h4 className="font-bold mb-2 text-lg">Skills:</h4>
            {skills.length <= 5 ? (
              <ul>
                {skills?.map((skill, index) => (
                  <li key={index} className="list-disc ml-5">
                    {skill}
                  </li>
                ))}
              </ul>
            ) : (
              <div className="grid grid-cols-2">
                <ul>
                  {skills
                    .slice(0, Math.ceil(skills.length / 2))
                    .map((skill, index) => (
                      <li key={index} className="list-disc ml-5">
                        {skill}
                      </li>
                    ))}
                </ul>
                <ul>
                  {skills
                    .slice(Math.ceil(skills.length / 2))
                    .map((skill, index) => (
                      <li key={index} className="list-disc ml-5">
                        {skill}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  // Add more functions for rendering other sections

  return (
    <div className="p-4 md:p-8 lg:p-12 w-full md:w-10/12 rounded-md shadow-md mx-auto justify-center">
      <h2 className="text-2xl font-bold mb-4">Review Your Information</h2>

      {renderCandidateProfile()}
      {renderCareerObjective()}
      {renderSkillsDetails()}
      {renderEducationDetails()}
      {renderProjectDetails()}
      {renderExperienceDetails()}

      {/* Add calls to other rendering functions for other sections */}

      <FormControlLabel
        control={
          <Checkbox checked={isAgreed} onChange={onAgree} color="secondary" />
        }
        label="I declare that above information is true to the best of my knowledge."
      />
      <div className=" flex justify-between mt-2">
        <Button
          className="mt-4"
          variant="contained"
          color="success"
          onClick={handleUpdate}
        >
          Edit Resume
        </Button>

        <div className=" flex gap-5 text-white">
          <LoadingButton
            loading={loading}
            variant="contained"
            color="secondary"
            onClick={onSubmit}
            disabled={!isAgreed}
          >
            <h2 className=" text-white">Submit1</h2>
            <Icon
              icon="mingcute:arrow-right-line"
              height={20}
              width={20}
              className=" text-white"
            />
          </LoadingButton>
          {/* <Button
            className="mt-4"
            variant="contained"
            color="secondary"
            onClick={onSubmit}
            disabled={!isAgreed}
          >
            <h2 className=" text-white">Continue Application</h2>
            <Icon
              icon="mingcute:arrow-right-line"
              height={20}
              width={20}
              className=" text-white"
            />
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default ResumePreview;
// import { useApi } from "@/hooks/useApi";
// import React, { useEffect, useState } from "react";
// import { useParams } from "react-router-dom";
// import {BASE_URL} from '../../services/configUrls'

// const ResumeViewer = () => {
//   const { userId } = useParams();
//   // const { sendRequest, loading, error } = useApi();
//   const [resumeData, setResumeData] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchResumeData = async () => {
//       setLoading(true);  // Start loading
//       setError(null);    // Reset error state
//       try {
//         const response = await fetch(`${BASE_URL}/talent/connect/modifiedResumeShow`, {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
//           },
//           body: JSON.stringify({ resumeId: userId }),
//         });
  
//         if (!response.ok) {
//           throw new Error("Failed to fetch data");
//         }
  
//         const data = await response.json();
//         setResumeData(data.resumeResponse);
//       } catch (err) {
//         console.error("Failed to fetch resume data:", err);
//         setError(err.message);
//       }
//       finally {
//         setLoading(false);  // Stop loading
//       }
//     };
  
//     if (userId) {
//       fetchResumeData();
//     }
//   }, [userId]);
  

//   // We'll only use API data, no dummy data

//   // Add responsive styles for scrolling and prepare for printing
//   React.useEffect(() => {
//     document.body.style.overflow = "auto";

//     // Add extra padding to the top of the body to account for sticky header
//     const handleScroll = () => {
//       const headerHeight = document.querySelector(".sticky")?.offsetHeight || 0;
//       if (window.scrollY > 20) {
//         document.body.style.paddingTop = `${headerHeight}px`;
//       } else {
//         document.body.style.paddingTop = "0px";
//       }
//     };

//     // Add print-specific styles
//     const style = document.createElement("style");
//     style.textContent = `
//       @media print {
//         body {
//           -webkit-print-color-adjust: exact !important;
//           print-color-adjust: exact !important;
//           background-color: white !important;
//         }
//         .print-hidden {
//           display: none !important;
//         }
//         .sticky {
//           position: relative !important;
//         }
//       }
//     `;
//     document.head.appendChild(style);

//     window.addEventListener("scroll", handleScroll);
//     window.addEventListener("resize", handleScroll);

//     return () => {
//       document.body.style.overflow = "";
//       document.body.style.paddingTop = "0px";
//       window.removeEventListener("scroll", handleScroll);
//       window.removeEventListener("resize", handleScroll);
//       document.head.removeChild(style);
//     };
//   }, []);

//   // Function to handle direct PDF download using the API
//   const handleDownloadPDF = async () => {
//     if (!userId) {
//       console.error("Resume ID not available");
//       return;
//     }

//     // Show loading indicator
//     const loadingIndicator = document.createElement("div");
//     loadingIndicator.className =
//       "fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50";
//     loadingIndicator.innerHTML =
//       '<div class="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>';
//     document.body.appendChild(loadingIndicator);

//     try {
// const response = await fetch(`${BASE_URL}talent/connect/userModifiedResumeShow`, {
//   method: "POST",
//   headers: {
//     "Content-Type": "application/json",
//     "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
//   },
//   body: JSON.stringify({ resumeId: userId }) // Send data as JSON
// });

//       // Handle the base64 response from the API
//       if (response && response.resumeResponse) {
//         // Get the base64 string from response
//         const base64PDF = response.resumeResponse;
        
//         // Create data URL with proper PDF MIME type prefix if it doesn't have one
//         const dataUrl = base64PDF.startsWith('data:') 
//           ? base64PDF 
//           :` data:application/pdf;base64,${base64PDF}`;
        
//         // Create link element and trigger download
//         const link = document.createElement('a');
//         link.href = dataUrl;
//         link.download = `${resumeData?.profile?.name || "Resume"}.pdf`;
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);
//       } else {
//         throw new Error("Invalid response format from API");
//       }
//     } catch (err) {
//       console.error("Failed to download resume:", err);
//       alert("Failed to download resume. Please try again.");
//     } finally {
//       // Remove loading indicator
//       document.body.removeChild(loadingIndicator);
//     }
//   };

//   // Removed the generatePDFFromHTML function as we're now using the API directly

//   if (loading) {
//     return (
//       <div className="flex items-center justify-center min-h-screen">
//         <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
//       </div>
//     );
//   }

//   if (error) {
//     return (
//       <div className="flex items-center justify-center min-h-screen">
//         <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
//           <p>{error}</p>
//         </div>
//       </div>
//     );
//   }

//   if (!resumeData) {
//     return (
//       <div className="flex items-center justify-center min-h-screen">
//         <p className="text-gray-500">No resume data available</p>
//       </div>
//     );
//   }

//   const formatDate = (dateString) => {
//     try {
//       return new Date(dateString).toLocaleDateString("en-US", {
//         year: "numeric",
//         month: "short",
//       });
//     } catch (e) {
//       return dateString;
//     }
//   };

//   return (
//     <div
//       id="resume-container"
//       className="w-full font-sans bg-gray-50 print:bg-white"
//     >
//       {/* Header - Sticky */}
//       <div className="sticky top-0 z-10 flex flex-col sm:flex-row items-start sm:items-center p-4 bg-gray-200 rounded-md shadow-sm transition-all duration-300">
//         <div className="mr-4 mb-3 sm:mb-0">
//           {resumeData.profile?.profile_pic ? (
//             <img
//               src={
//                 resumeData.profile.profile_pic.startsWith("data:")
//                   ? resumeData.profile.profile_pic
//                   : "/api/placeholder/80/80"
//               }
//               alt="Profile"
//               className="rounded-full w-16 h-16 border-2 border-white shadow-sm"
//             />
//           ) : (
//             <img
//               src="/api/placeholder/80/80"
//               alt="Profile"
//               className="rounded-full w-16 h-16 border-2 border-white shadow-sm"
//             />
//           )}
//         </div>
//         <div className="flex-1">
//           <h1 className="text-xl font-semibold text-gray-800">
//             {resumeData.profile?.name || "Name Not Available"}
//           </h1>
//           <p className="text-sm text-gray-600">
//             {resumeData.profile?.email || ""}
//           </p>
//           <p className="text-xs text-gray-500">
//             {resumeData.profile?.city || ""} • {resumeData.profile?.phone || ""}
//           </p>
//         </div>
//         <div className="flex space-x-2 mt-3 sm:mt-0 ml-auto print-hidden">
//           <button
//             onClick={handleDownloadPDF}
//             className="p-2 rounded border border-gray-300 bg-white hover:bg-gray-50 transition-colors"
//             title="Download as PDF"
//           >
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               className="h-4 w-4 text-blue-500"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
//               />
//             </svg>
//           </button>
//           <button className="p-2 rounded border border-gray-300 bg-white hover:bg-gray-50 transition-colors">
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               className="h-4 w-4 text-blue-500"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
//               />
//             </svg>
//           </button>
//         </div>
//       </div>

//       {/* Resume Content */}
//       <div className="flex flex-col md:flex-row p-4">
//         {/* Left Column */}
//         <div className="w-full md:w-2/3 md:pr-6">
//           {/* Career Objective Section */}
//           {resumeData.objective && (
//             <div className="mb-8 p-4 border border-gray-200 rounded-md bg-white">
//               <div className="flex items-center border-l-4 border-blue-500 pl-3 mb-4">
//                 <h2 className="text-lg font-medium">
//                   {resumeData.sec_0 || "Career Objective"}
//                 </h2>
//               </div>
//               <p className="text-sm whitespace-pre-line">
//                 {resumeData.objective}
//               </p>
//             </div>
//           )}

//           {/* Internship Details Section */}
//           {resumeData.internship && resumeData.internship.length > 0 && (
//             <div className="mb-8 p-4 border border-gray-200 rounded-md bg-white">
//               <div className="flex items-center border-l-4 border-blue-500 pl-3 mb-4">
//                 <h2 className="text-lg font-medium">
//                   {resumeData.sec_4 || "Internship Details"}
//                 </h2>
//               </div>

//               {resumeData.internship.map((internship, index) => (
//                 <div key={index} className="mb-6 p-3 border-b border-gray-100">
//                   <div className="flex flex-col sm:flex-row sm:justify-between mb-1">
//                     <h3 className="font-medium">
//                       {internship.jobTitle} @ {internship.companyName}
//                     </h3>
//                     <svg
//                       xmlns="http://www.w3.org/2000/svg"
//                       className="h-4 w-4 text-gray-500"
//                       fill="none"
//                       viewBox="0 0 24 24"
//                       stroke="currentColor"
//                     >
//                       <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth={2}
//                         d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
//                       />
//                     </svg>
//                   </div>
//                   <p className="text-sm text-gray-600 mb-2">
//                     {formatDate(internship.fromDate)} -{" "}
//                     {formatDate(internship.toDate)} | {internship.location}
//                   </p>
//                   <div className="mb-2">
//                     <p className="text-sm whitespace-pre-line">
//                       {internship.roleDescription}
//                     </p>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           )}

//           {/* Projects Section */}
//           {resumeData.projects && resumeData.projects.length > 0 && (
//             <div className="mb-8 p-4 border border-gray-200 rounded-md bg-white">
//               <div className="flex items-center border-l-4 border-blue-500 pl-3 mb-4">
//                 <h2 className="text-lg font-medium">
//                   {resumeData.sec_5 || "Project Details"}
//                 </h2>
//               </div>

//               {resumeData.projects.map((project, index) => (
//                 <div key={index} className="mb-6 p-3 border-b border-gray-100">
//                   <h3 className="font-medium mb-2">{project.projectTitle}</h3>
//                   <div className="mb-2">
//                     <p className="text-sm whitespace-pre-line">
//                       {project.projectDescription}
//                     </p>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>

//         {/* Right Column */}
//         <div className="w-full md:w-1/3 mt-6 md:mt-0">
//           {/* Education Section */}
//           {resumeData.education && resumeData.education.length > 0 && (
//             <div className="mb-6 p-4 border border-gray-200 rounded-md bg-white">
//               <div className="flex items-center border-l-4 border-blue-500 pl-3 mb-4">
//                 <h2 className="text-lg font-medium">
//                   {resumeData.sec_2 || "Educational Details"}
//                 </h2>
//               </div>

//               {resumeData.education.map((edu, index) => (
//                 <div key={index} className="mb-4 pb-2 border-b border-gray-100">
//                   <h3 className="font-medium">{edu.school}</h3>
//                   <p className="text-sm">
//                     {edu.degree} | {edu.percentage}%
//                   </p>
//                   <p className="text-xs text-gray-500">
//                     {new Date(edu.passoutYear).getFullYear()}
//                   </p>
//                 </div>
//               ))}
//             </div>
//           )}

//           {/* Skills Section */}
//           {resumeData.skills && resumeData.skills.length > 0 && (
//             <div className="mb-6 p-4 border border-gray-200 rounded-md bg-white">
//               <div className="flex items-center border-l-4 border-blue-500 pl-3 mb-4">
//                 <h2 className="text-lg font-medium">
//                   {resumeData.sec_3 || "Skills"}
//                 </h2>
//               </div>
//               <div className="flex flex-wrap gap-2">
//                 {resumeData.skills.map((skill, index) => (
//                   <span
//                     key={index}
//                     className="bg-gray-100 px-2 py-1 rounded text-xs"
//                   >
//                     {skill}
//                   </span>
//                 ))}
//               </div>
//             </div>
//           )}

//           {/* Virtual Internships Section */}
//           {resumeData.eduskills_internship &&
//             resumeData.eduskills_internship.length > 0 && (
//               <div className="mb-6 p-4 border border-gray-200 rounded-md bg-white">
//                 <div className="flex items-center border-l-4 border-blue-500 pl-3 mb-4">
//                   <h2 className="text-lg font-medium">Virtual Internships</h2>
//                 </div>

//                 {resumeData.eduskills_internship.map((intern, index) => (
//                   <div
//                     key={index}
//                     className="mb-4 pb-2 border-b border-gray-100"
//                   >
//                     <h3 className="font-medium">{intern.domain}</h3>
//                     <p className="text-xs text-gray-500">
//                       {intern.started_at} - {intern.ended_at}
//                     </p>
//                   </div>
//                 ))}
//               </div>
//             )}

//           {/* Contact Details */}
//           {resumeData.profile && (
//             <div className="mb-6 p-4 border border-gray-200 rounded-md bg-white">
//               <div className="flex items-center border-l-4 border-blue-500 pl-3 mb-4">
//                 <h2 className="text-lg font-medium">
//                   {resumeData.sec_1 || "Profile Details"}
//                 </h2>
//               </div>

//               <div className="mb-2">
//                 {resumeData.profile.email && (
//                   <p className="text-sm mb-1 flex items-start">
//                     <span className="mr-2 text-gray-700">•</span>
//                     <span>Email: {resumeData.profile.email}</span>
//                   </p>
//                 )}
//                 {resumeData.profile.alt_email && (
//                   <p className="text-sm mb-1 flex items-start">
//                     <span className="mr-2 text-gray-700">•</span>
//                     <span>Alt Email: {resumeData.profile.alt_email}</span>
//                   </p>
//                 )}
//                 {resumeData.profile.phone && (
//                   <p className="text-sm mb-1 flex items-start">
//                     <span className="mr-2 text-gray-700">•</span>
//                     <span>Phone: {resumeData.profile.phone}</span>
//                   </p>
//                 )}
//                 {resumeData.profile.alt_phone && (
//                   <p className="text-sm mb-1 flex items-start">
//                     <span className="mr-2 text-gray-700">•</span>
//                     <span>Alt Phone: {resumeData.profile.alt_phone}</span>
//                   </p>
//                 )}
//                 {resumeData.profile.city && (
//                   <p className="text-sm mb-1 flex items-start">
//                     <span className="mr-2 text-gray-700">•</span>
//                     <span>Location: {resumeData.profile.city}</span>
//                   </p>
//                 )}
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ResumeViewer;