// AicteVerificationFlow.jsx
import React, { useState, useRef, useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  IconButton,
  Modal,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Divider,
  LinearProgress,
  ImageList,
  ImageListItem,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import {
  WarningAmber as WarningIcon,
  Close as CloseIcon,
  CheckCircleOutline as CheckIcon,
  Info as InfoIcon,
  ArrowForward as ArrowForwardIcon,
  ZoomIn as ZoomInIcon,
  LockClock as LockIcon,
} from "@mui/icons-material";

// Import your images
import aicteSample from "../../assets/aictesample.png";
import aicteSample1 from "../../assets/aictesample1.jpeg";
import aicteSample2 from "../../assets/aictesample2.jpeg";

const steps = [
  {
    label: "Login to AICTE Portal",
    description:
      "Visit the AICTE Internship portal and sign in with your credentials. Make sure you're using the latest version of your browser for the best experience.",
    icon: "🔐",
  },
  {
    label: "Navigate to Profile Section",
    description:
      "After logging in, locate and click on the Profile section in the left-side menu. This is where you'll find all your personal information.",
    icon: "👤",
    images: [{ src: aicteSample, alt: "AICTE Portal Navigation" }],
  },
  {
    label: "Check Your Information",
    description:
      "Review all your personal and academic details carefully. Make sure everything is up-to-date and accurate.",
    icon: "📋",
    images: [{ src: aicteSample1, alt: "Profile Information Review" }],
  },
  {
    label: "Generate PDF",
    description: "Follow these specific steps to generate your PDF:",
    substeps: [
      "Right-click anywhere on your profile page",
      'Select "Print" from the context menu',
      'In the printer options, choose "Save as PDF"',
      'Click "Save" and choose your save location',
      "Ensure the file size is under 1MB",
    ],
    icon: "💾",
    images: [{ src: aicteSample2, alt: "PDF Generation Process" }],
  },
];

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 800,
  maxHeight: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 0,
  display: "flex",
  flexDirection: "column",
};

// ImageWithZoom Component
const ImageWithZoom = ({ src, alt }) => {
  const [isZoomed, setIsZoomed] = useState(false);

  return (
    <>
      <Box sx={{ position: "relative", cursor: "pointer" }}>
        <img
          src={src}
          alt={alt}
          style={{
            width: "100%",
            height: "auto",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          }}
          onClick={() => setIsZoomed(true)}
        />
        <IconButton
          sx={{
            position: "absolute",
            right: 8,
            bottom: 8,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.9)",
            },
          }}
          onClick={() => setIsZoomed(true)}
        >
          <ZoomInIcon />
        </IconButton>
      </Box>
      <Modal
        open={isZoomed}
        onClose={() => setIsZoomed(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "90vw",
            maxHeight: "90vh",
            overflow: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 1,
            borderRadius: 2,
          }}
        >
          <IconButton
            onClick={() => setIsZoomed(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              bgcolor: "rgba(255, 255, 255, 0.8)",
              zIndex: 1,
              "&:hover": {
                bgcolor: "rgba(255, 255, 255, 0.9)",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={src}
            alt={alt}
            style={{
              maxWidth: "100%",
              maxHeight: "85vh",
              objectFit: "contain",
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

const AicteVerificationFlow = ({ isOpen, onClose, onComplete }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(new Set());
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [confirmChecked, setConfirmChecked] = useState(false);
  const [timeSpentOnStep, setTimeSpentOnStep] = useState(0);
  const [canProceed, setCanProceed] = useState(false);

  const MINIMUM_STEP_TIME = 15; // Minimum time in seconds required for each step

  useEffect(() => {
    let timer;
    if (isOpen && activeStep < steps.length) {
      timer = setInterval(() => {
        setTimeSpentOnStep((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isOpen, activeStep]);

  useEffect(() => {
    if (timeSpentOnStep >= MINIMUM_STEP_TIME) {
      setCanProceed(true);
    }
  }, [timeSpentOnStep]);

  useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
      setCompletedSteps(new Set());
      setTimeSpentOnStep(0);
      setCanProceed(false);
      setConfirmChecked(false);
    }
  }, [isOpen]);

  const resetStep = () => {
    setTimeSpentOnStep(0);
    setCanProceed(false);
  };

  const handleStepAttempt = () => {
    if (timeSpentOnStep < MINIMUM_STEP_TIME) {
      alert(
        `Please spend at least ${MINIMUM_STEP_TIME} seconds reviewing this step.`
      );
      return;
    }
    setShowConfirmDialog(true);
  };

  const handleStepConfirm = () => {
    if (confirmChecked) {
      setCompletedSteps((prev) => new Set([...prev, activeStep]));
      if (activeStep === steps.length - 1) {
        onComplete();
        onClose();
      } else {
        setActiveStep((prev) => prev + 1);
      }
      setShowConfirmDialog(false);
      setConfirmChecked(false);
      resetStep();
    }
  };

  const renderTimeRemaining = () => {
    const remaining = Math.max(0, MINIMUM_STEP_TIME - timeSpentOnStep);
    return remaining > 0 ? (
      <Typography
        color="error"
        sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}
      >
        <LockIcon fontSize="small" />
        Please review for {remaining} more seconds
      </Typography>
    ) : null;
  };

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="verification-steps-title"
      >
        <Box sx={modalStyle}>
          {/* Header */}
          <Box
            sx={{
              p: 2,
              borderBottom: "1px solid",
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              bgcolor: "primary.main",
              color: "primary.contrastText",
              borderRadius: "8px 8px 0 0",
            }}
          >
            <Typography variant="h6" component="h2">
              AICTE Profile PDF Instructions
            </Typography>
            <IconButton
              size="small"
              onClick={onClose}
              sx={{ color: "inherit" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          {/* Warning Alert */}
          <Alert severity="warning" icon={<WarningIcon />} sx={{ m: 2 }}>
            <AlertTitle>Important: Complete Each Step</AlertTitle>
            You must spend at least {MINIMUM_STEP_TIME} seconds reviewing each
            step and confirm your understanding.
          </Alert>

          {/* Steps Content */}
          <Box sx={{ flex: 1, overflowY: "auto", p: 3 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={index} completed={completedSteps.has(index)}>
                  <StepLabel>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {step.label}
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography>{step.description}</Typography>
                    {step.substeps && (
                      <Box sx={{ mt: 2, ml: 2 }}>
                        {step.substeps.map((substep, idx) => (
                          <Box
                            key={idx}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 1,
                            }}
                          >
                            <ArrowForwardIcon
                              sx={{
                                mr: 1,
                                color: "primary.main",
                                fontSize: "small",
                              }}
                            />
                            <Typography variant="body2">{substep}</Typography>
                          </Box>
                        ))}
                      </Box>
                    )}
                    {step.images && (
                      <Box sx={{ mt: 2 }}>
                        <ImageList cols={1} gap={16}>
                          {step.images.map((img, imgIndex) => (
                            <ImageListItem key={imgIndex}>
                              <ImageWithZoom src={img.src} alt={img.alt} />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </Box>
                    )}
                    {renderTimeRemaining()}
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant="contained"
                        onClick={handleStepAttempt}
                        disabled={!canProceed}
                      >
                        {index === steps.length - 1
                          ? "Complete Review"
                          : "Continue"}
                      </Button>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
      </Modal>

      {/* Step Confirmation Dialog */}
      <Dialog
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
      >
        <DialogTitle>Confirm Your Understanding</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please confirm that you have read and understood the instructions
            for this step.
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmChecked}
                onChange={(e) => setConfirmChecked(e.target.checked)}
              />
            }
            label="I have carefully read and understood these instructions"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmDialog(false)}>
            Review Again
          </Button>
          <Button
            onClick={handleStepConfirm}
            disabled={!confirmChecked}
            variant="contained"
          >
            Confirm & Continue
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AicteVerificationFlow;