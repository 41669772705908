import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import CandidateProfileForm from "../../components/Form/CandidateProfileForm";
import EducationalDetailsForm from "../../components/Form/EductionDetailsForm";
import SkillsForm from "../../components/Form/SkillsForm";
import ExperienceForm from "../../components/Form/ExperienceForm";
import ProjectDetailsForm from "../../components/Form/ProjectsDetailsForm";
import ReviewForm from "../../components/Form/ReviewForm";
import { Modal, useMediaQuery, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { toast } from "react-toastify";
import {
  initialLoadDataService,
  submitJobService,
  submitResumeService,
} from "../../services/dataService";
import { JobDescriptionService } from "../../services/dataService";
import ObjectiveForm from "../../components/Form/ObjectiveForm";
import ResumePreview from "../../components/Form/ResumePreview";
import Cookies from "js-cookie";

const Jobdescription = ({  agreementResponses }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [resumeId, setResumeId] = useState();
  const [formData, setFormData] = useState({
    profile: {},
    education: [],
    skills: [],
    projects: [],
    internship: [],
    objective: "",
  });
  const [jobDetailsData1, setJobDetailsData1] = useState();
  const [error, setError] = useState(false);
  const [updateMode, setUpdateMode] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const JobId = location.state?.rowData;
  const [stepCompletion, setStepCompletion] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
    4: false,
    5: false,
    6: false,
  });
  const [firstLoadData, setFirstLoadData] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    // Load active step index from local storage when the component mounts
    const storedActiveStep = localStorage.getItem("active_step");
    const storedStepCompletion = JSON.parse(
      localStorage.getItem("step_completion")
    );
    const storedFormData = JSON.parse(localStorage.getItem("form_data"));

    setActiveStep((prevActiveStep) => {
      if (firstLoadData && Object.keys(firstLoadData).length !== 0) {
        return 6;
      } else if (storedActiveStep) {
        return Number(storedActiveStep);
      } else {
        return 0;
      }
    });
    if (storedStepCompletion) {
      setStepCompletion(storedStepCompletion);
    }
    if (storedFormData) {
      setFormData(storedFormData);
    }
  }, [firstLoadData]);
useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        // setLoading(true);
        const response = await JobDescriptionService.jobDescription(JobId.job_id);
        setJobDetailsData1(response?.data);
        console.log("the jobDetails In JobDetailsJsx:",response?.data);
        // setIsJobApplied(response?.data?.is_applied_successfully);
        // setIsHaveResume(response?.data?.is_resume);
        // setJobDetailsData(response?.data?.data);
      } catch (error) {
        setError(error.message);
      } finally {
        // setLoading(false);
      }
    };
    fetchJobDetails();
  }, [JobId.job_id]);
  //..................
  const onComplete = () => {
    // Handle the completion logic here
    const newStepCompletion = { ...stepCompletion, [activeStep]: true };
    setStepCompletion(newStepCompletion);
    localStorage.setItem("step_completion", JSON.stringify(newStepCompletion));
  };
  // useEffect(() => {
  //   localStorage.setItem("form_data", JSON.stringify(formData));
  // }, [formData]);
  //..................
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  
  // const handleJobApply = async () => {
  //   try {
  //     // Get agreement values using Cookies.get()
  //     const bondAgreement = Cookies.get("bondAgreement");
  //     const relocationAgreement = Cookies.get("relocationAgreement");
  //     const documentForwardingAgreement = Cookies.get(
  //       "documentForwardingAgreement"
  //     );

  //     console.log(bondAgreement, relocationAgreement, "agreement");

  //     const responses = await initialLoadDataService.initialLoadData();
  //     const response = await submitJobService.submittedJobData({
  //       jobId: JobId.job_id,
  //       resumeId: responses.data?.data?.resume_id,
  //       companyAgreement: JSON.stringify({
  //         bondAgreement: bondAgreement,
  //         relocationAgreement: relocationAgreement,
  //         documentForwardingAgreement: documentForwardingAgreement,
  //       }),
  //     });

  //     handleCloseModal();
  //     handleSuccessMessage(response?.data?.detail);

  //     // Clear cookies using Cookies.remove()
  //     Cookies.remove("bondAgreement");
  //     Cookies.remove("relocationAgreement");

  //     // Clear localStorage
  //     localStorage.removeItem("form_data");
  //     localStorage.removeItem("profileData");
  //     localStorage.removeItem("projectData");
  //     localStorage.removeItem("experienceData");
  //     localStorage.removeItem("skillData");
  //     localStorage.removeItem("careerObjective");
  //     localStorage.removeItem("educationData");
  //     localStorage.removeItem("eductionDataLength");
  //     localStorage.removeItem("step_completion");
  //     localStorage.removeItem("active_step");

  //     navigate("/jobs/opening");
  //   } catch (error) {
  //     console.error("Error applying for job:", error);
  //     console.log(resumeId);
  //   }
  // };
  const handleJobApply = async () => {
    try {
      // Get agreement values using Cookies.get()
      const bondAgreement = Cookies.get("bondAgreement");
      const relocationAgreement = Cookies.get("relocationAgreement");
      const documentForwardingAgreement = Cookies.get("documentForwardingAgreement");
  
      console.log(bondAgreement, relocationAgreement, "agreement");
  
      const responses = await initialLoadDataService.initialLoadData();
      
      // Here, checking the condition based on usePreviousTemplate from jobDetailsData
      const companyAgreement = jobDetailsData1?.usePreviousTemplate === 0
        ? Cookies.get("responsesToLog") // Use the agreementResponses object when usePreviousTemplate is 0
        : {
            bondAgreement: bondAgreement,
            relocationAgreement: relocationAgreement,
            documentForwardingAgreement: documentForwardingAgreement,
          };
  
      const response = await submitJobService.submittedJobData({
        jobId: JobId.job_id,
        resumeId: responses.data?.data?.resume_id,
        companyAgreement: JSON.stringify(companyAgreement), // Sending the proper agreement data
      });
  
      handleCloseModal();
      handleSuccessMessage(response?.data?.detail);
  
      // Clear cookies using Cookies.remove()
      Cookies.remove("bondAgreement");
      Cookies.remove("relocationAgreement");
  
      // Clear localStorage
      localStorage.removeItem("form_data");
      localStorage.removeItem("profileData");
      localStorage.removeItem("projectData");
      localStorage.removeItem("experienceData");
      localStorage.removeItem("skillData");
      localStorage.removeItem("careerObjective");
      localStorage.removeItem("educationData");
      localStorage.removeItem("eductionDataLength");
      localStorage.removeItem("step_completion");
      localStorage.removeItem("active_step");
  
      navigate("/jobs/opening");
    } catch (error) {
      console.error("Error applying for job:", error);
      console.log(resumeId);
    }
  };
  

  //................
  const handleNext = (data) => {
    let updatedFormData = { ...formData };

    switch (activeStep) {
      case 0:
        updatedFormData = {
          ...formData,
          profile: {
            ...data.profile,
          },
        };
        break;
      case 1:
        updatedFormData = {
          ...formData,
          education: [
            ...(Array.isArray(data.education)
              ? data.education
              : [data.education]),
          ],
        };
        break;
      case 2:
        updatedFormData = {
          ...formData,
          skills: data.skills,
        };
        break;
      case 3:
        updatedFormData = {
          ...formData,
          projects: [
            ...(Array.isArray(data.projects) ? data.projects : [data.projects]),
          ],
        };
        break;
      case 4:
        updatedFormData = {
          ...formData,
          internship: [
            ...(Array.isArray(data.internship)
              ? data.internship
              : [data.internship]),
          ],
        };
        break;
      case 5:
        updatedFormData = {
          ...formData,
          objective: data.objective,
        };
        break;
      default:
        break;
    }

    setFormData(updatedFormData);
    setError(false);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // Save to localStorage
    localStorage.setItem("form_data", JSON.stringify(updatedFormData));
    localStorage.setItem("step_completion", JSON.stringify(stepCompletion));
    localStorage.setItem("active_step", activeStep + 1);
  };
  //.................
  const handleUpdate = () => {
    setUpdateMode(true);
    setActiveStep(0);
  };
  //.................

  const handleBack = () => {
    setError(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    localStorage.setItem("active_step", activeStep - 1);
  };

  const handleError = () => {
    setError(true);
  };
  function handleSuccessMessage(message) {
    toast.success(message, {
      autoClose: 4000,
      position: "top-center",
    });
  }
  const handleFinish = async () => {
    // Get agreement values from cookies
    const bondAgreement = document.cookie
      .split("; ")
      .find((row) => row.startsWith("bondAgreement="))
      ?.split("=")[1];

    const relocationAgreement = document.cookie
      .split("; ")
      .find((row) => row.startsWith("relocationAgreement="))
      ?.split("=")[1];

    const formattedData = {
      objective: formData?.objective || firstLoadData.objective,
      profile: {
        name: formData?.profile?.name || firstLoadData.profile.name,
        profile_pic:
          formData?.profile?.profile_pic || firstLoadData?.profile?.profile_pic,
        email: formData?.profile?.email || firstLoadData.profile.email,
        alt_email:
          formData?.profile?.alt_email ||
          (firstLoadData?.profile?.alt_email !== undefined
            ? firstLoadData.profile.alt_email
            : ""),
        alt_phone:
          formData?.profile?.alt_phone ||
          (firstLoadData?.profile?.alt_phone !== undefined
            ? firstLoadData.profile.alt_phone
            : ""),
        phone: formData?.profile?.phone || firstLoadData.profile.phone,
        city: formData?.profile?.city || firstLoadData.profile.city,
      },
      internship:
        formData?.internship?.length > 0
          ? formData?.internship
          : firstLoadData?.internship || [],
      projects:
        formData?.projects?.length > 0
          ? formData?.projects
          : firstLoadData?.projects || [],
      education:
        formData?.education?.length > 0
          ? formData?.education
          : firstLoadData?.education || [],
      skills:
        formData?.skills?.length > 0
          ? formData?.skills
          : firstLoadData?.skills || [],
      bondAgreement: bondAgreement === "true",
      relocationAgreement: relocationAgreement === "true",
    };

    try {
      const response = await submitResumeService.submittedResumeData(
        formattedData
      );

      if (response && response.status === 200) {
        // Clear cookies after successful submission

        handleSuccessMessage(response?.data?.detail);
        setResumeId(response?.data?.data?.resume_id);
        handleOpenModal(JobId);
      } else {
        console.error("Unexpected response format or status:", response);
      }
    } catch (error) {
      console.error("Error submitting resume:", error);
    } finally {
      console.log("set loading false");
      console.log(resumeId, "submission");
    }
  };

  const isStepComplete = (step) => {
    switch (step) {
      case 0:
        return Object.keys(formData.profile).length > 0;
      case 1:
        return Object.keys(formData.education).length > 0;
      case 2:
        return Object.keys(formData.skills).length > 0;
      case 3:
        return Array.isArray(formData.projects) && formData.projects.length > 0;
      case 4:
        return (
          Array.isArray(formData.internship) && formData.internship.length > 0
        );
      case 5:
        return formData.objective !== "";
      default:
        return false;
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await initialLoadDataService.initialLoadData();
        console.log(response, "fetch initial data");
        // console.log(response?.data?.data);
        setFirstLoadData(response?.data?.data);
        console.log(response?.data?.data?.resume_id);
        setResumeId(response?.data?.data?.resume_id);
        console.log(resumeId);
      } catch (error) {
        console.log("Error fetching initial data:", error);
      }
    };
    fetchInitialData();
  }, []);

  const steps = [
    {
      label: "Candidate Profile",
      form: (
        <CandidateProfileForm
          onNext={(data) => handleNext({ profile: data })}
          onError={handleError}
          onComplete={onComplete}
          initialData={firstLoadData}
        />
      ),
    },
    {
      label: "Education Details",
      form: (
        <EducationalDetailsForm
          onNext={(data) => handleNext({ education: data })}
          onError={handleError}
          onComplete={onComplete}
          handleBack={handleBack}
          initialEductionData={firstLoadData}
        />
      ),
    },
    {
      label: "Skills",
      form: (
        <SkillsForm
          onNext={(data) => handleNext({ skills: data })}
          onError={handleError}
          onComplete={onComplete}
          handleBack={handleBack}
          initialData={firstLoadData}
        />
      ),
    },
    {
      label: "Projects Details",
      form: (
        <ProjectDetailsForm
          onNext={(data) => handleNext({ projects: data })}
          onError={handleError}
          onComplete={onComplete}
          handleBack={handleBack}
          initialData={firstLoadData}
        />
      ),
    },
    {
      label: "Work Experience",
      form: (
        <ExperienceForm
          onNext={(data) => handleNext({ internship: data })}
          onError={handleError}
          onComplete={onComplete}
          handleBack={handleBack}
          initialData={firstLoadData}
        />
      ),
    },
    {
      label: "Objective",
      form: (
        <ObjectiveForm
          onNext={(data) => handleNext({ objective: data })}
          onError={handleError}
          onComplete={onComplete}
          handleBack={handleBack}
          initialData={firstLoadData}
        />
      ),
    },
    {
      label: "Review",
      form: (
        <ReviewForm
          data={formData}
          onComplete={onComplete}
          onSubmit={handleFinish}
          handleBack={handleBack}
          isInitialDataAvailable={!!firstLoadData}
        />
      ),
    },
  ];
  //.................

  const isMediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
  return (
    <div className="m-5 min-h-[620px] h-auto">
      {Object.keys(firstLoadData || {}).length > 0 && !updateMode ? (
        <ResumePreview
          data={firstLoadData}
          onSubmit={handleFinish}
          handleUpdate={handleUpdate}
        />
      ) : (
        <div className="m-5 flex flex-col sm:flex-row sm:justify-center gap-10 min-h-[620px] h-auto">
          <div className="w-4/12 md:p-8 p-4">
            <Stepper
              activeStep={activeStep}
              orientation={isMediumScreen ? "vertical" : "horizontal"}
              color="success"
            >
              {steps.map((step, index) => (
                <Step
                  key={step.label}
                  completed={isStepComplete(index)}
                  sx={{ color: "red" }}
                >
                  <StepLabel style={{ color: "green" }}>
                    {isMediumScreen ? (
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: "bold",
                          color: isStepComplete(index) ? "green" : "",
                        }}
                      >
                        {step.label}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div className="w-full rounded-md shadow-md">
            {steps[activeStep].form}
            {/* <Box sx={{ mb: 2 }}></Box> */}
          </div>
        </div>
      )}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            boxShadow: 24,
            borderRadius: 2,
            p: 4,
            textAlign: "center",
          }}
        >
          {/* Modal content */}
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure you want to apply for this job?
          </Typography>
          <div className=" flex justify-between mt-5">
            <Button
              onClick={handleCloseModal}
              color="error"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              color="success"
              variant="contained"
              onClick={handleJobApply}
            >
              Apply
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default Jobdescription;
