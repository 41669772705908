// import { Icon } from "@iconify/react";
// import React, { useEffect, useState } from "react";
// import CountUp from "react-countup";
// // import Chart from "react-apexcharts";
// import ReactApexChart from "react-apexcharts";
// import Skeleton from "@mui/material/Skeleton";
//  import api from "../../services/api"

// //........graph skeleton
// const GraphSkeleton = () => (
//   <div className="w-3/12 mr-48 -my-5">
//     <div className="bg-gray-300 h-80 animate-pulse"></div>
//   </div>
// );
// const Dashboard = () => {
//   const [loading, setLoading] = useState(true);
//   const [series, setSeries] = useState([
//     { name: "Applied", data: 44 },
//     { name: "Inprogress", data: 75 },
//     { name: "Shortlisted", data: 30 },
//     { name: "Provisional", data: 15 },
//     { name: "Completed", data: 8 },
//     { name: "Certified", data: 3 },
//   ]);
//   const [jobs, setJobs] = useState([
//     { name: "Openings", data: 144 },
//     { name: "Applied", data: 144 },
//     { name: "Inprogress", data: 55 },
//     { name: "Rejected", data: 19 },
//     { name: "Selected", data: 13 },
//   ]);
//   const options = {
//     chart: {
//       width: 380,
//       type: "donut",
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     responsive: [
//       {
//         breakpoint: 480,
//         options: {
//           chart: {
//             width: 200,
//           },
//           legend: {
//             show: false,
//           },
//         },
//       },
//     ],
//     legend: {
//       position: "right",
//       offsetY: 0,
//       height: 230,
//     },
//     labels: series.map((item) => item.name),
//     colors: ["#468DB9", "#3FD944", "#FBC02D", "#B24928", "#4923B2"], // Set colors for the chart
//     plotOptions: {
//       pie: {
//         dataLabels: {
//           style: {
//             fontSize: "36px",
//             fontWeight: "bold",
//             fontFamily: "Arial, sans-serif",
//             fill: "#000000",
//           },
//         },
//       },
//     },
//   };
//   // useEffect(() => {
//   //   // Simulate data fetching delay
//   //   const timer = setTimeout(() => {
//   //     // Simulated updated data
//   //     const updatedSeries = [
//   //       { name: "Applied", data: 44 },
//   //       { name: "Inprogress", data: 75 },
//   //       { name: "Shortlisted", data: 30 },
//   //       { name: "Provisional", data: 15 },
//   //       { name: "Completed", data: 8 },
//   //       { name: "Certified", data: 3 },
//   //     ];
//   //     setSeries(updatedSeries);
//   //     setLoading(false);
//   //   }, 2000); // Simulated delay of 2 seconds

//   //   return () => clearTimeout(timer);
//   // }, []);
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await api.get("/internship/studentDashboard", {
//           headers: {
//             "Content-Type": "application/json",
//             "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
//           },
//         });

//         setJobs(response?.data?.jobsResponse); // Use response.data instead of response.json()
//         setSeries(response?.data?.internshipResponse);
//       } catch (error) {
//         console.error("Fetch error:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div>
//       <div class=" bg-blue-300 m-5 h-auto  rounded-md pb-3">
//         <div className=" h-7 flex justify-center">
//           <div className=" bg-slate-100 px-6 pt-1 text-center rounded-md mt-5 h-8 shadow-md">
//             <h2 className=" tracking-wider text-blue-500 font-semibold text-base">
//               {" "}
//               Internships
//             </h2>
//           </div>
//         </div>
//         <div class="content mt-12 pl-10 md:flex justify-between mb-5">
//           {loading ? (
//             <div className=" m-5 grid md:grid-cols-2 grid-cols-1 gap-x-6 gap-y-4 text-slate-200">
//               {[...Array(5)].map((_, index) => (
//                 <Skeleton
//                   variant="rectangular"
//                   width={180}
//                   height={60}
//                   className=" rounded-md shadow-xl bg-slate-200 animate-pulse"
//                 />
//               ))}
//             </div>
//           ) : (
//             <div class="grid md:grid-cols-2 grid-cols-1 gap-4 text-slate-800 mr-8">
//               {" "}
//               {series.map((item, index) => (
//                 <div
//                   key={index}
//                   className="card bg-slate-200 border shadow-md rounded-md px-4 py-1 flex gap-16 items-center justify-between"
//                 >
//                   <Icon
//                     icon={
//                       index === 0
//                         ? "material-symbols:quick-reference-all"
//                         : index === 1
//                         ? "ri:progress-6-fill"
//                         : index === 2
//                         ? "codicon:vm-active"
//                         : index === 3
//                         ? "material-symbols:all-match-outline"
//                         : index === 4
//                         ? "fluent-mdl2:t-v-monitor-selected"
//                         : "mdi:email-certified"
//                     }
//                     height={30}
//                     width={30}
//                     color={
//                       index === 0
//                         ? "blue"
//                         : index === 1
//                         ? "indigo"
//                         : index === 2
//                         ? "cyan"
//                         : index === 3
//                         ? "salmon"
//                         : index === 4
//                         ? "purple"
//                         : "green"
//                     }
//                   />
//                   <div className="text-right">
//                     <h2 className="text-lg font-bold">
//                       <CountUp
//                         start={0}
//                         end={item.data}
//                         className=" text-blue-900"
//                       />
//                     </h2>
//                     <h2 className=" font-semibold text-slate-600">
//                       {item.name}
//                     </h2>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           )}
//           <div className=" mr-48 md:-my-5">
//             {loading ? (
//               // Render skeleton for graph while loading
//               <Skeleton
//                 variant="circular"
//                 width={250}
//                 height={250}
//                 className="rounded-md shadow-xl bg-slate-200 animate-pulse"
//               />
//             ) : (
//               // Render actual graph component
//               <ReactApexChart
//                 options={options}
//                 series={series.map((item) => item.data)}
//                 type="donut"
//                 width={380}
//                 className="font-bold text-white"
//               />
//             )}
//           </div>
//         </div>
//       </div>
//       <div className="bg-sky-100 m-5 min-h-52  rounded-md ">
//         <div className=" flex justify-center">
//           <div className=" bg-slate-300 px-6 pt-1 text-center rounded-md mt-5 h-8">
//             <h2 className=" tracking-wider text-blue-500 font-semibold text-xl">
//               {" "}
//               Jobs
//             </h2>
//           </div>
//         </div>
//         <div>
//           {loading ? (
//             <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4 gap-x-5 text-slate-800 p-5">
//               {[...Array(5)].map((_, index) => (
//                 <Skeleton
//                   variant="rectangular"
//                   width={280}
//                   height={60}
//                   className=" rounded-md shadow-xl bg-slate-200 animate-pulse gap-x-5"
//                 />
//               ))}
//             </div>
//           ) : (
//             <div class="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-4 text-slate-800 p-5">
//               {" "}
//               {jobs.map((item, index) => (
//                 <div
//                   key={index}
//                   className="card bg-slate-200 border shadow-md rounded-md px-4 py-1 flex gap-16 items-center justify-between"
//                 >
//                   <Icon
//                     icon={
//                       index === 0
//                         ? "bi:arrow-up-right-square-fill"
//                         : index === 1
//                         ? "zondicons:bookmark"
//                         : index === 2
//                         ? "ri:progress-5-fill"
//                         : index === 3
//                         ? "majesticons:alert-circle"
//                         : "uis:check-circle"
//                     }
//                     height={30}
//                     width={30}
//                     color={
//                       index === 0
//                         ? "blue"
//                         : index === 1
//                         ? "indigo"
//                         : index === 2
//                         ? "violet"
//                         : index === 3
//                         ? "tomato"
//                         : "green"
//                     }
//                   />
//                   <div className="text-right">
//                     <h2 className="text-lg font-bold">
//                       <CountUp
//                         start={0}
//                         end={item.data}
//                         className=" text-3xl font-extrabold text-blue-900"
//                       />
//                     </h2>
//                     <h2 className=" text-base font-bold">{item.name}</h2>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dashboard;

import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
// import CountUp from "react-countup";
import ReactApexChart from "react-apexcharts";
import Skeleton from "@mui/material/Skeleton";
import { Box, Typography, Paper, Button, LinearProgress } from "@mui/material";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import UploadIcon from "@mui/icons-material/CloudUpload";
import FileTextIcon from "@mui/icons-material/Description";
import AwardIcon from "@mui/icons-material/EmojiEvents";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { format } from "date-fns";
import { profileGetDataService } from "../../services/dataService";
// import CountUp from "react-countup";
import api from "../../services/api";
import { parseInt } from "lodash";
import { styled } from "@mui/system";

import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts"; // Ensure you have recharts installed
import CountUp from "react-countup";
const InternshipCard = styled(Box)({
  background: "#0069d9",
  color: "#fff",
  borderRadius: "12px",
  padding: "20px",
  marginBottom: "20px",
});

// TimelineStep component
const TimelineStep = ({ title, date, icon, statusColor, isLast }) => (
  <TimelineItem
    sx={{
      "&::before": {
        content: "none", // Removing the ::before pseudo-element
        // display: 'none',  // Ensures it's not displayed or taking space
      },
      marginLeft: "10px",
    }}
  >
    <TimelineSeparator>
      <TimelineDot
        sx={{ backgroundColor: statusColor, width: "30px", height: "30px" }}
      >
        {icon}
      </TimelineDot>
      {!isLast && <TimelineConnector sx={{ height: "20px" }} />}{" "}
      {/* Render connector only if not the last step */}
    </TimelineSeparator>
    <TimelineContent>
      <Typography variant="h6" fontWeight="bold">
        {title}
      </Typography>
      {date && (
        <Box display="flex" color="gray" mt={0.5}>
          <CalendarTodayIcon fontSize="small" sx={{ marginRight: 1 }} />
          <Typography variant="body2">{date}</Typography>
        </Box>
      )}
    </TimelineContent>
  </TimelineItem>
);

const Dashboard = () => {
  // console.log("pratyukti isAllowed",isAllowed);
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [status, setStatus] = useState([]);
  const [pieChartData, setPieChartData] = useState([
    { name: "Opening", value: 0 },
    { name: "Applied", value: 0 },
  ]);

  const Header = styled(Box)({
    background: "#0069d9",
    borderRadius: "12px",
    padding: "20px",
    color: "#ffffff",
    // marginBottom: "20px",
    margin: "24px 45px 10px 45px",
  });

  const steps = [
    {
      title: "Internship Apply",
      date:
        status?.started_at != null
          ? format(new Date(status?.started_at), "dd MMM yyyy")
          : null,
      icon: <CheckCircleIcon sx={{ margin: "0 auto" }} />,
      statusColor: "#07bb5f",
    },
    {
      title: "Internship Approval",
      date:
        status?.started_at != null
          ? format(new Date(status?.started_at), "dd MMM yyyy")
          : null,
      icon: <CheckCircleIcon />,
      statusColor: "#07bb5f",
    },
    {
      title: "Course Starting",
      date:
        status?.started_at != null
          ? format(new Date(status?.started_at), "dd MMM yyyy")
          : null,
      icon: <CheckCircleIcon />,
      statusColor: "#07bb5f",
    },
    {
      title: "Certificate Upload",
      date: status?.certificate_upload_start
        ? format(new Date(status?.certificate_upload_start), "dd MMM yyyy")
        : "Not Available",
      icon: <UploadIcon />,
      statusColor: status?.certificate_upload === 1 ? "#07bb5f" : "blue",
    },
    {
      title: "Certificate Verify",
      date: (() => {
        switch (status?.certificate_upload) {
          case 1:
            return "Verified";
          case 0:
            return "Pending Verification";
          default:
            return null;
        }
      })(),
      icon: <FileTextIcon />,
      statusColor: (() => {
        switch (status?.certificate_upload) {
          case 1:
            return "#07bb5f"; // Verified -> Green
          case 0:
            return "gray"; // Pending -> Gray
          default:
            return "gray"; // Default -> Gray
        }
      })(),
    },
    {
      title: "Assessment",
      date: (() => {
        switch (status?.assessment_active) {
          case "0":
            return "Start Assessment";
          case "1":
            return "Assessment Completed";
          case "3":
            return "Assessment Restricted";
          default:
            return "Assessment Disabled";
        }
      })(),
      icon: <AwardIcon />,
      statusColor: (() => {
        switch (status?.assessment_active) {
          case "0":
            return "blue"; // Blue for start
          case "1":
            return "#07bb5f"; // Green for completed
          default:
            return "gray"; // Gray if no valid status
        }
      })(),
    },
  ].filter((step) => step.date !== null); // Filters out steps with no date or relevant data
  const completedSteps = steps.filter(
    (step) => step.statusColor === "#07bb5f"
  ).length;
  const progressPercentage = (completedSteps / steps.length) * 100;

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await profileGetDataService.profileData(); // Fetch the data from your service
        const data = response?.data;

        setProfileData(data);
        console.log("pratyukti data", data);
        // console.log('The profile Data are:',data)
        // setProfileUpdateStatus(data?.profile_update_status);
      } catch (error) {
        // console.error("Failed to fetch profile data:", error);
      }
    };

    fetchProfileData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/internship/studentDashboard", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        // Log the entire response to check the structure of data
        console.log("Response Data:", response?.data);

        // Ensure the data structure is what we expect
        if (Array.isArray(response?.data?.jobsResponse)) {
          const openings = response?.data?.jobsResponse.find(
            (item) => item.message === "Openings"
          );
          const applied = response?.data?.jobsResponse.find(
            (item) => item.message === "Applied"
          );

          // Extract colors dynamically
          const openingColor = openings?.color || "#0088FE"; // Default color if undefined
          const appliedColor = applied?.color || "#00C49F"; // Default color if undefined

          const remainingValue = Math.max(
            (openings?.number || 0) - (applied?.number || 0),
            0
          );

          // Log the extracted data to verify
          console.log("Openings:", openings);
          console.log("Applied:", applied);

          // Set pie chart data only if the values exist
          setPieChartData([
            { name: "Opening", value: remainingValue, color: openingColor },
            {
              name: "Applied",
              value: applied?.number || 0,
              color: appliedColor,
            },
          ]);
        } else {
          console.error("Response data is not an array:", response?.data);
        }

        // Additional checks for jobs and internship data if available
        if (response?.data) {
          setJobs(
            response.data.jobsResponse.map((job) => ({
              name: job.message,
              data: job.number,
              icon: job.icon,
              color: job.color,
            }))
          );

          setSeries(
            response.data.internshipResponse.map((internship) => ({
              name: internship.message,
              data: parseInt(internship?.days), // Convert days to number
              icon: internship.icon,
              color: internship.color,
            }))
          );
        }
      } catch (error) {
        console.error("Fetch error:", error);
      } finally {
        setLoading(false);
      }
    };
    const fetchStatus = async () => {
      try {
        const response = await api.post("/internship/ongoingStudentStatus", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });
        console.log("responce", response.data);
        setStatus(response.data);
      } catch (error) {
        // console.error("Failed to fetch profile data:", error);
      }
    };

    fetchStatus();
    fetchData();
  }, []);

  return (
    <div>
      <Header>
        <Typography variant="h5" fontWeight="bold">
          {" "}
          Hello ,{profileData.first_name}
        </Typography>
        <Typography variant="body2" color="#e6e9f0">
          Track your job applications and internships from your dashboard.
        </Typography>
      </Header>
      <div className=" m-3 h-auto rounded-md ">
        {/* <div className="h-7 flex justify-center">
          <div className="bg-slate-100 px-6 pt-1 text-center rounded-md mt-5 h-8 shadow-md">
            <h2 className="tracking-wider text-blue-500 font-semibold text-base">Internships</h2>
          </div>
        </div> */}
        <div className="content mt-6 pl-10 justify-between mb-5">
          {loading ? (
            // <Skeleton variant="rectangular" width={250} height={250} className="rounded-md shadow-xl bg-slate-200 animate-pulse" />
            ""
          ) : (
            // <div className="grid md:grid-cols-4 grid-cols-2 gap-4 text-slate-800 mr-8">
            //   {series.map((item, index) => (
            //     <div key={index} className="card bg-slate-200 border shadow-md rounded-md px-4 py-1 flex gap-10 items-center justify-between flexdirection-column">
            //       <Icon icon={item.icon} height={120} width={100} color={item.color} style={{marginTop:'-70px'}} />
            //       <div className="text-right">
            //         <h2 className="font-semibold text-slate-600" color={item.color}>{item.name}</h2>
            //         <h2 className="text-lg font-bold text-blue-900" ><CountUp start={0} end={item.data} className="text-blue-900" /> days</h2>

            //       </div>
            //     </div>
            //   ))}
            // </div>
            <div className="grid sm:grid-cols-4 grid-cols-2 gap-4 text-slate-800 mr-8">
              {series.map((item, index) => (
                <div
                  key={index}
                  className=" border shadow-md rounded-md px-4 py-1 flex gap-10 items-center justify-between flexdirection-column"
                  style={{ background: "rgb(228 240 255)" }}
                >
                  <Icon
                    icon={item.icon}
                    height={30}
                    width={30}
                    color={item.color}
                  />
                  <div className="text-right">
                    <h2
                      className="font-bold text-slate-600"
                      color={item.color}
                      style={{ fontSize: "9px", fontWeight: "bold" }}
                    >
                      {item.name}
                    </h2>
                    <h2 className="text-sm font-bold text-blue-900">
                      <CountUp
                        start={0}
                        end={item.data}
                        className="text-blue-900"
                      />{" "}
                      days
                    </h2>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Jobs section grid */}
      <div className=" min-h-52 rounded-md" style={{ margin: "0 3.25rem" }}>
        {/* Flex container for timeline and jobs */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          {/* Timeline Section */}
          <Box
            sx={{
              flex: "1",
              minWidth: "350px",
              // maxWidth: '400px',
              width: "40%",
              overflowY: "auto",
              backgroundColor: "rgb(228 240 255)",
              borderRadius: "12px",
              boxShadow: 2,
              padding: 2,
            }}
          >
            {status?.cohort_id && status?.domain && (
              <InternshipCard>
                <Typography variant="h5" fontWeight="bold">
                  {status?.domain}
                </Typography>
                <Typography variant="body1">
                  {" "}
                  Cohort : {status?.cohort_id}
                </Typography>

                {/* Add more details here as needed */}
                <Box sx={{ width: "100%", minWidth: "150px" }}>
                  <Typography variant="body2" fontWeight="bold" fontSize="12px">
                     {progressPercentage.toFixed(0)}% Completed
                  </Typography>
                  <LinearProgress
                    variant="determinate"
                    value={progressPercentage}
                    sx={{
                      height: 8,
                      marginTop: "12px",
                      borderRadius: 5,
                      backgroundColor: "#e0e0e0",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#07bb5f",
                      },
                    }}
                  />
                  {/* <Typography variant="body2" sx={{ mt: 1 }}>
       
      </Typography> */}
                </Box>
              </InternshipCard>
            )}

            <Paper
              elevation={2}
              sx={{
                borderRadius: "12px",
                padding: "0 16px 16px 16px",
                // height: '230px',
                // maxHeight: '230px',
                // overflowY: 'auto'
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  // justifyContent: "space-between",
                  // flexDirection:'column',
                  width: "100%",
                  gap: 1,
                }}
              >
                {/* Left: Timeline (Unmodified) */}
                <Timeline
                  sx={{
                    width: "70%",
                    padding: 0,
                    textAlign: "left",
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {steps.length > 0 ? (
                    steps.map((step, index) => (
                      <TimelineStep
                        key={index}
                        title={step.title}
                        date={step.date}
                        icon={step.icon}
                        statusColor={step.statusColor}
                        isLast={index === steps.length - 1}
                      />
                    ))
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        maxWidth: 500,
                        margin: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                          borderRadius: "15px",
                          backdropFilter: "blur(10px)",
                          padding: 3,
                          textAlign: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: 2,
                          }}
                        >
                          <AccessTimeIcon
                            sx={{ fontSize: "50px", color: "blue" }}
                          />
                        </Box>
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: "bold",
                            color: "#333",
                            marginBottom: 2,
                          }}
                        >
                          No Ongoing Internship
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Timeline>

                {/* Right: Progress Bar */}
              </Box>
            </Paper>
          </Box>

          {/* Jobs Section with Pie Chart */}
          <Box
            sx={{
              flex: "1",
              minWidth: "350px",
              // maxWidth: '400px',
              width: "40%",
              backgroundColor: "rgb(228 240 255)",
              borderRadius: "12px",
              boxShadow: 2,
              padding: 2,
              margin: "0 auto",
            }}
          >
            <Typography variant="h6" fontWeight="bold">
              Job Application Status
            </Typography>

            {loading ? (
              <div>Loading...</div>
            ) : (
              <PieChart width={260} height={150} style={{ margin: "0 auto" }}>
                <Pie
                  data={pieChartData}
                  dataKey="value"
                  nameKey="name"
                  cx="40%"
                  cy="50%"
                  outerRadius={50}
                  innerRadius={25} // Ring effect
                  paddingAngle={5}
                >
                  {pieChartData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>

                {/* Glassmorphism Tooltip */}
                <Tooltip
                  contentStyle={{
                    background: "rgba(255, 255, 255, 0.2)",
                    backdropFilter: "blur(10px)",
                    borderRadius: "10px",
                    padding: "10px",
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                    // border: "1px solid rgba(255, 255, 255, 0.2)"
                  }}
                  // wrapperStyle={{ transform: "translateX(50px)" }} // Moves it further right
                  itemStyle={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#fff",
                  }}
                  // cursor={{ fill: "rgba(0,0,0,0.1)" }}
                />
                {/* <Tooltip
  position={{ x: 300, y: 50 }} // Adjust this based on chart size
  contentStyle={{
    background: "rgba(255, 255, 255, 0.2)",
    backdropFilter: "blur(10px)",
    borderRadius: "10px",
    padding: "10px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    border: "1px solid rgba(255, 255, 255, 0.2)"
  }}
  wrapperStyle={{ transform: "translateX(50px)" }} // Moves it further right
  itemStyle={{ fontSize: "14px", fontWeight: "bold", color: "#fff" }}
  cursor={{ fill: "rgba(0,0,0,0.1)" }}
/> */}

                {/* Small Legend Points */}
                <Legend
                  layout="vertical"
                  align="right"
                  verticalAlign="middle"
                  iconType="circle"
                  iconSize={5} // Makes the legend points smaller
                />
              </PieChart>
            )}

            {/* Job Listings */}
            <div
              className="flex flex-wrap gap-3 mt-3"
              style={{ background: "rgb(228 240 255)"}}
            >
              {jobs.map((item, index) => (
                <div
                  key={index}
                  className="card border shadow-md rounded-md px-4 py-2 flex gap-4 items-center justify-between bg-white 
      w-full sm:w-[calc(50%-8px)] md:w-[calc(49.33%-6px)] lg:w-[calc(33.33%-8px)]"
                >
                  {/* Ensure the icon does not shrink */}
                  <div className="flex-shrink-0">
                    <Icon
                      icon={item.icon}
                      height={26}
                      width={26}
                      color={item.color}
                    />
                  </div>

                  {/* Centered text content */}
                  <div className="text-center">
                    <h2 className="text-sm font-bold">
                      <CountUp
                        start={0}
                        end={item.data}
                        className="text-2lg font-extrabold text-blue-900"
                      />
                    </h2>
                    <h2
                      className="font-bold text-[11px]"
                      style={{ color: item.color }}
                    >
                      {item.name}
                    </h2>
                  </div>
                </div>
              ))}
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
