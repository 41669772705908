import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { getOngoingInternships } from "../services/dataService";
import { BASE_URL } from "../services/configUrls";

const CACHE_KEY = "internshipData";
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const DomainGuard = ({ children, type }) => {
  const [isAllowed, setIsAllowed] = useState(null);
  const[documentStatus,setDocumentStatus] =useState('');
  const [fetchedRows, setFetchedRows] = useState([]);
// Fetch document status whenever fetchedRows changes

  useEffect(() => {
    const fetchDocumentStatus = async () => {
      // Ensure both cohortId and domainId are provided
     
      try {
        
    
        const response = await fetch(
          `${BASE_URL}/internship/checkStatus`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json", // Set Content-Type to JSON
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            
          }
        );
    
        if (!response.ok) {
          throw new Error("Failed to fetch document status");
        }
    
        const data = await response.json();
        console.log('pratyukti',data);
        setDocumentStatus(data); // Handle the response data
      } catch (error) {
        console.error("Error fetching document status:", error);
      }
    };
    fetchDocumentStatus();
    const checkDomainAccess = async () => {
      try {

        const response = await fetch(
          `${BASE_URL}/internship/checkStatus`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json", // Set Content-Type to JSON
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
            
          }
        );
    
        if (!response.ok) {
          throw new Error("Failed to fetch document status");
        }
    
        const checkStatusData = await response.json();
        console.log('pratyukti',checkStatusData);
        setDocumentStatus(checkStatusData); // Handle the response data
        // Check localStorage for cached data
        const cachedData = localStorage.getItem(CACHE_KEY);
        
        let internshipData;

        if (cachedData) {
          const { data, timestamp } = JSON.parse(cachedData);
          const isExpired = Date.now() - timestamp > CACHE_DURATION;

          if (!isExpired) {
            internshipData = data;
          }
        }

        // If no cached data or expired, fetch from API
        if (!internshipData) {
          const response = await getOngoingInternships.ongoingInternshipsData();
          internshipData = response.data;

          // Cache the new data
          localStorage.setItem(
            CACHE_KEY,
            JSON.stringify({
              data: internshipData,
              timestamp: Date.now(),
            })
          );
        }

        // First check domain restrictions
      const restrictedDomains =
          type === "assessment"
            ? [13, 25, 9, 6, 20, 26, 27, 28, 36, 37]
            : checkStatusData.certificate_upload.restrictedDomain;
        console.log("pratyukti2",restrictedDomains);
        const hasRestrictedDomain = internshipData.internships?.some(
          (internship) => restrictedDomains.includes(internship.domain_id)
        );

        // For cert-upload, check specifically for cohort_id 11
        if (type === "cert-upload") {
          const hasOnlyCohort11 = internshipData.internships?.every(
            (internship) => internship.cohort_id === 12
          );

          // Allow access if there's at least one internship not in cohort 11
          setIsAllowed(!hasRestrictedDomain && !hasOnlyCohort11);
          return;
        }

        setIsAllowed(!hasRestrictedDomain);
      } catch (error) {
        console.error("Domain check failed:", error);
        setIsAllowed(false);
      }
    };
    

    checkDomainAccess();
   

  }, [type]);



  if (isAllowed === null) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return isAllowed ? children : <Navigate to="/internship" replace />;
};

export default DomainGuard;
