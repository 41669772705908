import React, { useEffect, useState } from "react";
import {
  Container,
  TextField,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
  Tooltip,
  Link,
  Box,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material";
import { motion } from "framer-motion";

import UploadIcon from "@mui/icons-material/Upload";

import { blue, green } from "@mui/material/colors";
import { getOngoingInternships } from "../../services/dataService";
import { Navigate, Link as RouterLink, useNavigate } from "react-router-dom";

import { FaCircleCheck, FaCirclePause } from "react-icons/fa6";
import { Document, Page } from "react-pdf";
import ScheduleIcon from "@mui/icons-material/Schedule";

import QuizIcon from "@mui/icons-material/Quiz";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LockIcon from "@mui/icons-material/Lock";
import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import pdf from "../../assets/Google AI-ML course process document.pdf";
// import { TbTimeDuration30 } from 'react-icons/tb';
import { pdfjs } from "react-pdf";
import { BASE_URL } from "../../services/configUrls";
import { toast } from "react-toastify";
import { Calendar, Clock, Info } from "lucide-react";
import CountdownTimer from "./TimerComponent";
import DocumentModal from "./DocumentModal";
import AnnouncementsSection from "./AnnouncementsSection";
import { DocumentAccessCell } from "./AccessLinkCell";
import CourseAccessLinkCell from "./CourseAccessLinkCell";
import InProgressComponent from "./InprogressComponent";
import { MdPending } from "react-icons/md";

import { DotLottieReact } from "@lottiefiles/dotlottie-react";

const OngoingTableOld = () => {
  const navigate = useNavigate();
  const [filteredRows, setFilteredRows] = useState([]);
  const [fetchedRows, setFetchedRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState([]);
  const [domainHeader, setDomainHeader] = useState("");
  const [active, setActive] = useState([]);
  const [isData, setIsData] = useState([]);
  const [showCertUpload, setShowCertUpload] = useState(false);

  const [pdfUrl, setPdfUrl] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [numPages, setNumPages] = useState(null); // Track number of pages
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0); // Track the zoom level (scale)
  const [openRecordingDialog, setOpenRecordingDialog] = useState(false);
  const [selectedRecordings, setSelectedRecordings] = useState([]);
  const [isAfterTargetDate, setIsAfterTargetDate] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = useState(false);
  const [cohort, setCohort] = useState([]);
  const [loadingAccessLink, setLoadingAccessLink] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [domainDetails, setDomainDetails] = useState(null);
  const [cohortDetails, setCohortDetails] = useState(null);
  const [documentStatus, setDocumentStatus] = useState([]);

  const targetDate = new Date("2024-10-20");
  const dummyTimelineData = [
    { key: 1, value: "4 days left" },

    // { key: 3, value: "8 days" },
  ];

  const courseStatus = "verified"; // Set the course status to "not started", "ongoing", "complete", or "verified"

  let message = "";

  // Handle conditions based on course status
  if (courseStatus === "not started") {
    message = "You can upload the certificate after";
  } else if (courseStatus === "ongoing") {
    message = "for uploading the certificate";
  } else if (courseStatus === "complete") {
    message = "Certificate uploading date is already over";
  } else if (courseStatus === "verified") {
    message = "Your certificate is verified";
  } else {
    message = "Course status is unknown";
  }

  useEffect(() => {
    const currentDate = new Date();
    // Check if the current date is after the target date
    setIsAfterTargetDate(currentDate > targetDate);
  }, []);

  const handleInfoDialogClose = () => {
    setOpenInfoDialog(false);
  };

  const handlePdfClose = () => {
    setIsDialogOpen(false);
    setPdfUrl(null);
    setPageNumber(1);
    setScale(1.0); // Reset zoom level
  };

  const handleRecordingDialogOpen = (recordingString) => {
    // Extract all <a> tags using a regex and create an array of objects with `href` and `text` for each link
    const parser = new DOMParser();
    const doc = parser.parseFromString(recordingString, "text/html");
    const anchors = Array.from(doc.querySelectorAll("a"));

    const recordingsArray = anchors.map((anchor) => ({
      href: anchor.href, // Extract href attribute
      text: anchor.textContent, // Extract the text inside the <a> tag
    }));

    setSelectedRecordings(recordingsArray);
    setOpenRecordingDialog(true);
  };

  // Function to close the dialog
  const handleRecordingDialogClose = () => {
    setOpenRecordingDialog(false);
    setSelectedRecordings([]);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages); // Set the number of pages
  };

  // Handle next and previous page navigation
  // const goToNextPage = () => {
  //   if (pageNumber < numPages) {
  //     setPageNumber(pageNumber + 1);
  //   }
  // };

  // const goToPreviousPage = () => {
  //   if (pageNumber > 1) {
  //     setPageNumber(pageNumber - 1);
  //   }
  // };

  const onLinkClick = ({ link }) => {
    // Open links in a new tab if they are valid URLs
    if (link && link.startsWith("http")) {
      window.open(link, "_blank");
    }
  };

  const zoomIn = () => setScale(scale + 0.1); // Increase the scale
  const zoomOut = () => setScale(scale > 0.5 ? scale - 0.1 : scale); // Decrease the scale, but not below 0.5

  const fetchDomainDetails = async (cohortId, domainId) => {
    try {
      const formData = new FormData();
      formData.append("cohortId", cohortId);
      formData.append("domainId", domainId);

      const response = await fetch(
        `${BASE_URL}/internship/cohortDomainDetails`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch domain details");
      }

      const data = await response.json();
      setDomainDetails(data.domainDetails);
      setCohortDetails(data.cohortDetails);
    } catch (error) {
      console.error("Error fetching domain details:", error);
    }
  };

  useEffect(() => {
    const fetchDocumentStatus = async (cohortId, domainId) => {
      // Ensure both cohortId and domainId are provided
      if (!cohortId || !domainId) {
        console.log("Missing cohortId or domainId, skipping fetch");
        return; // Skip the fetch if either ID is missing
      }

      try {
        const body = {
          cohortId: cohortId,
          domainId: domainId,
        };

        const response = await fetch(`${BASE_URL}/internship/checkStatus`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Set Content-Type to JSON
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
          body: JSON.stringify(body), // Send the body as JSON
        });

        if (!response.ok) {
          throw new Error("Failed to fetch document status");
        }

        const data = await response.json();
        setDocumentStatus(data); // Handle the response data
      } catch (error) {
        console.error("Error fetching document status:", error);
      }
    };

    // Get cohortId and domainId from the data after fetching the initial data
    if (fetchedRows.length > 0) {
      const cohortId = fetchedRows[0]?.cohort_id;
      const domainId = fetchedRows[0]?.domain_id;
      fetchDocumentStatus(cohortId, domainId); // Pass them to the fetch function
    }
  }, [fetchedRows]); // Fetch document status whenever fetchedRows changes

  useEffect(() => {
    const fetchOngoingInternshipData = async () => {
      try {
        setLoading(true);
        const response = await getOngoingInternships.ongoingInternshipsData();

        if (response.data && response.data.internships) {
          const data = response.data.internships;
          setIsData(data);
          setSelectedCertificate(data[0].certificate_status || []);
          setActive(data[0].assessment_active);

          const rowsWithId = data.map((row, index) => ({
            ...row,
            id: row.sl_no || index,
          }));

          setFetchedRows(rowsWithId);
          setFilteredRows(rowsWithId);
          setDomainHeader(data[0].domain);
          setCohort(data[0].cohort);

          // After getting the initial data, fetch domain details
          if (data[0]) {
            await fetchDomainDetails(data[0].cohort_id, data[0].domain_id);
          }
          // if (data[0]) {
          //   await fetchDocumentStatus(data[0].cohort_id, data[0].domain_id);
          // }
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOngoingInternshipData();
  }, []);

  useEffect(() => {
    const checkCertUploadAccess = () => {
      if (fetchedRows.length === 0 || !domainDetails) return;

      const currentDate = new Date();
      const certificateUploadStart = cohortDetails.certificate_upload_start
        ? new Date(cohortDetails.certificate_upload_start)
        : null;

      // Show upload option if after certificate upload start date
      // If no start date is specified, fallback to previous logic
      if (certificateUploadStart) {
        setShowCertUpload(currentDate >= certificateUploadStart);
      } else {
        const febFirst2025 = new Date("2025-02-01");
        const isNotCohort11 = fetchedRows[0]?.cohort_id !== 11;
        const isAfterFebFirst = currentDate >= febFirst2025;
        setShowCertUpload(isNotCohort11 || isAfterFebFirst);
      }
    };

    checkCertUploadAccess();
  }, [fetchedRows, domainDetails]);

  const statusIcons = {
    "Not Uploaded": (
      <MdPending style={{ color: blue[500], fontSize: "26px" }} />
    ),
    Uploaded: <FaCirclePause style={{ color: "orange", fontSize: "24px" }} />,
    Verified: <FaCircleCheck style={{ color: "green", fontSize: "24px" }} />,
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const getAccessToken = async () => {
    try {
      const response = await fetch(`${BASE_URL}/wadhwani/wadhwaniCourse`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (!response.ok) {
        // Parse JSON response for error cases
        let errorData = null;
        try {
          errorData = await response.json();
        } catch (e) {
          console.error("Error parsing JSON from response:", e);
        }

        if (response.status === 401) {
          toast.error("Unauthorized access. Please log in again.");
        } else if (response.status === 403) {
          toast.error("Access forbidden. Contact support.");
        } else if (response.status === 404) {
          toast.error("Resource not found.");
        } else if (response.status === 400) {
          toast.warning(errorData?.detail || "Bad Request.");
        } else if (response.status >= 500) {
          toast.error(
            errorData?.detail || "Server error. Please try again later."
          );
        } else {
          toast.error(errorData?.detail || "An unexpected error occurred.");
        }
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      toast.success(data.detail); // Show success toast if response is OK
      return data;
    } catch (error) {
      console.error("Error:", error);
      // toast.error("An error occurred. Please try again.");
      return null;
    }
  };

  const handleButtonClick = async () => {
    setLoadingAccessLink(true); // Show loader when button is clicked
    const url = await getAccessToken();
    setLoadingAccessLink(false); // Hide loader after API call
    if (url) {
      window.location.href = url;
    } else {
      console.error("Failed to retrieve access token.");
    }
  };

  if (loading) {
    return (
      <Box sx={{ width: "100%", marginTop: 3 }}>
        <Skeleton height={40} />
        <Skeleton height={40} />
        <Skeleton height={40} />
      </Box>
    );
  }

  const getButtonProps = (assessment_active) => {
    localStorage.removeItem("IS_SUBMITTED");
    switch (assessment_active) {
      case null:
        return {
          color: "success", // Gray
          text: " Assessment Not Applicable",
          disabled: true,
          icon: <CancelIcon />, // Cross icon
          sx: {
            fontSize: "12px",
            margin:'0 auto'
          },
        };
      case "0":
        return {
          color: "success", // Default color
          text: "Start Assessment",
          disabled: false,
          icon: <QuizIcon />,
          sx: { margin:'0 auto',width:'100%'}, // Default styles
        };
      case "1":
        return {
          color: "success", // Green
          text: "Assessment Completed",
          disabled: false,
          icon: <CheckCircleIcon />,
          sx: { margin:'0 auto',width:'100%'}, // Default styles
        };
      case "4":
        return {
          color: "primary", // Green
          text: "Assessment",
          disabled: true,
          icon: <QuizIcon />,
          sx: { margin:'0 auto',width:'100%'}, // Default styles
        };
      case "3":
        return {
          color: "error", // Red
          text: "Assessment Restricted",
          disabled: true,
          icon: <LockIcon />,
          sx: {
             margin:'0 auto',
             width:'100%',
            backgroundColor: "red",
            color: "white",
            "&.Mui-disabled": {
              backgroundColor: "red",
              color: "white",
            },
            "&:hover": {
              backgroundColor: "darkred",
            },
          },
        };
      default:
        return {
          color: "primary", // Default color
          text: "Assessment",
          disabled: true,
          icon: <HelpIcon />,
          sx: { margin:'0 auto',width:'100%'}, // Default styles
        };
    }
  };

  // if (isData.length === 0 ) {
  //   return (
  //     <div className="flex items-center justify-center bg-gradient-to-br from-blue-50 to-indigo-50 py-8 px-4">
  //       <div className="max-w-lg w-full bg-white shadow-xl rounded-2xl p-8 text-center border border-indigo-100">
  //         <div className="flex flex-col items-center space-y-6">
  //           <div className="bg-indigo-100 p-4 rounded-full">
  //             <Calendar className="w-12 h-12 text-indigo-600" />
  //           </div>

  //           <div className="space-y-3">
  //             <h1 className="text-3xl font-bold text-gray-800">
  //               Cohort 12 is Coming!
  //             </h1>

  //             <div className="flex items-center justify-center space-x-2 text-indigo-600">
  //               <Clock className="w-5 h-5" />
  //               {/* <p className="text-lg font-medium">Opening December 15th</p> */}
  //             </div>
  //           </div>

  //           <div className="bg-indigo-50 rounded-lg p-6 w-full">
  //             <p className="text-gray-700 leading-relaxed">
  //               Get ready to embark on an exciting journey! Join our upcoming
  //               cohort and be part of an innovative learning experience.
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <Container
      sx={{
        marginTop: "10px",
        // p: {
        //   xs: 2, // 16px padding for xs screens (320px+)
        //   sm: 3, // 24px padding for sm screens
        //   md: 4, // 32px padding for md screens
        //   lg: 2, // 48px padding for lg screens
        // },
        width: "100%",
        maxWidth: "100% !important", // Override default Container maxWidth
        boxSizing: "border-box",
        overflowX: "auto",
      }}
    >
      {/* <InProgressComponent /> */}
      <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
        <Grid item xs={12} sm="auto"></Grid>
      </Grid>
      {loading ? (
        <Box sx={{ width: "100%", marginTop: 3 }}>
          <Skeleton height={40} />
          <Skeleton height={40} />
          <Skeleton height={40} />
        </Box>
      ) : (
        <Box sx={{ width: "100%", marginTop: 3 }}>
          <TableContainer
            sx={{
              width: {
                xs: "calc(100vw - 32px)", // Account for container padding
                sm: "100%",
              },
              minWidth: "280px", // Minimum width for smallest screens
              boxShadow: 3,
              //
              borderRadius: 3,
              overflowX: "auto",
              "& .MuiTable-root": {
                // Minimum width for table content
                minWidth: {
                  xs: "280px",
                  sm: "100%",
                },
              },
              "& .MuiTableCell-root": {
                // Adjust cell padding for small screens
                padding: {
                  xs: 1,
                  sm: 2,
                },
              },
            }}
            component={Paper}
          >
            <Table>
              <TableHead sx={{ borderRadius: 3 }}>
                <TableRow sx={{ backgroundColor: "#1976d2", borderRadius: 3 }}>
                  <TableCell
                    colSpan={6}
                    sx={{
                      color: "white",
                      textTransform: "uppercase",
                      borderRadius: 3,
                    }}
                  >
                    <Typography sx={{ padding: 0 }}>
                      INTERNSHIP DOMAIN : {domainHeader} ({cohort})
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{ boxShadow: "none", border: "none" }}>
                {filteredRows.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      border: "none",
                      boxShadow: "none",
                      border: "none",
                      backgroundColor: "#eee",
                    }}
                  >
                    <>
                      {![1, 3, 8, 7, 33, 38, 24].includes(row.domain_id) && (
                        <TableCell
                          sx={{
                            fontSize: {
                              xs: "8px",
                              sm: "9px",
                              md: "10px",
                            },
                            padding: {
                              xs: "4px",
                              sm: "8px",
                              md: "12px",
                            },
                            border: "none",
                          }}
                        >
                          <Box
                            sx={{
                              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                              // backdropFilter: "blur(10px)",
                              background: "#fff",
                              borderRadius: "10px",
                              padding: "6px",
                              width: "180px",
                              height: "135px",
                              boxShadow: 3,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "9px",
                                  sm: "10px",
                                  md: "11px",
                                },
                                padding: {
                                  xs: "4px",
                                  sm: "8px",
                                  md: "9px",
                                },
                                fontWeight: "bold",
                                display: "flex",
                                marginBottom: "10px",
                                justifyContent: "center",
                              }}
                            >
                              {documentStatus?.document_status?.message
                                ? documentStatus.document_status.message
                                : ""}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                width: "180px",
                                fontSize: { xs: "8px", sm: "9px", md: "11px" },
                              }}
                            >
                              {documentStatus?.document_status?.days ? (
                                <React.Fragment>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      textAlign: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        marginBottom: "12px",
                                        fontWeight: "bold",
                                        color: (() => {
                                          const numericValue = parseInt(
                                            documentStatus.document_status
                                              .days ?? 0
                                          );
                                          return numericValue <= 5
                                            ? "#f44336" // Red for values <= 5
                                            : numericValue <= 15
                                            ? "#2196f3" // Blue for values <= 15
                                            : "#4caf50"; // Green for values > 15
                                        })(),
                                        padding: "6px 12px",
                                        width: "100%",
                                        backgroundColor: (() => {
                                          const numericValue = parseInt(
                                            documentStatus.document_status
                                              .days ?? 0
                                          );
                                          return numericValue <= 5
                                            ? "rgba(255, 100, 100, 0.2)" // Light red for values <= 5
                                            : numericValue <= 15
                                            ? "rgba(100, 100, 255, 0.2)" // Light blue for values <= 15
                                            : "rgba(100, 255, 100, 0.2)"; // Light green for values > 15
                                        })(),
                                        borderRadius: "9px",
                                        backdropFilter: "blur(10px)", // Glass effect
                                      }}
                                    >
                                      <ScheduleIcon
                                        sx={{
                                          marginRight: "5px",
                                          fontSize: "21px",
                                          fontWeight: "bold",
                                          height: "20px",
                                          width: "20px",
                                        }}
                                      />
                                      {documentStatus.document_status.days}
                                    </span>
                                  </Box>
                                </React.Fragment>
                              ) : (
                                <p></p>
                              )}
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 1,

                                background: "#fff",
                                //  height:'135px',
                              }}
                            >
                              <CourseAccessLinkCell
                                courseAccessLink={row.course_access_link}
                                documentsRestrictionStart={
                                  domainDetails?.documents_restriction_start
                                }
                                documentsRestrictionEnd={
                                  domainDetails?.documents_restriction_end
                                }
                                domainId={row.domain_id}
                                // Add this prop
                                cohortId={row.cohort_id}
                              />
                            </Box>
                          </Box>
                        </TableCell>
                      )}
                      <TableCell
                        sx={{
                          fontSize: {
                            xs: "8px", // Smaller font size for extra small screens
                            sm: "9px", // Slightly larger font for small screens
                            md: "10px", // Default font size for medium screens and above
                          },
                          padding: {
                            xs: "4px", // Less padding for extra small screens
                            sm: "8px", // Default padding for small screens
                            md: "12px", // Default padding for medium and larger screens
                          },
                          border: "none",
                        }}
                      >
                        {/* <Box sx={{display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center',
                           gap: 1,
                           width:"180px"}}> */}
                        <DocumentAccessCell
                          documentData={{
                            doc_link: row.process_document,
                            documents_restriction_start:
                              domainDetails?.documents_restriction_start,
                            documents_restriction_end:
                              domainDetails?.documents_restriction_end,
                          }}
                          onDocumentClick={() => setIsDocumentModalOpen(true)}
                          documentStatus={documentStatus}
                        />
                        {/* </Box> */}
                      </TableCell>

                      <DocumentModal
                        domainId={row.domain_id}
                        isOpen={isDocumentModalOpen}
                        onClose={() => setIsDocumentModalOpen(false)}
                      />

                      {/* <TableCell
                        sx={{
                          fontSize: {
                            xs: "8px", // Smaller font size for extra small screens
                            sm: "9px", // Slightly larger font for small screens
                            md: "11px", // Default font size for medium screens and above
                          },
                          padding: {
                            xs: "4px", // Less padding for extra small screens
                            sm: "8px", // Default padding for small screens
                            md: "12px", // Default padding for medium and larger screens
                          },
                        }} 
                      > 
                      {/* <Box sx={{boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                      fontSize: {
                        xs: "8px", // Smaller font size for extra small screens
                        sm: "9px", // Slightly larger font for small screens
                        md: "11px", // Default font size for medium screens and above
                      },
                            // backdropFilter: "blur(10px)",
                             background: "#fff",
                            borderRadius: "10px",
                            padding:'6px',
                            height:'135px',
                            width:"180px",
                            display:'flex',
                            justifyContent:'center',
                            flexDirection:'column'
                            }}>
                    
                        {/* Show the recording links icon and text if recordings are available 
                        {row.record_link && row.record_link.length > 0 && (
                          <Tooltip title="View Recording Links">
                            <Link
                              onClick={() =>
                                handleRecordingDialogOpen(row.record_link)
                              }
                              sx={{
                                textDecoration: "none",
                                display: "flex",
                                alignItems: "center",
                                // justifyContent:"center",
                                flexDirection:'column',
                                cursor: "pointer",
                                color: blue[500], // You can style the color as needed
                                gap:'20px'
                              }}
                            >
                              <VideoLibraryIcon sx={{ marginRight: 1 }} />
                              <Typography sx={{fontSize: {
                            xs: "8px", // Smaller font size for extra small screens
                            sm: "9px", // Slightly larger font for small screens
                            md: "11px", // Default font size for medium screens and above
                          },fontWeight:'bold'}}>Recorded Session Links</Typography>
                            </Link>
                          </Tooltip>
                        )}
                        </Box> */}
                      {/* </TableCell> */}

                      {row.domain_id === 33 && (
                        <TableCell sx={{ border: "none" }}>
                          <Box
                            sx={{
                              fontSize: {
                                xs: "8px", // Smaller font size for extra small screens
                                sm: "9px", // Slightly larger font for small screens
                                md: "11px", // Default font size for medium screens and above
                              },
                              display: "flex",
                              flexDirection: "column",

                              boxShadow: 3,
                              // backdropFilter: "blur(10px)",
                              background: "#fff",
                              borderRadius: "10px",
                              padding: "6px",
                              width: "180px",
                              height: "135px",
                            }}
                          >
                            <CountdownTimer documentStatus={documentStatus} />
                            <Tooltip title="AccessLink">
                              <Button
                                variant="contained"
                                color="info"
                                size="small"
                                sx={{ fontSize: "8px" }}
                                startIcon={
                                  loadingAccessLink ? (
                                    <CircularProgress
                                      size={20}
                                      color="inherit"
                                    />
                                  ) : (
                                    <QuizIcon />
                                  )
                                }
                                onClick={handleButtonClick}
                                disabled={loadingAccessLink}
                              >
                                {loadingAccessLink
                                  ? "Loading..."
                                  : "Course Access Link"}
                              </Button>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      )}

                      {
                        <TableCell
                          sx={{
                            fontSize: {
                              xs: "8px", // Smaller font size for extra small screens
                              sm: "9px", // Slightly larger font for small screens
                              md: "11px", // Default font size for medium screens and above
                            },
                            padding: {
                              xs: "4px", // Less padding for extra small screens
                              sm: "8px", // Default padding for small screens
                              md: "12px", // Default padding for medium and larger screens
                            },
                            border: "none",
                          }}
                        >
                          <Box
                            sx={{
                              boxShadow: 3,
                              // backdropFilter: "blur(10px)",
                              background: "#fff",
                              borderRadius: "10px",
                              padding: "6px",
                              width: "180px",
                              height: "135px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: {
                                  xs: "9px",
                                  sm: "10px",
                                  md: "11px",
                                },
                                padding: {
                                  xs: "4px",
                                  sm: "8px",
                                  md: "4px",
                                },
                                fontWeight: "bold",
                                display: "flex",
                                marginBottom: "10px",
                                justifyContent: "center",
                              }}
                            >
                              {documentStatus?.certificate_upload?.message
                                ? documentStatus.certificate_upload.message
                                : ""}
                            </Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                                // width: "180px",
                                fontSize: { xs: "8px", sm: "9px", md: "11px" },
                              }}
                            >
                              {documentStatus?.certificate_upload?.days ? (
                                <React.Fragment>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      textAlign: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        marginBottom: "12px",
                                        fontWeight: "bold",
                                        color: (() => {
                                          const numericValue = parseInt(
                                            documentStatus.certificate_upload
                                              .days ?? 0
                                          );
                                          return numericValue <= 5
                                            ? "#f44336" // Red for values <= 5
                                            : numericValue <= 15
                                            ? "#2196f3" // Blue for values <= 15
                                            : "#4caf50"; // Green for values > 15
                                        })(),
                                        padding: "6px 12px",
                                        width: "100%",
                                        backgroundColor: (() => {
                                          const numericValue = parseInt(
                                            documentStatus.certificate_upload
                                              .days ?? 0
                                          );
                                          return numericValue <= 5
                                            ? "rgba(255, 100, 100, 0.2)" // Light red for values <= 5
                                            : numericValue <= 15
                                            ? "rgba(100, 100, 255, 0.2)" // Light blue for values <= 15
                                            : "rgba(100, 255, 100, 0.2)"; // Light green for values > 15
                                        })(),
                                        borderRadius: "9px",
                                        backdropFilter: "blur(10px)", // Glass effect
                                      }}
                                    >
                                      <ScheduleIcon
                                        sx={{
                                          marginRight: "5px",
                                          fontSize: "21px",
                                          fontWeight: "bold",
                                          height: "20px",
                                          width: "20px",
                                        }}
                                      />
                                      {documentStatus.certificate_upload.days}
                                    </span>
                                  </Box>
                                </React.Fragment>
                              ) : (
                                <p></p>
                              )}
                            </Box>

                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: {
                                  xs: "8px", // Smaller font size for extra small screens
                                  sm: "9px", // Slightly larger font for small screens
                                  md: "11px", // Default font size for medium screens and above
                                },
                                // height:'120px'
                              }}
                            >
                              <Tooltip
                                title={`${
                                  !isAfterTargetDate
                                    ? "Upload not Available"
                                    : "Certificate Upload"
                                }`}
                              >
                                <Link
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  color="primary"
                                  sx={{
                                    textDecoration: "none",
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: isAfterTargetDate
                                      ? "pointer"
                                      : "not-allowed",
                                    color: isAfterTargetDate ? "black" : "gray",
                                    marginRight: 2, // Add some margin between the link and the circular progress
                                  }}
                                  //onClick={() => navigate("/cert-upload")}
                                  onClick={() =>
                                    isAfterTargetDate &&
                                    navigate("/cert-upload")
                                  }
                                >
                                  <UploadIcon
                                    sx={{
                                      marginRight: 1,
                                      color: `${
                                        isAfterTargetDate ? "blue" : "gray"
                                      }`,
                                    }}
                                  />
                                  Certificate Upload
                                </Link>
                              </Tooltip>
                            </Box>
                          </Box>
                        </TableCell>
                      }
                      <TableCell
                        sx={{
                          fontSize: {
                            xs: "8px", // Smaller font size for extra small screens
                            sm: "9px", // Slightly larger font for small screens
                            md: "10px", // Default font size for medium screens and above
                          },
                          padding: {
                            xs: "4px", // Less padding for extra small screens
                            sm: "8px", // Default padding for small screens
                            md: "12px", // Default padding for medium and larger screens
                          },
                          border: "none",
                        }}
                      >
                        <Box
                          sx={{
                            boxShadow: 3,
                            // backdropFilter: "blur(10px)",
                            background: "#fff",
                            borderRadius: "10px",
                            padding: "6px",
                            width: "180px",
                            height: "135px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "9px",
                                sm: "10px",
                                md: "11px",
                              },
                              padding: {
                                xs: "4px",
                                sm: "8px",
                                md: "4px",
                              },
                              fontWeight: "bold",
                              display: "flex",
                              marginBottom: "10px",
                              justifyContent: "center",
                            }}
                          >
                            {documentStatus?.assessment_status?.message
                              ? documentStatus.assessment_status.message
                              : ""}
                          </Typography>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: 2,
                              // width: "180px",
                              fontSize: { xs: "8px", sm: "9px", md: "11px" },
                              width:'100%'
                             
                            }}
                          ></Box>
                          <Tooltip
                            title={getButtonProps(row.assessment_active).text}
                          >
                            <Button
                             margin='0 auto'
                             display='flex'
                             justifyContent='center'
                             width='100%'
                            variant="contained"
                              color={
                                getButtonProps(row.assessment_active).color
                              }
                              startIcon={
                                getButtonProps(row.assessment_active).icon
                              }
                              disabled={
                                getButtonProps(row.assessment_active).disabled
                              }
                              sx={getButtonProps(row.assessment_active).sx } // Apply custom styles
                              onClick={() => navigate("/assessment")}
                            >
                              {getButtonProps(row.assessment_active).text}
                            </Button>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* New Section: Registration Steps & Doubt Clearing Session */}
      <Card sx={{ padding: 1, marginTop: 1, boxShadow: "none" }}>
        <CardContent>
          <Grid container spacing={2}>
            {/* Steps Section */}
            <Grid item xs={12} md={12}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginBottom: 2 }}
              >
                Steps to Follow
              </Typography>
              <Box
                sx={{
                  // bgcolor: "#E6F5FF",
                  // padding: {
                  //   xs: 1,
                  //   sm: 1,
                  //   md: 2,
                  // },
                  borderRadius: 2,
                  // height: {
                  //   xs: "300px",
                  //   sm: "350px",
                  //   md: "400px",
                  // },
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "8px",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#90caf9",
                    borderRadius: "4px",
                    "&:hover": {
                      background: "#64b5f6",
                    },
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: {
                      xs: 0.5,
                      sm: 0.8,
                    },
                  }}
                >
                  {[
                    {
                      step: "1",
                      text: 'Click on the "Course Access Document" to view the process document for completing the course.',
                    },
                    {
                      step: "2",
                      text: "After obtaining all your course completion certificates, click on the 'Certificate Upload' link.",
                    },
                    {
                      step: "3",
                      text: "Verification process will take 5-10 working days.",
                    },
                    {
                      step: "4",
                      text: "Once all certificates are verified, the assessment link will be activated for some selected Domains.",
                    },
                    {
                      step: "5",
                      text: "Click on the 'Assessment' link and complete the Final Internship Assessment.",
                    },
                    {
                      step: "6",
                      text: "Once Internship Certificate download link is activated, you will get an email.",
                    },
                  ].map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        bgcolor: "#fff",
                        padding: {
                          xs: 0.5,
                          sm: 1,
                        },
                        borderRadius: 2,
                        // boxShadow: "0 2px 4px rgba(0,0,0,0.05)",
                        // border: "1px solid #64b5f6",
                        display: "flex",
                        gap: 2,
                        alignItems: "flex-start",
                        // transition: "all 0.3s ease-in-out",
                        // "&:hover": {
                        //   bgcolor: "#E3F2FD", // Light blue background on hover
                        //   boxShadow: "0 4px 8px rgba(0,0,0,0.1)", // Increased shadow
                        //   border: "1px solid #1E88E5", // Darker blue border
                        //   transform: "scale(1.02)", // Slight scale effect
                        // },
                      }}
                    >
                      <Box
                        sx={{
                          // bgcolor: "#1976d2",
                          background:
                            "linear-gradient(to right, #E6F5FF, #B3E0FF)",
                          color: "#4897d7",
                          width: {
                            xs: 26,
                            sm: 30,
                          },
                          height: {
                            xs: 26,
                            sm: 30,
                          },
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: "bold",
                          fontSize: {
                            xs: 9,
                            sm: 11,
                          },
                          flexShrink: 0,
                        }}
                      >
                        {item.step}
                      </Box>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: 8,
                            sm: 9,
                            md: 12,
                          },
                          flex: 1,
                          lineHeight: "2.3",
                          fontWeight: "bold",
                        }}
                      >
                        {item.text}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Grid>

            {/* Announcements Section */}
            {/* <Grid item xs={12} md={6}>
              <AnnouncementsSection
                cohortId={fetchedRows[0]?.cohort_id}
                domainId={fetchedRows[0]?.domain_id}
              />
            </Grid> */}
          </Grid>
        </CardContent>
      </Card>

      <Dialog open={openInfoDialog} onClose={handleInfoDialogClose}>
        <DialogTitle>Not Available Yet</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            The Certificate Upload and progress tracking will be available after
            20th October 2024. Please come back after that date.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleInfoDialogClose}
            variant="contained"
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Dialog to show recording links */}
      <Dialog
        open={openRecordingDialog}
        onClose={handleRecordingDialogClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle sx={{ backgroundColor: blue[500], color: "#fff" }}>
          Recording Links
        </DialogTitle>
        <DialogContent>
          <Table sx={{ marginTop: 2, boxShadow: 3 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: blue[100] }}>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Sessions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedRecordings.length > 0 ? (
                selectedRecordings.map((recording, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:nth-of-type(even)": { backgroundColor: "#f5f5f5" },
                      "&:nth-of-type(odd)": { backgroundColor: "#ffffff" },
                      "&:hover": { backgroundColor: "#e0e0e0" },
                    }}
                  >
                    <TableCell align="center">
                      {/* Render the link using the parsed href and text */}
                      <Link
                        href={recording.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ color: blue[500], textDecoration: "none" }}
                      >
                        {recording.text}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    sx={{ color: "#888", padding: "16px" }}
                  >
                    No recordings available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleRecordingDialogClose}
            variant="contained"
            color="error"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDialogOpen}
        onClose={handlePdfClose}
        maxWidth="md"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: {
              xs: "90%",
              sm: "80%",
              md: "70%",
            },
            margin: {
              xs: 1,
              sm: 2,
              md: 3,
            },
          },
        }}
      >
        <DialogTitle>Course Access Document</DialogTitle>
        <DialogContent
          sx={{
            padding: {
              xs: 2,
              sm: 3,
              md: 4,
            },
          }}
        >
          {pdfUrl ? (
            <Document
              file={{
                url: pdfUrl,
                httpHeaders: {
                  "X-CustomHeader": "CustomValue",
                },
                withCredentials: false,
              }}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={(error) =>
                console.error("Error loading PDF:", error)
              }
              onItemClick={({ pageX, pageY, pageNumber, link }) =>
                onLinkClick({ link })
              } // Handle link clicks
              loading={
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: {
                      xs: "200px",
                      sm: "300px",
                      md: "400px",
                    },
                  }}
                >
                  <CircularProgress />
                </Box>
              }
            >
              <Page pageNumber={pageNumber} scale={scale} />
            </Document>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: {
                  xs: "200px", // For small screens
                  sm: "300px", // For medium screens
                  md: "400px", // For larger screens
                },
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center", // Center actions on small screens
            padding: {
              xs: 1, // Less padding for small screens
              sm: 2, // Default padding for medium screens
              md: 3, // More padding for larger screens
            },
            flexWrap: "wrap", // Allow buttons to wrap for smaller screens
            gap: 1, // Add space between buttons
          }}
        >
          <Button onClick={zoomOut} variant="contained" color="info">
            Zoom Out
          </Button>
          <Button onClick={zoomIn} variant="contained" color="info">
            Zoom In
          </Button>
          {/* <Button
            onClick={goToPreviousPage}
            variant="contained"
            color="primary"
            disabled={pageNumber <= 1}
          >
            Previous
          </Button> */}
          <Typography sx={{ margin: "0 10px" }}>
            Page {pageNumber} of {numPages}
          </Typography>
          {/* <Button
            onClick={goToNextPage}
            variant="contained"
            color="primary"
            disabled={pageNumber >= numPages}
          >
            Next
          </Button> */}
          <Button onClick={handlePdfClose} variant="contained" color="error">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default OngoingTableOld;
