import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { blue } from "@mui/material/colors";
import { Timer } from "lucide-react";
import ScheduleIcon from '@mui/icons-material/Schedule';

const CountdownTimer = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const dummyTimelineData = [
    { key: 1, value: "4 days " },
    
    // { key: 3, value: "8 days" },
  ];
  useEffect(() => {
    const targetDate = new Date("2025-03-12");

    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        // const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        // const minutes = Math.floor((difference / 1000 / 60) % 60);
        // const seconds = Math.floor((difference / 1000) % 60);
        setTimeLeft({ days});
      }
    };

    calculateTimeLeft();
    const timer = setInterval(calculateTimeLeft, 1000);

    return () => clearInterval(timer);
  }, []);

  const percentage = ((timeLeft.days / 365) * 100).toFixed(0);

  return (
    <>
    {/* <Typography
          variant="subtitle2"
          color="error"
          fontWeight="bold"
          gutterBottom
          sx={{
            fontSize: "0.75rem",
            textTransform: "uppercase",
            letterSpacing: "0.5px",
            animation: "blink 2s infinite",
          }}
        >
          Course Will Close Within
        </Typography> */}
    {/* <Box
      sx={{
        display: "flex",
        alignItems: "center",
        // gap: "3px",
        // p: 2,
        padding:"5px",
        paddingRight:2,
        borderRadius: 2,
        width:'100%',
        // bgcolor: blue[50],
        // border: `1px solid ${blue[100]}`,
        maxWidth: "fit-content",
      }}
    > */}
      
      {/* <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          variant="determinate"
          value={percentage}
          size={60}
          thickness={4}
          sx={{ color: blue[500] }}
        /> */}
        {/* <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Timer size={24} color={blue[700]} />
        </Box> */}
      {/* </Box> */}

      <Box>
        
        {/* <Box sx={{ display: "flex", alignItems:'center' ,gap: 2 ,width:'100%', flexDirection:'column'}}>
          <Box sx={{ width:'100%', display:'flex',flexDirection:"column" }}>
            <Typography variant="h6" color={blue[900]} fontWeight="bold">
              {timeLeft.days}
            </Typography>
            <Typography variant="caption" color={blue[700]}>
              Days Left
            </Typography> 
          </Box> */}
          {/* <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" color={blue[900]} fontWeight="bold">
              {timeLeft.hours}
            </Typography>
            <Typography variant="caption" color={blue[700]}>
              Hours
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" color={blue[900]} fontWeight="bold">
              {timeLeft.minutes}
            </Typography>
            <Typography variant="caption" color={blue[700]}>
              Min
            </Typography>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" color={blue[900]} fontWeight="bold">
              {timeLeft.seconds}
            </Typography>
            <Typography variant="caption" color={blue[700]}>
              Sec
            </Typography>
          </Box> */}
        {/* </Box> */}
        {/* <Box sx={{boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                            backdropFilter: "blur(10px)",
                            background: "rgba(255, 255, 255, 0.2)",
                            borderRadius: "10px",
                            padding:'6px',
                            width:"180px"
                            }}> */}
                            <Typography sx={{fontSize: {
                              xs: "9px",
                              sm: "10px",
                              md: "11px",
                            },
                            padding: {
                              xs: "4px",
                              sm: "8px",
                              md: "4px",
                            },
                            fontWeight: 'bold',
                            display:"flex",
                            marginBottom:'10px',
                            display:'flex',
                            justifyContent:'center'
                            }}>
                              Course Will Close Within </Typography>
                               <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                          }}
                        >
                          {dummyTimelineData.length > 0 ? (
                            dummyTimelineData.map((step, index) => (
                              <React.Fragment key={index}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    width:"100%",
                                  }}
                                >
                                  {/* Value above the key */}
                                  <span
                  style={{
                    fontSize: '14px',
                    marginBottom: '12px',
                    fontWeight: 'bold',
                    color: (() => {
                      const numericValue = parseInt(step.value);
                      if (isNaN(numericValue)) return 'blue'; // Default color for non-numeric values
                      return numericValue <= 5
                        ? '#f44336' // Error color (value <= 5)
                        : numericValue <= 15
                        ? '#2196f3' // Blue color (value <= 15)
                        : '#4caf50'; // Green color (value > 15)
                    })(),
                    width:"100%",
                    padding: "6px 12px",
                    backgroundColor: (() => {
                      const numericValue = parseInt(step.value);
                      if (isNaN(numericValue)) return 'rgba(255, 255, 255, 0.2)'; // Default glass color
                      return numericValue <= 5
                        ? 'rgba(255, 100, 100, 0.2)' // Light red glass (value <= 5)
                        : numericValue <= 15
                        ? 'rgba(100, 100, 255, 0.2)' // Light blue glass (value <= 15)
                        : 'rgba(100, 255, 100, 0.2)'; // Light green glass (value > 15)
                    })(),
                    borderRadius: '9px',
                    width:"100%",
                    // boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // Light shadow for depth
                    backdropFilter: 'blur(10px)', // Glassmorphism effect
                    WebkitBackdropFilter: 'blur(10px)', // Safari support
                  }}
                >
                  <ScheduleIcon sx={{marginRight:'5px', fontSize:"21px",fontWeight:'bold'}}/>
                  {step.value}
                </span>
                                  {/* Key inside a circle */}
                                  {/* <Box
                                    sx={{
                                      backgroundColor: '#e0f7fa',
                                      padding: '5px',
                                      borderRadius: '50%',
                                      height:'25px',
                                      width:'25px',
                                      fontWeight: 'bold',
                                      fontSize: {
                                        xs: '10px',
                                        sm: '12px',
                                        md: '10px',
                                      },
                                    }}
                                  >
                                    {step.key}
                                  </Box> */}
                                </Box>
                        
                                {/* Add horizontal line between steps except after the last one */}
                                {index < dummyTimelineData.length - 1 && (
                                  <Box
                                    sx={{
                                      height: '2px',
                                      width: '20px',
                                      backgroundColor: 'blue',
                                      margin: '0 ',
                                    }}
                                  />
                                )}
                              </React.Fragment>
                            ))
                          ) : (
                            <span style={{fontSize: '14px',
                              marginBottom: '12px',
                              fontWeight: 'bold',
                              color:'grey',
                              width:"100%",
                              padding: "6px 12px",
                              backgroundColor:'rgba(255, 255, 255, 0.2)',
                              borderRadius: '9px',
                                width:"100%",
                                // boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', // Light shadow for depth
                                backdropFilter: 'blur(10px)', // Glassmorphism effect
                                WebkitBackdropFilter: 'blur(10px)', // Safari support
                                display:'flex',
                                justifyContent:'center',
                                display:'none'

                            }}>NOT DISCLOSE</span>
                          )}
                        </Box>
                      
                          
      </Box>
    {/* </Box> */}
    </> 
  );
};

export default CountdownTimer;
