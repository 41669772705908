import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
const SkillsForm = ({
  onNext,
  handleBack,
  onError,
  onComplete,
  initialData,
}) => {
  const [skillsState, setSkillsState] = useState([]);
  const [tagValue, setTagVal] = useState("");
  const [tags, setTags] = useState([]);

  const [errors, setErrors] = useState([]);
  const [maxSkills, setMaxSkills] = useState(10);
  const [skillsError, setSkillsError] = useState(null);
  //..........................
  useEffect(() => {
    // console.log("initialData:", initialData);
    const storedData = localStorage.getItem("skillData");

    if (storedData) {
      // console.log("Setting experienceData from localStorage:", storedData);
      setSkillsState(JSON.parse(storedData));
    } else {
      // console.log("Setting skills from initialData:", initialData?.skills);
      setSkillsState(initialData ? initialData?.skills : []);
    }
  }, [initialData]);
  const deleteTag = (val) => {
    // Remove the tag from tags state
    let remainingTags = tags.filter((t) => t !== val);
    setTags(remainingTags);

    // Remove the tag from skills state and update local storage
    setSkillsState((prevSkills) => prevSkills.filter((skill) => skill !== val));
    localStorage.setItem(
      "skillData",
      JSON.stringify(skillsState.filter((skill) => skill !== val))
    );
  };

  const addTags = (e) => {
    if (e.key === "Enter" && tagValue) {
      setSkillsState((prevSkills) => {
        // Ensure prevSkills is always initialized as an array
        const prevSkillsArray = Array.isArray(prevSkills) ? prevSkills : [];
        const updatedSkills = [...prevSkillsArray, tagValue];
        // Store the updated skillsState in local storage
        localStorage.setItem("skillData", JSON.stringify(updatedSkills));
        return updatedSkills;
      });
      setTagVal("");
    }
  };

  // const handleInputChange = (field, value) => {
  //   console.log("start");
  //   if (field === "skills") {
  //     const maxSkills = 10;
  //     console.log(maxSkills);
  //     console.log(skillsError);
  //     if (value.length > maxSkills) {
  //       setSkillsError(`Maximum limit of ${maxSkills} skills exceeded`);
  //     } else {
  //       setSkillsError(null);
  //     }
  //   }
  //   setSkillsState((prevState) => ({
  //     ...prevState,
  //     [field]: value,
  //   }));
  // };

  const handleInputChange = (field, value) => {
    if (field === "skills") {
      // console.log("Number of skills:", skillsState.length);
      // console.log("Length of tags:", tags.length);
      if (tags.length > maxSkills) {
        setSkillsError(`Maximum limit of ${maxSkills} skills exceeded`);
      } else {
        setSkillsError(null);
      }
    }
    setSkillsState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };
  useEffect(() => {
    // console.log("maxSkills:", maxSkills);
    // console.log("skillsError:", skillsError);
    // console.log("tags length", tags.length);
    if (tagValue.length > 10) {
      setSkillsError(`Maximum limit of ${maxSkills} skills exceeded`);
    }
  }, [maxSkills, skillsError, tags]);
  // const handleInputChange = (field, value) => {
  //   if (field === "skills") {
  //     const maxSkills = 10;
  //     if (value.length > maxSkills) {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         skills: `Maximum limit of ${maxSkills} skills exceeded`,
  //       }));
  //     } else {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         skills: null,
  //       }));
  //     }
  //   }

  //   setSkillsState((prevState) => ({
  //     ...prevState,
  //     [field]: value,
  //   }));
  // };

  const loadSkillData = () => {
    const storedData = localStorage.getItem("skillData");
    if (storedData) {
      setSkillsState(JSON.parse(storedData));
    }
  };

  useEffect(() => {
    loadSkillData();
  }, []);

  const handleNext = () => {
    let newErrors = { ...errors };
    let isValid = true;
    if (!Array.isArray(skillsState)) {
      console.error("skillsState is undefined or not an array");
      alert("Please fill the form");
      return;
    }

    if (skillsState.length === 0 || skillsState.length >= 11) {
      newErrors.skills = true;
      isValid = false;
    } else {
      newErrors.skills = false;
    }

    setErrors(newErrors);

    if (isValid) {
      onNext(skillsState);
      onError(false);
      onComplete();
      localStorage.setItem("skillData", JSON.stringify(skillsState));
    } else {
      onError(true);
    }
  };

  return (
    <div className=" min-h-[500px] max-h-auto">
      <div className="p-2 md:p-2 lg:p-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
        <div className="mb-4 col-span-2">
          <div className="mt-4">
            <div className="mt-2">
              <label
                htmlFor="skillsInput"
                className="block text-gray-700 text-sm font-bold"
              >
                Add Skill
              </label>

              <div className="items-center">
                <input
                  id="skillsInput"
                  type="text"
                  placeholder="Enter your skills"
                  value={tagValue}
                  onChange={(e) => setTagVal(e.target.value)}
                  onKeyDown={addTags}
                  className="border-2 text-gray-950 h-10 mt-1 pl-2 rounded-md bg-gray-100 w-full focus:outline-sky-400"
                />
                {skillsError && <p className="text-red-500">{skillsError}</p>}
                <p className="text-gray-500 text-sm">
                  Press <span className="font-bold">Enter</span> to add your
                  skills.
                </p>
                {errors?.skills && (
                  <p className="text-red-500 text-sm">
                    You must enter at least one skill or a maximum of 10 skills.
                  </p>
                )}
              </div>
            </div>
            {skillsState?.length > 0 && (
              <label
                htmlFor="skills"
                className="block text-gray-700 text-sm font-bold mt-5"
              >
                Skills
              </label>
            )}
            <div className="flex flex-wrap gap-2 mt-2">
              {skillsState?.map((item, index) => (
                <button
                  key={index}
                  onClick={() => deleteTag(item)}
                  className="bg-blue-500 text-white px-2 py-1 rounded-md flex items-center"
                >
                  {item}
                  <span className="ml-2">x</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="left-0 w-full flex justify-between">
        <div className="ml-4 flex justify-start md:absolute md:bottom-5 mb-5">
          <Button
            className="mt-4"
            variant="contained"
            color="success"
            onClick={handleBack}
          >
            Back
          </Button>
        </div>
        <div className="mr-2 flex justify-end md:absolute md:bottom-1 mb-5 md:mb-10 md:right-12">
                  <Button
                    className="mt-4"
                    variant="contained"
                    color="secondary"
                    onClick={handleNext}
                  >
                    Continue
                  </Button>
                </div>
      </div>
    </div>
  );
};

export default SkillsForm;
