import React, { useState } from "react";
import { Link, Tooltip, CircularProgress } from "@mui/material";
import { MdAssessment } from "react-icons/md";
import { toast } from "react-toastify";
import { BASE_URL } from "../../services/configUrls";

export const CourseAccessLinkCell = ({
  courseAccessLink,
  documentsRestrictionStart,
  documentsRestrictionEnd,
  domainId,
  
}) => {
  const [loadingLmsAccess, setLoadingLmsAccess] = useState(false);

  const isAccessRestricted = () => {
    const currentDate = new Date();
    const startDate = new Date(documentsRestrictionStart);
    const endDate = new Date(documentsRestrictionEnd);
    return currentDate >= startDate && currentDate <= endDate;
  };

  const getLmsAccess = async () => {
    setLoadingLmsAccess(true);
    try {
      const response = await fetch(`${BASE_URL}/token/getLmsAccess`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (!response.ok) {
        let errorData = null;
        try {
          errorData = await response.json();
        } catch (e) {
          console.error("Error parsing JSON:", e);
        }

        if (response.status === 401) {
          toast.error("Unauthorized access. Please log in again.");
        } else if (response.status === 403) {
          toast.error("Access forbidden. Contact support.");
        } else {
          toast.error(
            errorData?.detail || "An error occurred while accessing the course."
          );
        }
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
      } else {
        toast.error("No course access URL provided");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to access the course. Please try again.");
    } finally {
      setLoadingLmsAccess(false);
    }
  };

  const handleLinkClick = async (e) => {
    e.preventDefault();

    if (isAccessRestricted()) {
      return;
    }

    if ([26, 27, 28, 36, 37].includes(domainId)) {
      await getLmsAccess();
    } else if (courseAccessLink) {
      window.open(courseAccessLink, "_blank");
    }
  };

  return (
    <Tooltip
      title={
        isAccessRestricted()
          ? "Access Restricted During This Period"
          : "Course Access Link"
      }
      // sx={{display: 'flex',
      //   alignItems: 'center',
      //   justifyContent: 'center',
      //   gap: 1,}}
    >
      <span>
        <Link
          href="#"
          color="primary"
          sx={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            cursor: isAccessRestricted() ? "not-allowed" : "pointer",
            opacity: isAccessRestricted() ? 0.5 : 1,
            pointerEvents: isAccessRestricted() ? "none" : "auto",
            "&:hover": {
              textDecoration: "none",
            },
          }}
          onClick={handleLinkClick}
        >
          {loadingLmsAccess ? (
            <CircularProgress size={20} sx={{ mr: 1 }} />
          ) : (
            <MdAssessment
              style={{
                fontSize: "20px",
                color: isAccessRestricted() ? "gray" : "blue",
                marginRight: "8px",
              }}
            />
          )}
          Course Access Link
        </Link>
      </span>
    </Tooltip>
  );
};

export default CourseAccessLinkCell;
