import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  Grid,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Tooltip
} from "@mui/material";
import domtoimage from "dom-to-image";
import { jsPDF } from "jspdf";
import QRCode from "qrcode";
import { BASE_URL } from "../../services/configUrls";
import certificates from "../../assets/apsche.png";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import DownloadIcon from "@mui/icons-material/Download";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";

// Styled components using Material UI's styled API
const CertificateButton = styled(Button)(({ theme, selected }) => ({
  margin: theme.spacing(1),
  backgroundColor: selected
    ? theme.palette.primary.main
    : theme.palette.grey[200],
  color: selected ? theme.palette.common.white : theme.palette.text.primary,
  "&:hover": {
    backgroundColor: selected
      ? theme.palette.primary.dark
      : theme.palette.grey[300],
  },
}));

const DownloadButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(to right, #2193b0, #6dd5ed)",
  color: theme.palette.common.white,
  fontWeight: "bold",
  padding: "10px 20px",
  textTransform: "uppercase",
  fontSize: "1rem",
  "&:hover": {
    background: "linear-gradient(to right, #6dd5ed, #2193b0)",
  },
}));

const GrayishText = styled(Typography)({
  fontFamily: "Times New Roman",
  color: "#666666",
  fontSize: "1.35rem",
  display: "inline",
});

const UnderlinedText = styled(Typography)({
  borderBottom: "2px solid black",
  paddingBottom: "1px",
  fontWeight: "bold",
  fontFamily: "Times New Roman",
  color: "black",
  fontSize: "1.35rem",
  display: "inline",
});

const BoldText = styled(Typography)({
  fontWeight: "bold",
  fontFamily: "Times New Roman",
  fontSize: "1.35rem",
  color: "black",
  display: "inline",
});

const LinkedInButton = styled(Button)(({ theme }) => ({
  background: "linear-gradient(to right, #0077B5, #0A66C2)",
  color: theme.palette.common.white,
  fontWeight: "bold",
  padding: "10px 20px",
  textTransform: "uppercase",
  fontSize: "1rem",
  marginLeft: theme.spacing(2),
  "&:hover": {
    background: "linear-gradient(to right, #0A66C2, #0077B5)",
  },
  "& .MuiSvgIcon-root": {
    marginRight: theme.spacing(1),
  },
}));

const SemiBoldUnderlineText = styled(Typography)({
  borderBottom: "2px solid black",
  paddingBottom: "1px",
  fontWeight: 500,
  fontFamily: "Times New Roman",
  color: "black",
  fontSize: "1.35rem",
  display: "inline",
});

// Certificate content component to avoid duplication
const CertificateContent = ({ certificate, qrCodeUrl, certificateRef }) => (
  <Paper
    ref={certificateRef}
    elevation={3}
    sx={{
      position: "relative",
      width: "100%",
      margin: "auto",
      padding: 2,
    }}
  >
    <img
      src={certificates}
      alt="Certificate"
      style={{ width: "100%", height: "auto" }}
    />

    <Box
      sx={{
        position: "absolute",
        top: "44%",
        left: "10%",
        right: "10%",
      }}
    >
      <Typography
        sx={{
          color: "#666666",
          fontSize: "1.35rem",
          lineHeight: 1.9,
          textAlign: "justify",
          fontFamily: "Times New Roman",
          textJustify: "inter-word",
        }}
      >
        This is to certify that Ms./Mr.{" "}
        <UnderlinedText>{certificate.name}</UnderlinedText>
        <SemiBoldUnderlineText>
          , <GrayishText>Course :</GrayishText>{" "}
          {certificate.course},{" "}
          <GrayishText>Branch : </GrayishText>
          {certificate.branch},{" "}
          <GrayishText>Semester : </GrayishText>
          {certificate.semester},{" "}
          <GrayishText>Roll No : </GrayishText>
          {certificate.roll_no}{" "}
          <GrayishText>Under</GrayishText>{" "}
          <UnderlinedText>
            {certificate.institute_name}
          </UnderlinedText>{" "}
          <GrayishText>of</GrayishText>{" "}
          <UnderlinedText>
            {certificate.university_name}
          </UnderlinedText>
        </SemiBoldUnderlineText>{" "}
        has Successfully completed the{" "}
        <BoldText>Short-Term Internship for 120 Hours</BoldText> on{" "}
        <SemiBoldUnderlineText>
          {certificate.domains}
        </SemiBoldUnderlineText>{" "}
        Organised by <BoldText>EduSkills</BoldText> in Collaboration
        with{" "}
        <BoldText>
          Andhra Pradesh State Council of Higher Education
        </BoldText>
        .
      </Typography>
    </Box>

    <Box
      sx={{
        position: "absolute",
        bottom: "8%",
        left: "10%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          fontSize: "1.1rem",
          fontWeight: 500,
          color: "#666666",
          fontFamily: "Times New Roman",
          mb: 1,
        }}
      >
        Certificate No. : {certificate.cert_no}
      </Typography>
      <Typography
        sx={{
          fontSize: "1.1rem",
          fontWeight: 500,
          color: "#666666",
          fontFamily: "Times New Roman",
        }}
      >
        Issue Date :{" "}
        {new Date(certificate.cert_gen_date)
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })
          .replace(",", "")}
      </Typography>
    </Box>

    <Box
      sx={{
        position: "absolute",
        bottom: "8%",
        right: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
      }}
    >
      {qrCodeUrl && (
        <img
          src={qrCodeUrl}
          alt="QR Code"
          style={{ width: "120px", height: "120px" }}
        />
      )}
    </Box>
  </Paper>
);

const ApscheCertificate = ({ initial }) => {
  const [certificatesData, setCertificatesData] = useState([]);
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const certificateRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [fullscreenOpen, setFullscreenOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenFullscreen = () => setFullscreenOpen(true);
  const handleCloseFullscreen = () => setFullscreenOpen(false);

  const handleConfirmShare = () => {
    handleCloseDialog();
    handleLinkedInShare();
  };

  useEffect(() => {
    console.log("Initial received:", initial);
  
    if (initial) {
      if (Array.isArray(initial) && initial.length > 0) {
        setCertificatesData(initial); // Set entire array if it's already an array
        setSelectedCertificate(initial[0]); // Select the first certificate by default
      } else if (typeof initial === "object" && Object.keys(initial).length > 0) {
        setCertificatesData([initial]); // Convert single object to an array
        setSelectedCertificate(initial); // Select it as the default
      }
    }
    setLoading(false);
  }, [initial]);
  

  useEffect(() => {
    if (selectedCertificate?.verification_link) {
      // Handle the verification link format from the provided data
      const verificationUrl = selectedCertificate.verification_link.startsWith('http') 
        ? selectedCertificate.verification_link 
        : `${BASE_URL}${selectedCertificate.verification_link}`;
      
      QRCode.toDataURL(verificationUrl)
        .then(setQrCodeUrl)
        .catch((error) => {
          console.error("Error generating QR code:", error);
        });
    }
  }, [selectedCertificate]);

  const handleCertificateSelect = (certificate) => {
    setSelectedCertificate(certificate);
  };

  const compressImage = async (imgData) => {
    // Create an image element
    const img = new Image();
    img.src = imgData;

    await new Promise((resolve) => (img.onload = resolve));

    // Create canvas
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Calculate new dimensions (reduce size by 50%)
    const maxWidth = img.width * 0.5;
    const maxHeight = img.height * 0.5;

    canvas.width = maxWidth;
    canvas.height = maxHeight;

    // Draw image at new size
    ctx.drawImage(img, 0, 0, maxWidth, maxHeight);

    // Return compressed image as base64
    return canvas.toDataURL("image/jpeg", 0.7); // Use JPEG format with 70% quality
  };

  const handleLinkedInShare = async () => {
    if (!certificateRef.current) return;

    try {
      // Generate image data from the certificate
      const certificateElement = certificateRef.current;
      const scale = 2; // Reduced scale from 4 to 2
      const options = {
        width: certificateElement.clientWidth * scale,
        height: certificateElement.clientHeight * scale,
        style: {
          transform: `scale(${scale})`,
          transformOrigin: "top left",
          width: `${certificateElement.clientWidth}px`,
          height: `${certificateElement.clientHeight}px`,
        },
        quality: 0.8, // Reduced quality
        bgcolor: "#ffffff",
      };

      const imgData = await domtoimage.toPng(certificateElement, options);

      // Compress the image
      const compressedImage = await compressImage(imgData);

      // Store in sessionStorage instead of localStorage
      sessionStorage.setItem("certificateImage", compressedImage);
      sessionStorage.setItem(
        "certificateName",
        `${selectedCertificate.name}_certificate`
      );

      // Redirect to LinkedIn auth
      const redirectUri = encodeURIComponent(
        `${window.location.origin}/internship/linkedin-callback`
      );
      const linkedInAuthUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86hgcl1mjwpc0q&redirect_uri=${redirectUri}&scope=openid w_member_social profile`;
      window.location.href = linkedInAuthUrl;
    } catch (error) {
      console.error("Error preparing image for sharing:", error);
      // Show error to user
      alert(
        "Failed to prepare image for sharing. Please try again with a smaller image size."
      );
    }
  };

  const handleDownloadPDF = () => {
    if (!certificateRef.current) return;

    const certificateElement = certificateRef.current;
    const width = certificateElement.clientWidth;
    const height = certificateElement.clientHeight;
    const scale = 4;

    const options = {
      width: width * scale,
      height: height * scale,
      style: {
        transform: `scale(${scale})`,
        transformOrigin: "top left",
        width: `${width}px`,
        height: `${height}px`,
      },
      quality: 1,
      bgcolor: "#ffffff",
    };

    domtoimage
      .toPng(certificateElement, options)
      .then((imgData) => {
        const pdf = new jsPDF({
          orientation: width > height ? "landscape" : "portrait",
          unit: "px",
          format: [width, height],
        });

        pdf.addImage(imgData, "PNG", 0, 0, width, height, undefined, "FAST");
        pdf.save(`${selectedCertificate.name}_certificate.pdf`);
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!certificatesData.length || !selectedCertificate) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6" color="textSecondary">
          No Certificates Available
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ fontFamily: "Times New Roman" }}>
      {/* Certificate Selection Buttons (if needed) */}
      {certificatesData.length > 1 && (
        <Grid container justifyContent="center" spacing={2} sx={{ mb: 4, mt: 2 }}>
          {certificatesData.map((cert, index) => (
            <Grid item key={cert.cert_no}>
              <CertificateButton
                variant="contained"
                selected={selectedCertificate?.cert_no === cert.cert_no}
                onClick={() => handleCertificateSelect(cert)}
              >
                Certificate {index + 1}
              </CertificateButton>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Small certificate with fullscreen and download buttons */}
      <Box sx={{ position: "relative", maxWidth: "500px", margin: "auto", mt: 2 }}>
     
        <Paper 
          elevation={3} 
          sx={{ 
            cursor: "pointer",
            position: "relative",
            overflow: "hidden",
            '&:hover': {
              boxShadow: 6
            }
          }}
          onClick={handleOpenFullscreen}
        >
          <img
      src={certificates}
      alt="Certificate"
      style={{ width: "100%", height: "auto" }}
    />

    <Box
      sx={{
        position: "absolute",
        top: "44%",
        left: "10%",
        right: "10%",
      }}
    >
      <Typography
        sx={{
          color: "#666666",
          fontSize: "10px",
          lineHeight: 1.9,
          textAlign: "justify",
          fontFamily: "Times New Roman",
          textJustify: "inter-word",
        }}
      >
        This is to certify that Ms./Mr.{" "}
        <UnderlinedText sx={{ fontSize: "10px",}}>{selectedCertificate.name}</UnderlinedText>
        <SemiBoldUnderlineText sx={{ fontSize: "10px",}}>
          , <GrayishText sx={{ fontSize: "10px",}}>Course :</GrayishText>{" "}
          {selectedCertificate.course},{" "}
          <GrayishText sx={{ fontSize: "10px",}}>Branch : </GrayishText>
          {selectedCertificate.branch},{" "}
          <GrayishText sx={{ fontSize: "10px",}}>Semester : </GrayishText>
          {selectedCertificate.semester},{" "}
          <GrayishText sx={{ fontSize: "10px",}}>Roll No : </GrayishText>
          {selectedCertificate.roll_no}{" "}
          <GrayishText sx={{ fontSize: "10px",}}>Under</GrayishText>{" "}
          <UnderlinedText sx={{ fontSize: "10px",}}>
            {selectedCertificate.institute_name}
          </UnderlinedText >{" "}
          <GrayishText sx={{ fontSize: "10px",}} >of</GrayishText>{" "}
          <UnderlinedText sx={{ fontSize: "10px",}}>
            {selectedCertificate.university_name}
          </UnderlinedText>
        </SemiBoldUnderlineText >{" "}
        has Successfully completed the{" "}
        <BoldText sx={{ fontSize: "10px",}}>Short-Term Internship for 120 Hours</BoldText> on{" "}
        <SemiBoldUnderlineText sx={{ fontSize: "10px",}}>
          {selectedCertificate.domains}
        </SemiBoldUnderlineText>{" "}
        Organised by <BoldText sx={{ fontSize: "10px",}}>EduSkills</BoldText> in Collaboration
        with{" "}
        <BoldText sx={{ fontSize: "10px",}}>
          Andhra Pradesh State Council of Higher Education
        </BoldText>
        .
      </Typography>
    </Box>

    <Box
      sx={{
        position: "absolute",
        bottom: "8%",
        left: "10%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{
          fontSize: "11px",
          fontWeight: 500,
          color: "#666666",
          fontFamily: "Times New Roman",
          mb: 1,
        }}
      >
        Certificate No. : {selectedCertificate.cert_no}
      </Typography>
      <Typography
        sx={{
          fontSize: "11px",
          fontWeight: 500,
          color: "#666666",
          fontFamily: "Times New Roman",
        }}
      >
        Issue Date :{" "}
        {new Date(selectedCertificate.cert_gen_date)
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })
          .replace(",", "")}
      </Typography>
    </Box>

    <Box
      sx={{
        position: "absolute",
        bottom: "8%",
        right: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
      }}
    >
      {qrCodeUrl && (
        <img
          src={qrCodeUrl}
          alt="QR Code"
          style={{ width: "60px", height: "60px" }}
        />
      )}
    </Box>
          {/* Fullscreen button overlay */}
         
          <Tooltip title="View Full Certificate">
            <IconButton 
              sx={{
                position: "absolute",
                top: 7,
                right: 10,
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.9)" }
              }}
              onClick={handleOpenFullscreen}
            >
              <FullscreenIcon />
            </IconButton>
          </Tooltip>
          {/* Download button overlay */}
          {/* <Tooltip title="Download Certificate">
            <IconButton 
              sx={{
                position: "absolute",
                bottom: 10,
                right: 60,
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.9)" }
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleDownloadPDF();
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip> */}
        </Paper>
      </Box>

      {/* Fullscreen Dialog */}
      <Dialog
        open={fullscreenOpen}
        onClose={handleCloseFullscreen}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            maxHeight: "90vh",
            maxWidth: "90vw",
            width: "1150px",
            overflowY: "auto",
            borderRadius:3
          }
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6">Certificate</Typography>
          <Box>
            <Tooltip title="Download Certificate">
              <IconButton onClick={handleDownloadPDF}>
                <DownloadIcon />
              </IconButton>
            </Tooltip>
            <IconButton onClick={handleCloseFullscreen}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ overflowX: "auto" }}>
            <CertificateContent 
              certificate={selectedCertificate} 
              qrCodeUrl={qrCodeUrl} 
              certificateRef={certificateRef}
            />
          </Box>
        </DialogContent>
      </Dialog>

      {/* LinkedIn Share Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Share Certificate on LinkedIn?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will be redirected to LinkedIn to share your certificate. Would
            you like to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConfirmShare}
            color="primary"
            variant="contained"
            autoFocus
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ApscheCertificate;