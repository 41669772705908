import React, { useState, useCallback } from "react";
import { Box, Button, Grid, TextField,MenuItem,Collapse, Typography, Avatar, IconButton, Dialog, DialogActions, DialogContent } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';  // Import the edit icon
import Cropper from "react-easy-crop";
import ReactQuill from 'react-quill'; // Import ReactQuill
import 'react-quill/dist/quill.snow.css'; // Import the styles for ReactQuill
import { ExpandMore, ExpandLess } from '@mui/icons-material';


const ResumeBuilder = () => {
    const [resumeData, setResumeData] = useState({
        personalInfo: {
          firstName: "",
          lastName: "",
          jobTitle: "",
          email: "",
          phone: "",
          country: "",
          city: "",
          profilePhoto: null, // Store profile photo URL
        },
        summary: "",
        skills: [], // Ensure skills is an array to avoid 'undefined' errors
      });
    const [educationFields, setEducationFields] = useState([]);
    const [expanded, setExpanded] = useState(false);

   
    const handleAddEducation = () => {
        setEducationFields([
          ...educationFields,
          { 
            school: '', degree: '', country: '', city: '', 
            startYear: '', startMonth: '', endYear: '', endMonth: '',
            currentStudying: false, description: '' 
          }]);
      };
    
      const handleEducationChange = (index, field, value) => {
        const newEducationFields = [...educationFields];
        newEducationFields[index][field] = value;
        setEducationFields(newEducationFields);
      };
    
      const handleDescriptionChange = (index, value) => {
        const newEducationFields = [...educationFields];
        newEducationFields[index].description = value;
        setEducationFields(newEducationFields);
      };
    
      const toggleExpand = () => {
        setExpanded(!expanded);
      };
  // Theme color
  const [activeColor, setActiveColor] = useState("grey");

  // Cropping states
  const [openCropper, setOpenCropper] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // Handle profile photo upload
  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
        setOpenCropper(true);
      };
      reader.readAsDataURL(file);
    }
  };

  // Capture cropped area
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Save cropped image
  const saveCroppedImage = async () => {
    if (!imageSrc || !croppedAreaPixels) return;

    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = croppedAreaPixels.width;
      canvas.height = croppedAreaPixels.height;

      ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );

      const croppedDataURL = canvas.toDataURL("image/jpeg");

      setResumeData((prevData) => ({
        ...prevData,
        personalInfo: { ...prevData.personalInfo, profilePhoto: croppedDataURL },
      }));

      setOpenCropper(false);
    };
  };

  // Delete profile photo
  const deletePhoto = () => {
    setResumeData((prevData) => ({
      ...prevData,
      personalInfo: { ...prevData.personalInfo, profilePhoto: null },
    }));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: 4, p: 4, bgcolor: "#f5f5f5", minHeight: "100vh" }}>
      {/* Left Side: Form */}
      <Box sx={{ width: { xs: "100%", md: "50%" }, bgcolor: "white", p: 4, borderRadius: 2, boxShadow: 3 }}>
        <Typography variant="h5" fontWeight="bold" color="primary" mb={2}>
          Create Your Resume
        </Typography>

        {/* Profile Photo Upload */}
        <Box sx={{ textAlign: "center", mb: 3 }}>
          <Avatar src={resumeData.personalInfo.profilePhoto} sx={{ width: 100, height: 100, margin: "auto", bgcolor: activeColor }} />
          
          <Box mt={1} display="flex" justifyContent="center" gap={1}>
            {/* Upload/Edit Photo */}
            <IconButton color="primary" component="label">
              <input type="file" hidden accept="image/*" onChange={handlePhotoUpload} />
              <PhotoCameraIcon />
            </IconButton>
            {/* Edit Photo (Only visible if a photo exists) */}
            {/* {resumeData.personalInfo.profilePhoto && (
                    <IconButton
                        color="secondary"
                        onClick={handlePhotoUpload}

                    >
                        <EditIcon />
                    </IconButton>
                )} */}

            {/* Delete Photo */}
            {resumeData.personalInfo.profilePhoto && (
              <IconButton color="error" onClick={deletePhoto}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>

        
        </Box>

        {/* Personal Info Form */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField label="First Name" fullWidth value={resumeData.personalInfo.firstName} 
              onChange={(e) => setResumeData({ ...resumeData, personalInfo: { ...resumeData.personalInfo, firstName: e.target.value } })} />
          </Grid>
          <Grid item xs={6}>
            <TextField label="Last Name" fullWidth value={resumeData.personalInfo.lastName} 
              onChange={(e) => setResumeData({ ...resumeData, personalInfo: { ...resumeData.personalInfo, lastName: e.target.value } })} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Job Title" fullWidth value={resumeData.personalInfo.jobTitle} 
              onChange={(e) => setResumeData({ ...resumeData, personalInfo: { ...resumeData.personalInfo, jobTitle: e.target.value } })} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Email" fullWidth type="email" value={resumeData.personalInfo.email} 
              onChange={(e) => setResumeData({ ...resumeData, personalInfo: { ...resumeData.personalInfo, email: e.target.value } })} />
          </Grid>
          <Grid item xs={6}>
            <TextField label="Phone" fullWidth value={resumeData.personalInfo.phone} 
              onChange={(e) => setResumeData({ ...resumeData, personalInfo: { ...resumeData.personalInfo, phone: e.target.value } })} />
          </Grid>
          <Grid item xs={6}>
            <TextField label="City" fullWidth value={resumeData.personalInfo.city} 
              onChange={(e) => setResumeData({ ...resumeData, personalInfo: { ...resumeData.personalInfo, city: e.target.value } })} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Country" fullWidth value={resumeData.personalInfo.country} 
              onChange={(e) => setResumeData({ ...resumeData, personalInfo: { ...resumeData.personalInfo, country: e.target.value } })} />
          </Grid>
        </Grid>
         {/* Professional Summary
         <Box mt={4}>
          <TextField
            label="Professional Summary"
            multiline
            rows={4}
            fullWidth
            value={resumeData.summary}
            onChange={(e) => setResumeData({ ...resumeData, summary: e.target.value })}
          />
        </Box> */}
        {/* Professional Summary */}
        <Box mt={4}>
                    <Typography variant="h6" color="primary">Professional Summary</Typography>
                    <ReactQuill
                    placeholder="Enter your Summary"
                        value={resumeData.summary}
                        onChange={(value) => setResumeData({ ...resumeData, summary: value })}
                        modules={{
                            toolbar: [
                                [{ 'header': '1'}, { 'header': '2'},],
                                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                [{ 'indent': '-1'}, { 'indent': '+1' }],
                                ['bold', 'italic', 'underline'],
                                ['link'],
                                
                                [{ 'align': [] }],
                                
                            ],
                        
                        }}
                        sx={{borderRadius:3}}
                    />
                </Box>
                <Box sx={{ width: '100%', maxWidth: 900, margin: '0 auto', padding: 2 }}>
      <Button variant="contained" onClick={handleAddEducation} sx={{ marginBottom: 2 }}>
        Add Education
      </Button>

      {/* Display down arrow icon when education fields are added */}
      {educationFields.length > 0 && (
        <IconButton onClick={toggleExpand} sx={{ marginLeft: 2 }}>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      )}

      {/* Show the education details when the "expand" state is true */}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Box sx={{ marginTop: 2, border: '1px solid #ccc', padding: 2, borderRadius: 1 }}>
          {educationFields.map((education, index) => (
            <Box key={index}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="School/University"
                    fullWidth
                    value={education.school}
                    onChange={(e) => handleEducationChange(index, 'school', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Degree"
                    fullWidth
                    value={education.degree}
                    onChange={(e) => handleEducationChange(index, 'degree', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Country"
                    fullWidth
                    value={education.country}
                    onChange={(e) => handleEducationChange(index, 'country', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="City"
                    fullWidth
                    value={education.city}
                    onChange={(e) => handleEducationChange(index, 'city', e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Start Year"
                    fullWidth
                    value={education.startYear}
                    onChange={(e) => handleEducationChange(index, 'startYear', e.target.value)}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Start Month"
                    fullWidth
                    value={education.startMonth}
                    onChange={(e) => handleEducationChange(index, 'startMonth', e.target.value)}
                    select
                  >
                    {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, i) => (
                      <MenuItem key={i} value={month}>{month}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="End Year"
                    fullWidth
                    value={education.endYear}
                    onChange={(e) => handleEducationChange(index, 'endYear', e.target.value)}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="End Month"
                    fullWidth
                    value={education.endMonth}
                    onChange={(e) => handleEducationChange(index, 'endMonth', e.target.value)}
                    select
                  >
                    {['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'].map((month, i) => (
                      <MenuItem key={i} value={month}>{month}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Currently Studying Here"
                    type="checkbox"
                    checked={education.currentStudying}
                    onChange={(e) => handleEducationChange(index, 'currentStudying', e.target.checked)}
                  />
                </Grid>
              </Grid>

              {/* ReactQuill Description */}
              <Box sx={{ marginTop: 2 }}>
                <ReactQuill
                  value={education.description}
                  onChange={(value) => handleDescriptionChange(index, value)}
                  placeholder="Enter description"
                  modules={{
                    toolbar: [
                      [{ 'header': '1' }, { 'header': '2' }],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                      ['bold', 'italic', 'underline'],
                      ['link'],
                      [{ 'align': [] }],
                    ],
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      </Collapse>
    </Box>
      </Box>
          {/* Summary */}
          

      {/* Right Side: Resume Preview */}
      <Box sx={{ width: { xs: "100%", md: "50%" }, bgcolor: "white", p: 4, borderRadius: 2, boxShadow: 3 }}>
        <Typography variant="h5" fontWeight="bold" color="primary" mb={2}>
          Resume Preview
        </Typography>

        {/* Resume Header */}
        <Box sx={{ p: 3, textAlign: "center", borderRadius: 2, bgcolor: activeColor, color: "white" }}>
          <Avatar 
            src={resumeData.personalInfo.profilePhoto} 
            sx={{ width: 80, height: 80, margin: "auto", mb: 1, bgcolor: "white" }}
          />
          <Typography variant="h5">
            {resumeData.personalInfo.firstName} {resumeData.personalInfo.lastName}
          </Typography>
          <Typography variant="subtitle1">{resumeData.personalInfo.jobTitle}</Typography>
          <Typography variant="body2">
            {resumeData.personalInfo.email} | {resumeData.personalInfo.phone}
          </Typography>
          <Typography variant="body2">
            {resumeData.personalInfo.city}, {resumeData.personalInfo.country}
          </Typography>
        </Box>

        {/* Summary */}
        <Box mt={3}>
          <Typography variant="h6" color="primary">Professional Summary</Typography>
          <Typography variant="body2"><div 
                        dangerouslySetInnerHTML={{ __html: resumeData.summary }} 
                        style={{ whiteSpace: 'pre-wrap' }} 
                    /></Typography>
        </Box>

        {/* Skills */}
        <Box mt={3}>
          <Typography variant="h6" color="primary">Skills</Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
            {resumeData.skills.length > 0 ? (
              resumeData.skills.map((skill, index) => (
                <Typography key={index} sx={{ px: 2, py: 1, bgcolor: activeColor, color: "white", borderRadius: "12px" }}>
                  {skill}
                </Typography>
              ))
            ) : (
              <Typography variant="body2" color="gray">No skills added yet.</Typography>
            )}
          </Box>
        </Box>
        </Box>

      {/* Cropper Dialog */}
      <Dialog open={openCropper} onClose={() => setOpenCropper(false)} fullWidth maxWidth="sm">
        <DialogContent sx={{ height: 400, position: "relative" }}>
          <Cropper 
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCropper(false)} color="secondary">Cancel</Button>
          <Button onClick={saveCroppedImage} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ResumeBuilder;
