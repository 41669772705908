// export const BASE_URL = "http://ec2-15-206-231-196.ap-south-1.compute.amazonaws.com/api/v1";
  export const BASE_URL = "https://erpapi.eduskillsfoundation.org";
// export const BASE_URL = "https://eduskillsfoundation.org/talent-connect";
// export const BASE_URL = "http://192.168.1.4:8000";
//  export const BASE_URL = "http://192.168.0.136:8000";
//  export const BASE_URL = "http://127.0.0.1:8000";
//..............BASE_URL1
// export const BASE_URL = "http://sm.eduskills.academy/api";

// export const API_URLS1 = {
//   LOGIN: "/auth",
//   INSTRUCTION_DATA: "/assessment",
// };

export const API_URLS = {
  //...........BASE_URL1
  // LOGIN: "/assessment/sm/auth",
  // INSTRUCTION_DATA: "/assessment",
  //   // auth..............................................
  LOGIN: "/token/",
  LOGOUT: "/token/logout",
  SEND_OTP: "/token/send/otp",
  VERIFY_OTP: "/token/verify/otp2",
  REFRESH: "/token/refresh",
  ROLES: "/user/roles",
  //   SWITCH_ROLE: "/user/switch/role/",
  //   // dashboard.........................................
  ASSESSMENT_QUES: "/assessment/question/",
  ASSESSMENT_SUBMIT: "/assessment/submit/",
  INSTRUCTION_DATA: "/assessment/",
  //..............................
  APPLYING_JOB: "/talent/connect/student/jobs/",
  JOB_DETAILS: "/talent/connect/student/jobs/",
  INITIAL_DATA: "/talent/connect/student/resume/",
  RESUME_SUBMIT: "/talent/connect/student/resume/",
  JOB_SUBMIT: "/talent/connect/student/apply/jobVersion2",
  APPLIED_JOB: "/talent/connect/student/applied-jobs/",
  APPLIED_JOB_DETAILS: "/talent/connect/student/jobs/",
  //.............................
  // Profile
  PROFILE_DATA: "/profile/",
  PROFILE_UPDATE: "/profile/update/",
  GET_STATES: "/profile/state/",
  GET_PROGRAM: "/profile/program/",
  GET_INSTITUTE: "/profile/institute/",
  GET_BRANCH: "/profile/branch/",
  //..............register using otp,email,institute
  //VERIFY_OTP_PROFILE: "/profile/verify/otp/",
  //for testing

  //INTERNSHIPS
  COMPLETED_INTERNSHIPS: "/internship/completed-internships/",
  ONGOING_INTERNSHIPS: "/internship/ongoing-internships/",
  COURSES: "/internship/courseDropdown",
  BRANCHES: "/internship/branchDropdown",
  INTERNSHIPS_DETAILS: "/internship/getDetails/",
  GET_CERTIFICATE: "/internship/getLongTermCertificate",
  GET_SHORT_CERTIFICATE: "/internship/getShortTermCertificate",
  GET_INTERNSHIP_DOMAIN: "/domain/course",
  DOMAIN_CHOOSE: "/domain/domainChoose",
};
