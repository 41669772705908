import { initializeApp } from "firebase/app";
import { getMessaging, getToken,onMessage } from "firebase/messaging";


// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyDbqSrDeKpHNL1a5s3F1VPyJ7V3R8Mbpwc",
  authDomain: "eduskills-notification.firebaseapp.com",
  projectId: "eduskills-notification",
  storageBucket: "eduskills-notification.firebasestorage.app",
  messagingSenderId: "132961005229",
  appId: "1:132961005229:web:796e73ec45af5748086024",
  measurementId: "G-79VWTQEY37"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Request Notification Permission & Get FCM Token
export const requestFCMToken = async () => {
  try {
    console.log("Requesting notification permission...");
    const permission = await Notification.requestPermission();

    if (permission !== "granted") {
      console.log("Notification permission denied.");
      return null;
    }

    console.log("Notification permission granted.");
    
    // Get FCM Token
    const fcmToken = await getToken(messaging, { vapidKey: "BAQ1GleAgTDUf61uxsNVCTlzQ-Ro4RVtJZxPwXUBZqZJ0x6edPjGNxGyVICJrAAEOHXiG4N1mb3iG4oYuLx7c_A" });
    
    if (fcmToken) {
      console.log("FCM Token:", fcmToken);
      return fcmToken;
    } else {
      console.log("No registration token available.");
      return null;
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
    return null;
  }
};

// Foreground Notification Handling
onMessage(messaging, (payload) => {
  console.log("Received foreground message:", payload);
  new Notification(payload.notification.title, {
      body: payload.notification.body,
      // icon: "./logo192.png",
  });
});
