import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Drawer,
  useTheme,
  // Button,
  IconButton, Button,
  Card, CardContent
  // Tooltip,
} from "@mui/material";
import { QrCode, OpenInNew,  } from "@mui/icons-material";
import { ChevronRight, Scan, ArrowUpRight } from 'lucide-react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import { MenuItems } from "./navConfig";
import LogoDark from "../assets/imgs/logo-dark.svg";
import LogoLight from "../assets/imgs/logo-light.svg";
import Questions from "../components/sidebar/Questions";
import { profileGetDataService } from "../services/dataService";
// import { Icon } from "@iconify/react";
// import { useSelector } from "react-redux";

const Item = ({
  title,
  to,
  icon,
  selected,
  setSelected,
  isMobileView,
  setToggleDrawer,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSelection = () => {
    setSelected(title);
    if (isMobileView) {
      setToggleDrawer(false);
    }
  };
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={handleSelection}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ toggleDrawer, setToggleDrawer }) => {
  const menuItems = MenuItems();
  // console.log("menuitems pratyuzti",menuItems)
  const location = useLocation();
  const currentPath = location.pathname;
  console.log("current path", currentPath);
  const navigate = useNavigate();
  // const isActiveRole = useSelector((state) => state.authorise.userRole);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth < theme.breakpoints.values.lg
  );
  const [profileUpdateStatus, setProfileUpdateStatus] = useState(1);

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await profileGetDataService.profileData(); // Fetch the data from your service
        const data = response?.data;

        

        setProfileUpdateStatus(data?.profile_update_status);
      } catch (error) {
        console.error("Failed to fetch profile data:", error);
      }
    };

    fetchProfileData();
  }, []);
  useEffect(() => {
    const selectedItem = menuItems.find((nav) => nav.path === currentPath);
    console.log("select item", selectedItem);
  }, [currentPath, menuItems]);

  // useEffect(() => {
  //   // Find the current child item matching the path
  //   const selectedChild = menuItems.find((nav) => nav?.children);
  //   console.log("selected children", selectedChild?.children);
  //   const selectedChildItem = selectedChild?.children.find(
  //     (nav) => nav.path === currentPath
  //   );
  //   console.log("selected child item ==>", selectedChildItem);
  //   if (selectedChildItem) {
  //     setSelected(selectedChildItem?.title);
  //   }
  // }, [currentPath, menuItems]);
  useEffect(() => {
    if (!menuItems || !Array.isArray(menuItems)) {
      console.warn("menuItems is undefined or not an array:", menuItems);
      return;
    }
  
    // console.log("Current Path:", currentPath);
    // console.log("Available menu items:", menuItems);
    // console.log("Available menu paths:", menuItems.map(item => item.path));
    // console.log("Available menu titles:", menuItems.map(item => item.title));
  
    // Find standalone menu item (match by path first, then by title)
    const selectedItem = menuItems.find((nav) => 
      nav.path === currentPath || 
      nav.title.toLowerCase() === currentPath.toLowerCase()
    );
  
    if (selectedItem) {
      setSelected(selectedItem.title);
      // console.log("✅ Selected standalone item:", selectedItem.title);
    } else {
      // Check inside children if standalone item is not found
      const selectedParent = menuItems.find((nav) => 
        nav?.children?.some(child => 
          child.path === currentPath || 
          child.title.toLowerCase() === currentPath.toLowerCase()
        )
      );
  
      // console.log("🔍 Selected parent with children:", selectedParent?.title, selectedParent?.children);
  
      const selectedChildItem = selectedParent?.children?.find(child => 
        child.path === currentPath || 
        child.title.toLowerCase() === currentPath.toLowerCase()
      );
  
      // console.log("✅ Selected child item:", selectedChildItem);
  
      if (selectedChildItem) {
        setSelected(selectedChildItem.title);
      }
    }
  }, [currentPath, menuItems]);
  

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < theme.breakpoints.values.lg);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [theme.breakpoints.values.lg]);

  const SidebarContent =
    profileUpdateStatus !== null && profileUpdateStatus === 1 ? (
      <Box
        sx={{
          "& .pro-sidebar-inner": {
            background: `${colors.primary[400]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
          },
          "& .pro-inner-item": {
            p: "8px 22px !important",
          },
          "& .pro-inner-item:hover": {
            color: `${colors.blueAccent[400]} !important`,
            borderLeft: `2px solid ${colors.blueAccent[400]}`,
            transition: "width 0.3s ease-out, background-color 0.3s ease-out",
          },
          "& .pro-menu-item.active": {
            color: `${colors.blueAccent[400]} !important`,
            borderLeft: `2px solid ${colors.blueAccent[400]}`,
          },

          "& .logo": {
            display: "flex",
            justifyContent: "center",
            position: "sticky",
            top: 0,
            zIndex: 100,
            bgcolor: colors.primary[400],
          },
          "& .logo img": {
            height: "70px",
            padding: "16px 3px 16px 16px",
            transition: "width 0.3s ease-out",
          },
          bgcolor: `${colors.primary[400]} !important`,
          height: "100vh",
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.down("lg")]: {
              display: "block",
            },
            height: "100%",
            background: `${colors.primary[400]} !important`,
          }}
        >
          <ProSidebar style={{ position: "relative" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // flexDirection:'column'

              }}
            >
              <Box className="logo">
                <img
                  src={theme.palette.mode === "dark" ? LogoLight : LogoDark}
                  alt="Logo"
                />
              </Box>
              <Box sx={{paddingBottom:'10px', borderRadius:3, padding:'4px 8px',background:'blue' }}>
                <Typography sx={{fontWeight:'bold',fontSize:'9px',color:'#fff',}}>V1.0</Typography>
              </Box>
            </Box>
            <Menu iconShape="square" style={{ padding: 0, height: "100%" }}>
              {location.pathname === "/assement" ? (
                <Questions />
              ) : (
                <div>
                  {menuItems?.map((nav, idx) => (
                    <div key={idx}>
                      {nav?.children ? (
                        <SubMenu title={nav.title} icon={nav.icon}>
                          {nav?.children?.map((child, childIdx) => (
                            <Item
                              key={childIdx}
                              title={child.title}
                              to={child.path}
                              icon={child.icon}
                              selected={selected}
                              setSelected={setSelected}
                              setToggleDrawer={setToggleDrawer}
                              isMobileView={isMobileView}
                              isDisabled={false} // Enabled items
                            />
                          ))}
                        </SubMenu>
                      ) : (
                        <Item
                          title={nav.title}
                          to={nav.path}
                          icon={nav.icon}
                          selected={selected}
                          setSelected={setSelected}
                          setToggleDrawer={setToggleDrawer}
                          isMobileView={isMobileView}
                          isDisabled={false} // Enabled items
                        />
                      )}
                    </div>
                  ))}
                </div>
              )}
       
            </Menu>
            
          </ProSidebar>
          
        </Box>
       

      </Box>
    ) : (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: `${colors.primary[400]} !important`,
          color: colors.grey[100],
        }}
      >
        <Typography variant="h6">
        </Typography>
      </Box>
    );

  return (
    <Box>
      {isMobileView ? (
        <Drawer
          variant="temporary"
          anchor="left"
          open={toggleDrawer}
          onClose={() => setToggleDrawer(false)}
        >
          {SidebarContent}
        </Drawer>
      ) : (
        SidebarContent
      )}
    </Box>
  );
};

export default Sidebar;
<Card
sx={{
  width: 240,
  borderRadius: 3,
  overflow: "hidden",
  position: "relative",
  color: "black",
  // boxShadow: 6,
  display: "flex",
  justifyContent: "center",
  backgroundColor:' #eee',
  margin: "0 auto",
  p: 1,
  marginBottom: "30px",
}}
>
{/* ✅ Fix: Wrap content inside a Box with the gradient background */}
<Box
  sx={{
    width: "100%",
    height: "100%",
    // backgroundImage: "linear-gradient(to bottom right, #4F46E5, #9333EA, #EC4899)",
    backgroundSize: "cover",
    backgroundColor:'#eee',
    backgroundRepeat: "no-repeat",

    p: 2,
    borderRadius: 3, // Ensures background follows Card shape
  }}
>
  <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
    {/* Card Header */}
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <IconButton sx={{ color: "white", bgcolor: "rgba(255,255,255,0.2)", p: 1, borderRadius: 2 }}>
        <QrCode />
      </IconButton>
      <Typography variant="h6" fontWeight="bold">Connect with Us</Typography>
    </Box>

    {/* QR Code Container */}
    <Box
      sx={{
        mt: 2,
        p: 1,
        bgcolor: "white",
        borderRadius: 2,
        boxShadow: 3,
        transition: "transform 0.2s",
        "&:hover": { transform: "scale(1.05)" }
      }}
    >
      <img
        src="https://via.placeholder.com/100" // Replace with your QR code
        alt="QR Code"
        width={100}
        height={100}
      />
    </Box>

    {/* Description */}
    <Typography variant="body2" sx={{ mt: 1, opacity: 0.8, textAlign: "center" }}>
      Scan to visit our awesome app
    </Typography>

    {/* Button */}
    <Button
      variant="contained"
      sx={{
        mt: 2,
        // background: "linear-gradient(to right, #EC4899,#7ea8f1)",
        backgroundColor:'#7ea8f1',
        color: "white",
        px: 3,
        "&:hover": { opacity: 0.9 }
      }}
      endIcon={<ChevronRight />}
    >
      Open manually
    </Button>

    {/* Dots */}
    <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
      {[...Array(5)].map((_, i) => (
        <Box key={i} sx={{
          width: 6, height: 6, borderRadius: "50%", bgcolor: "white", opacity: 0.6,
          animation: i % 2 === 0 ? "pulse 1.5s infinite" : "none"
        }} />
      ))}
    </Box>
  </CardContent>
</Box>
</Card>