// import React, { useEffect, useState } from "react";
// import Paper from "@mui/material/Paper";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
// import TableRow from "@mui/material/TableRow";
// import { tokens } from "../../theme";
// import { Box, Button, Typography, useTheme } from "@mui/material";
// import { Helmet } from "react-helmet-async";
// import Skeleton from "@mui/material/Skeleton";
// import { useNavigate } from "react-router-dom";
// import { Icon } from "@iconify/react";
// // import CustomError from "../../components/Error/CustomError";
// import { applyingJobService } from "../../services/dataService";

// const formatDate = (dateString) => {
//   const date = new Date(dateString);

//   // Get day with suffix (1st, 2nd, 3rd, etc.)
//   const day = date.getDate();
//   const suffix = ["th", "st", "nd", "rd"][
//     day % 10 > 3 ? 0 : (day % 100) - (20 % 10) || day % 10
//   ];

//   // Get month name
//   const months = [
//     "January",
//     "February",
//     "March",
//     "April",
//     "May",
//     "June",
//     "July",
//     "August",
//     "September",
//     "October",
//     "November",
//     "December",
//   ];

//   return `${day} ${months[date.getMonth()]} ${date.getFullYear()}`;
// };
// const columns = [
//   // { id: "sl_no", label: "Sl", minWidth: 20 },
//   { id: "company", label: "Company Name", minWidth: 100 },
//   { id: "job_title", label: "Job Title", minWidth: 150 },

//   // { id: "job_role", label: "Job Role", minWidth: 150 },
//   { id: "no_of_post", label: "No.posts", minWidth: 20 },
//   // { id: "reg_start", label: "PublishDate", minWidth: 20 },
//   { id: "reg_end", label: "Last Date", minWidth: 30 },
//   { id: "action", label: "Action", minWidth: 30 },
// ];
// const createData = (
//   sl_no,
//   job_title,
//   company,
//   job_role,
//   job_id,
//   no_of_post,
//   reg_start,
//   reg_end
// ) => {
//   return {
//     sl_no,
//     job_title,
//     company,
//     job_role,
//     job_id,
//     no_of_post,
//     reg_start,
//     reg_end: formatDate(reg_end),
//     action: "View",
//   };
// };

// const OpeningJobs = () => {
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);
//   const [searchQuery, setSearchQuery] = useState("");
//   const [jobsData, setJobsData] = useState();
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [filteredData, setFilteredData] = useState([]);
//   // const [selectedRowId, setSelectedRowId] = useState(null);
//   const navigate = useNavigate();
//   //....................
//   useEffect(() => {
//     // Simulating data fetching delay
//     const fetchData = async () => {
//       try {
//         // Simulating API call or data fetching
//         setLoading(true);
//         const response = await applyingJobService.jobApply();
//         console.log(response?.data);
//         const apiJobsData = response.data;
//         const formattedJobsData = apiJobsData.data.map((job) =>
//           createData(
//             job.sl_no,
//             job.job_title,
//             job.company,
//             job.job_role,
//             job.job_id,
//             job.no_of_post,
//             job.reg_start,
//             job.reg_end
//           )
//         );
//         //api call ending
//         setLoading(false);
//         // await new Promise((resolve) => setTimeout(resolve, 1500));
//         // console.log(formattedJobsData?.length, "here is length");
//         setJobsData(formattedJobsData);
//         setFilteredData(formattedJobsData);
//         // setJobsData(jobsData);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         setError("Failed to fetch data. Please try again.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };
//   useEffect(() => {
//     // This effect will be triggered whenever searchQuery changes
//     const debounceTimer = setTimeout(() => {
//       // Perform filtering logic after 3 seconds of inactivity
//       const filtered = jobsData?.filter((row) => {
//         return (
//           row.job_title.toLowerCase().includes(searchQuery) ||
//           row.company.toLowerCase().includes(searchQuery) ||
//           row.job_role.toLowerCase().includes(searchQuery)
//         );
//       });
//       setFilteredData(filtered || []);
//       // setJobsData(filteredData);
//       setPage(0);
//     }, 3000);

//     // Cleanup function to clear the timer on each searchQuery change
//     return () => clearTimeout(debounceTimer);
//   }, [searchQuery]);

//   const handleSearch = (event) => {
//     const query = event.target.value.toLowerCase();
//     setSearchQuery(query);
//   };
//   const handleApply = (rowData) => {
//     // setSelectedRowId(rowData.id);
//     navigate("/jobs/details", { state: { rowData } });
//   };
//   const filtereData = jobsData?.filter((row) => {
//     const searchTerms = Object.values(row).join(" ").toLowerCase();
//     return searchTerms.includes(searchQuery.toLowerCase());
//   });

//   const paginationLabelStyle = {
//     fontSize: "1.6rem",
//     fontWeight: "bold",
//   };
//   return (
//     <div className="m-5">
//       <Helmet>
//         <title> EduSkills | Jobs opening </title>
//       </Helmet>
//       <Paper sx={{ width: "100%", overflow: "hidden", height: "100%" }}>
//         {loading ? (
//           <Skeleton
//             animation="wave"
//             height={70}
//             width={200}
//             className=" m-2 ml-5"
//           />
//         ) : (
//           //      error ? (
//           //        <CustomError message={error} />
//           //       ) :
//           <div className="m-2 relative">
//             <input
//               type="text"
//               placeholder="Search..."
//               value={searchQuery}
//               onChange={handleSearch}
//               className="p-2 pl-8 rounded-md w-full md:w-60 shadow-sm outline-sky-300 border-2 border-gray-300 text-gray-900"
//             />
//             <div className="absolute top-0 left-0 m-2">
//               <Icon
//                 icon="material-symbols:search"
//                 height={24}
//                 width={24}
//                 className="text-gray-500"
//               />
//             </div>
//           </div>
//         )}
//         <TableContainer >
//           <Table stickyHeader aria-label="sticky table">
//             <TableHead>
//               {loading ? (
//                 <TableRow>
//                   {columns.map((column) => (
//                     <TableCell key={column.id}>
//                       <Skeleton animation="wave" height={80} />
//                     </TableCell>
//                   ))}
//                 </TableRow>
//               ) : (
//                 <TableRow sx={{ height: "20px" }}>
//                   {columns.map((column) => (
//                     <TableCell
//                       key={column.id}
//                       align={column.align}
//                       sx={{
//                         minWidth: column.minWidth,
//                         // bgcolor: colors.blueAccent[700],
//                       }}
//                     >
//                       <Typography
//                         fontSize="1rem"
//                         fontWeight="bold"
//                         sx={{ paddingRight: "1rem", color: colors.grey[100] }}
//                         component={"span"}
//                         variant={"body2"}
//                       >
//                         {column.label}
//                       </Typography>
//                     </TableCell>
//                   ))}
//                 </TableRow>
//               )}
//             </TableHead>
//             <TableBody >
//               {loading ? (
//                 Array.from({ length: 8 }).map((_, rowIndex) => (
//                   <TableRow key={rowIndex } sx={{height:'50px',
//                     borderRadius:'10px',
//                     margin:'5px'}}>
//                     {columns?.map((column) => (
//                       <TableCell key={column.id}>
//                         <Skeleton animation="wave" height={50} />
//                       </TableCell>
//                     ))}
//                   </TableRow>
//                 ))
//               ) : filtereData?.length === 0 ? (
//                 <TableRow sx={{height:'50px',
//                   borderRadius:'10px',
//                   margin:'5px'}}>
//                   <TableCell colSpan={columns?.length} align="center">
//                     <Typography variant="h6" color="error" component={"span"}>
//                       We don't have any jobs that fit your profile right now.
//                     </Typography>
//                   </TableCell>
//                 </TableRow>
//               ) : (
//                 filtereData
//                   ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                   .map((row, index) => (
//                     <TableRow key={index} sx={{height:'50px',
//                       borderRadius:'10px',
//                       margin:'5px'}}>
//                       {columns.map((column) => (
//                         <TableCell
//                           key={column.id}
//                           align={column.align}
//                           style={{
//                             padding: "7px",
//                             paddingLeft: "10px",
                            
//                           }}
//                         >
//                           <Box sx={{height:'50px',
//                             borderRadius:'10px',
//                             margin:'5px'}}>
//                             {column.id === "action" ? (
//                               <Button
//                                 variant="contained"
//                                 color="success"
//                                 size="small"
//                                 onClick={() => handleApply(row)}
//                               >
//                                 {row.action}
//                               </Button>
//                             ) : column.format &&
//                               typeof row[column.id] === "number" ? (
//                               column.format(row[column.id])
//                             ) : (
//                               <Typography
//                                 fontSize="0.9rem"
//                                 fontWeight="normal"
//                                 component={"span"}
//                                 variant={"body2"}
//                               >
//                                 {row[column.id]?.toString()}
//                               </Typography>
//                             )}
//                           </Box>
//                         </TableCell>
//                       ))}
//                     </TableRow>
//                   ))
//               )}
//             </TableBody>
//           </Table>
//         </TableContainer>
// {/* its for pagination */}
//         {loading ? (
//           <Skeleton
//             animation="wave"
//             height={40}
//             width={350}
//             className=" mt-2 mb-5 ml-1 flex float-end mr-1"
//           />
//         ) : (
//           <TablePagination
//             component="div"
//             rowsPerPageOptions={[5, 10, 15]}
//             count={Array.isArray(jobsData) ? jobsData?.length : 0}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//             classes={paginationLabelStyle}
//             labelDisplayedRows={({ from, to, count }) => (
//               <Typography
//                 style={{ fontSize: "0.9", fontWeight: "bold" }}
//                 component={"span"}
//                 variant={"body2"}
//               >
//                 {from}-{to} of {count}
//               </Typography>
//             )}
//             labelRowsPerPage={
//               <Typography
//                 style={{ fontSize: "0.9rem" }}
//                 component={"span"}
//                 variant={"body2"}
//               >
//                 Rows per page
//               </Typography>
//             }
//             style={{ fontSize: "1rem", fontWeight: "bold" }}
//           />
//         )}
//       </Paper>
//     </div>
//   );
// };

// export default OpeningJobs;
import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  CircularProgress,
  Box,
  Card,
  TablePagination,
  Chip,
  IconButton,
  InputBase,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { applyingJobService } from "../../services/dataService";
import Company_Logo from '../../assets/Talent-connect.png'
import { Visibility, Search, Archive } from "@mui/icons-material";
import ScheduleIcon from '@mui/icons-material/Schedule';

import WorkIcon from "@mui/icons-material/Work";
import api from "../../services/api";

const OpeningJobs = () => {
  const [jobsData, setJobsData] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showArchived, setShowArchived] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Load current jobs by default when component mounts
    fetchCurrentJobs();
  }, []);

  useEffect(() => {
    // Filter jobs based on search term
    if (searchTerm.trim() === "") {
      setFilteredJobs(jobsData);
    } else {
      const filtered = jobsData.filter(
        (job) =>
          job.job_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          job.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
          job.job_id.toString().includes(searchTerm)
      );
      setFilteredJobs(filtered);
    }
  }, [searchTerm, jobsData]);

  const fetchCurrentJobs = async () => {
    try {
      setLoading(true);
      const response = await applyingJobService.jobApply();
      const jobs = response.data?.data || [];
      setJobsData(jobs);
      setFilteredJobs(jobs);
      setShowArchived(false);
    } catch (err) {
      setError("Failed to fetch current jobs.");
    } finally {
      setLoading(false);
    }
  };

  const fetchArchivedJobs = async () => {
    try {
      setLoading(true);
      const response = await api.get("talent/connect/student/archivedJobs");
      const archivedJobs = response.data?.data || [];
      setJobsData(archivedJobs);
      setFilteredJobs(archivedJobs);
      setShowArchived(true);
    } catch (err) {
      setError("Failed to fetch archived jobs.");
    } finally {
      setLoading(false);
    }
  };

  const handleApply = async (rowData) => {
    try {
      setLoading(true);
      const accessToken = localStorage.getItem("accessToken");
      const response = await api.post("/talent/connect/views/"+rowData.job_id.toString(),
        {
          
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
          },
         
        }
      );
      // const archivedJobs = response.data?.data || [];
      // setJobsData(archivedJobs);
      // // setFilteredJobs(archivedJobs);
      // setShowArchived(true);
    } catch (err) {
      // setError("Failed to fetch archived jobs.");
    } finally {
      setLoading(false);
    }
    navigate("/jobs/details", { state: { rowData } });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when searching
  };

  const toggleArchiveView = () => {
    if (showArchived) {
      fetchCurrentJobs();
    } else {
      fetchArchivedJobs();
    }
  };

  return (
    <>
      <Card sx={{ maxWidth: "lg", margin: "auto", background: '#F4F2EE', minHeight: "88vh" }}>
        <Box sx={{display: "flex", justifyContent: "center", mt: 3, gap: 2, marginBottom: '20px' }}>
          <Paper elevation={4} sx={{ width: "85%", maxWidth: 900, p: 3, borderRadius: 3 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
              {/* Search Bar */}
              <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 300 }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search jobs by title, company or ID"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                  <Search />
                </IconButton>
              </Paper>

              {/* Archive Toggle Button */}
              <Box sx={{display: 'flex', alignItems: 'center', }}>
                
                  
                
                <IconButton 
                  onClick={toggleArchiveView}
                  // color={showArchived ? "primary" : "primary"}
                  // title={showArchived ? "Show Current Jobs" : "Show Archived Jobs"}
                  
                >
                  {showArchived ? <WorkIcon />:<Archive /> }
                  
                </IconButton>
              </Box>
            </Box>

            {error && (
              <Typography color="error" textAlign="center">
                {error}
              </Typography>
            )}

            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {/* Job Listings Header */}
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {showArchived ? "Archived Jobs" : "Current Jobs"}
                </Typography>

                {/* Container for Job Listings */}
                {filteredJobs.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {searchTerm.trim() !== "" 
                      ? "No jobs found matching your search." 
                      : showArchived 
                        ? "No archived jobs available." 
                        : "No current jobs available."}
                  </div>
                ) : (
                  filteredJobs
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((job, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px",
                          borderBottom: "1px solid #f1f1f1",
                          backgroundColor: "#fff",
                          marginBottom: "10px",
                          boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                          transition: "all 0.3s ease-in-out",
                        }}
                      >
                        {/* Company Logo */}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            color: "#616161",
                            fontWeight: "bold",
                            fontSize: "14px",
                          }}
                        >
                          <img
                            src={Company_Logo}
                            alt="Company Logo"
                            style={{
                              width: 80,
                              height: 80,
                              objectFit: "contain",
                            }}
                          />
                          <div>
                            <div style={{
                              width: "fit-width",
                              display: "flex",
                              fontWeight: 500,
                              fontSize: "11px",
                              alignItems: 'center',
                              borderRadius: '9px'
                            }}>
                              <div> <WorkIcon fontSize="small" sx={{
                                padding: '0px 2px',
                                borderRadius: '4px',
                                fontSize: '19px'
                              }} /> Job Id: {job.reference}</div>
                            </div>
                            <div style={{ fontWeight: "bold", color: "black", display: "flex", gap: '5px', fontSize: '15px' }}>
                              <div>{job.job_title}</div>
                            </div>

                            <div style={{ color: "#616161", fontWeight: 300, display: "flex", gap: '5px', fontSize: "11px" }}>
                              <div>Company : </div>
                              <div>{job.company}</div>
                            </div>

                            <div style={{ color: "#616161", fontWeight: 300, display: "flex", gap: '5px', fontSize: "10px" }}>
                              <div>Total Openings : </div>
                              <div>{job.no_of_post}</div>
                            </div>
                          </div>
                        </div>

                        {/* View Button and Time Remaining */}
                        <div style={{ display: 'flex', gap: '10px' }}>
                          {/* Time Remaining */}
                          <div style={{ fontWeight: 300, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ fontWeight: 'bold', fontSize: '11px', }}>Time Remaining</div>
                            <div style={{
                              display: 'flex',
                              justifyContent: 'center',
                              padding: '2px 6px',
                              background: '#80e5ff',
                              fontSize: '13px',
                              color: 'white',
                              borderRadius: '10px'
                            }}>
                              <ScheduleIcon
                                sx={{
                                  marginRight: '5px',
                                  fontSize: '21px',
                                  fontWeight: 'bold',
                                  height: '20px',
                                  width: '20px',
                                }}
                              />
                              {job.reg_end ? (
                                (() => {
                                  const currentDate = new Date();
                                  const expiryDate = new Date(job.reg_end);
                                  const timeDifference = expiryDate - currentDate;
                                  const remainingDays = Math.floor(timeDifference / (1000 * 3600 * 24));

                                  if (remainingDays < 0) {
                                    return "Expired"; // or "0 days"
                                  } else if (remainingDays === 0) {
                                    return "0 days";
                                  } else {
                                    return `${remainingDays} days`;
                                  }
                                })()
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => handleApply(job)}
                            sx={{
                              textTransform: "none",
                              fontWeight: "bold",
                              borderRadius: 2,
                              background: !showArchived ? "#22C55E" : "#D32F2F",
                              "&:hover": { backgroundColor: !showArchived ? "#1B9E4B" : "#B71C1C" },
                            }}
                            disabled={showArchived}
                          >
                            {!showArchived ? "View Details" : "Archived"}
                          </Button>
                        </div>
                      </div>
                    ))
                )}

                {/* Pagination */}
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={filteredJobs.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            )}
          </Paper>
        </Box>
      </Card>
    </>
  );
};

export default OpeningJobs;