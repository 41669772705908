import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Typography,
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Modal,
  Dialog, DialogTitle, DialogContent,
} from "@mui/material";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../services/configUrls";
import genai from "../../assets/Google GenAI.pdf";
import { CrossIcon, X } from "lucide-react";
import step1 from '../../assets/screenshot/step1.jpeg'
import step2 from '../../assets/screenshot/step2.jpeg'
import step3 from '../../assets/screenshot/step3.jpeg'
import step4 from '../../assets/screenshot/step4.jpeg'
import step5 from '../../assets/screenshot/step5.jpeg'
import step6 from '../../assets/screenshot/step6.jpeg'
import registration from '../../assets/screenshot/registration Sucessful.jpeg'
import Part2 from '../../assets/screenshot/part2.jpeg'
import step7 from '../../assets/screenshot/step7.jpeg'
import step8 from '../../assets/screenshot/step8.jpeg'
import step9 from '../../assets/screenshot/step9.jpeg'
import step10 from '../../assets/screenshot/step10.jpeg'
import step11 from '../../assets/screenshot/step11.jpeg'
import thankyou from '../../assets/screenshot/thank you.jpeg'
//const API_URL = "http://192.168.1.6:8000";

// Demo data for the modal content
// Demo data for the modal content
// Demo data for the modal content with image URLs from Unsplash
// const domainDetails = {
//   17: {
//     domain_name: "Artificial Intelligence",
//     content: [
//       "Introduction to AI",
//       "Basics of Machine Learning",
//       "Hands-on with Data Science",
//     ],
//     willDo: [
//       "Participate in AI workshops",
//       "Work on projects with industry experts",
//       "Collaborate with peers",
//     ],
//     willGet: [
//       "Certificate of completion",
//       "Opportunity for internships",
//       "Access to premium AI resources",
//     ],
//     images: [
//       "https://images.unsplash.com/photo-1673422672504-757a7a57d24a?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//       "https://images.unsplash.com/photo-1720547014555-539d9d6cd2f5?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//       "https://images.unsplash.com/photo-1720547009638-a510e0fb9c09?q=80&w=1931&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
//     ],
//   },
//   19: {
//     domain_name: "Cloud Computing",
//     content: [
//       "Cloud Computing basics",
//       "Virtualization concepts",
//       "Cloud service providers overview",
//     ],
//     willDo: [
//       "Deploy applications on cloud platforms",
//       "Work with cloud infrastructure",
//       "Build and manage cloud services",
//     ],
//     willGet: [
//       "Cloud certifications",
//       "Hands-on cloud deployment experience",
//       "Networking with industry leaders",
//     ],
//     images: [
//       "https://source.unsplash.com/featured/?cloud-certification",
//       "https://source.unsplash.com/featured/?cloud-computing",
//       "https://source.unsplash.com/featured/?networking",
//     ],
//   },
//   // Add more domains as needed
// };

const CopyDomainChoose = ({preferedDomains}) => {
  const [domain, setDomain] = useState("");
  // const [preferredDomains, setPreferredDomains] = useState([]);
  const [domain1, setDomain1] = useState("");
  const [profile, setProfile] = useState([]);
  const [job, setJob] = useState([]);
  const [edu, setEdu] = useState([]);
  const [optionalDomains, setOptionalDomains] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingDomains, setLoadingDomains] = useState(true);
  const [url, setUrl] = useState("");
  const [showUrlInput, setShowUrlInput] = useState(false);
  const [status, setStatus] = useState(0);
  const [Loading, setLoading] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [modalContent, setModalContent] = useState({
    content: [],
    willDo: [],
    willGet: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eduDetails, setEduDetails] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const token = localStorage.getItem("accessToken");
  const steps = [
    { title: "Registration - Step 1", content: "Click on the link given below and click on Join option.\nLink: https://www.cloudskillsboost.google/", hasScreenshot: true,image:step1},
    { title: "Registration - Step 2", content: "Enter the details:\n1. Enter First Name\n2. Enter Last Name\n3. Enter Email ID\n4. Enter Company name\n5. Enter Password\n6. Confirm Password\n7. Check the Check box to confirm\nI'm not a robot\nClick on \"Create Account\" button.", hasScreenshot: true,image:step2 },
    { title: "Registration - Step 3", hasScreenshot: true,image:step3 },
    { title: "Registration - Step 4", hasScreenshot: true,image:step4 },
    { title: "Registration - Step 5",  hasScreenshot: true,image:step5 },
    { title: "Registration - Step 6",  hasScreenshot: true,image:step6 },
    { title: "Registration ", hasScreenshot: true,image:registration },
    { title: "Public Profile Link ", hasScreenshot: true ,image:Part2},
    { title: "Public Profile Link - Step 7",  hasScreenshot: true,image:step7 },
    { title: "Public Profile Link - Step 8",  hasScreenshot: true,image:step8 },
    { title: "Public Profile Link - Step 9", hasScreenshot: true,image:step9 },
    { title: "Public Profile Link - Step 10",  hasScreenshot: true,image:step10 },
    { title: "Public Profile Link - Step 11",  hasScreenshot: true,image:step11 },
    { title: "Thank You", hasScreenshot: true,image:thankyou }
];


  if (!token) {
    window.location.href = "https://eduskillsfoundation.org/login";
  }

  // Fetch both preferred and optional domains
  useEffect(() => {
    const fetchDomains = async () => {
      try {
        // Fetch Preferred Domains
        // const preferredResponse = await axios.get(
        //   `${BASE_URL}/domain/domainChoose`,
        //   {
        //     headers: {
        //       Authorization: `Bearer ${token}`,
        //     },
        //   }
        // );
        //  console.log("Fetched Preferred Domains:", preferedDomains); 
        // setPreferredDomains(preferredResponse.data[1]);  
        // setPreferredDomains(preferredResponse.data.result_domains);
           
        // setProfile(preferredResponse.data[1]);
        setProfile(preferedDomains.data?.result_domains);
        setDomain1(preferedDomains);
        // setEdu(preferredResponse.data[1]);
        setEdu(preferedDomains.data?.result_domains);
        // setJob(preferredResponse.data[2]);
        setJob(preferedDomains.data?.edu_deatils);
        setStatus(preferedDomains.data?.is_status);
        // setEduDetails(preferredResponse?.data[2]);
        setEduDetails(preferedDomains?.data?.edu_deatils);
        // console.log("After setPreferredDomains:", preferedDomains);
    

        // Fetch Optional Domains
        const optionalResponse = await axios.get(
          `${BASE_URL}/domain/optionalDomains`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setOptionalDomains(optionalResponse.data);
        // console.log(optionalDomains);
      } catch (error) {
        // console.error("Error fetching domains:", error);
        toast.error("An Error Occurred", {
          position: toast.POSITION.TOP_CENTER,
        });
      } finally {
        setLoadingDomains(false);
      }
    };

    fetchDomains();
  }, [token]);

  const googleGenAiUrlRegex =
    /^https:\/\/www\.cloudskillsboost\.google\/public_profiles\//;

  // Handle form submission
  const handleSubmit = async () => {
    if (!domain) {
      toast.error("Please select a domain", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // if (domain === "32"&& domain === "16" && !googleGenAiUrlRegex.test(url)) {
    const domainNumbers = ["32","38"]
    if (domainNumbers.includes(domain) && url==="") {
       alert("Please provide valid domain url")
      // toast.error("Invalid Google Gen AI URL", {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/domain/domainSubmit`,
        {
          domain_id: domain,
          url: url,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success(response.data[0], {
        position: toast.POSITION.TOP_RIGHT,
      });
      window.location.reload();
      setDomain("");
      setUrl("");
      setShowUrlInput(false);
    } catch (error) {
      // console.error("Error submitting domain:", error);
      toast.error("Failed to submit domain", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Function to find domain name based on domain_id
  const findDomainName = (domainId) => {
    const preferredDomain = preferedDomains.find(
      (item) => item.domain_id === domainId
    );
    if (preferredDomain) return preferredDomain.domain_name;

    const optionalDomain = optionalDomains.find(
      (item) => item.domain_id === domainId
    );
    if (optionalDomain) return optionalDomain.domain_name;

    return "Domain Details";
  };

  // Open modal with content based on selected domain
  const handleKnowMoreOpenModal = async (domainId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/domain/courseOutline/${domainId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Check if response data is an array and has at least one element
      if (Array.isArray(response.data) && response.data.length > 0) {
        const { lab_content, assessment, outcome_of_course } = response.data[0];

        setModalContent({
          content: lab_content || [],
          willDo: assessment || [],
          willGet: outcome_of_course || [],
          domain_name: findDomainName(domainId),
        });
        setOpenModal(true);
      } else {
        toast.error("No content available for this domain", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      // console.error("Error fetching course outline:", error);
      toast.error("Failed to fetch domain details", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  // Handle domain selection change
  const handleDomainChange = (event) => {
    const selectedDomain = event.target.value;
    setDomain(selectedDomain);
   
    setShowUrlInput(selectedDomain === "32" || selectedDomain === "38");
    if (selectedDomain !== "32" || selectedDomain !== "38") {
      setUrl("");
     // Show popup
    
    }
    if (selectedDomain === "32" || selectedDomain === "38") {
      setOpenModal1(true);
     // Show popup
    
    }
    else{
      setOpenModal1(false);
    }
    
    
  };

  // Function to open the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
    
    setCurrentStep(0);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setOpenModal1(false);
  };
  const nextStep = () => {
    if (currentStep < steps.length - 1) {
        setCurrentStep((prev) => prev + 1);
    }
};

const prevStep = () => {
    if (currentStep > 0) {
        setCurrentStep((prev) => prev - 1);
    }
};
  const shouldShowCohortMessage =
    // !loadingDomains &&
    // preferedDomains?.data.result_domains.length === 0 &&
    // optionalDomains.length === 0 &&
    !loadingDomains &&
    (preferedDomains?.data?.result_domains?.length ?? 0) === 0 &&
    (optionalDomains?.length ?? 0) === 0 &&
    // (Array.isArray(preferredDomains) ? preferredDomains.length : 0) === 0 &&
    // (Array.isArray(optionalDomains) ? optionalDomains.length : 0) === 0 &&
    status === "0";

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius={2}
      
    
    >
      {/* <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        boxShadow={3}
        // padding={4}
        // boxShadow={3}
        borderRadius={2}
        sx={{ backgroundColor: '#eee'}}
        width={{ xs: "100%", sm: "1200px" }} // Adjust width as per your needs
      > */}
        {/* <Typography variant="h5" gutterBottom>
          Domain Selection
        </Typography> */}

        {status === "1" && (
          <div className="flex items-center justify-center  " style={{width:'98%',backgroundColor:'#eee',boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)' ,borderRadius:'12px'}}>
            <div className="max-w-4xl w-full bg-white rounded-lg p-6 text-center" style={{backgroundColor:'#eee'}}>
              <h3 className="text-2xl font-bold text-gray-600 mb-1" style={{fontSize:'16px'}}>
                Successfully applied for {preferedDomains.data.domain_name}
              </h3>
              <p className="text-gray-600 text-left">
                Application has been sent to your institute for approval.
              </p>
              <p className="text-gray-600 text-left">
                Now, Kindly inform Your SPOC to approve your application within 7 day timeframe, otherwise it will be automatically rejected.
              </p>
              <p className="text-gray-600 text-left">Please contact to your SPOC using below details</p> 
              <p  className="text-gray-600  text-left "> Name : <b>{eduDetails[0]}</b></p>
              <p  className="text-gray-600  text-left "> Email : <b>{eduDetails[1]}</b></p>
              <p  className="text-gray-600  text-left "> Mobile : <b>{eduDetails[2]}</b></p>
            </div>
          </div>
        )}

        {status === "7" && (
          <div className="flex items-center justify-center  " style={{width:'98%',backgroundColor:'#eee',boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)' ,borderRadius:'12px'}}>
            <div className="max-w-4xl w-full bg-white rounded-lg p-6 text-center" style={{backgroundColor:'#eee'}}>
              <h3 className="text-2xl font-bold text-gray-600 mb-1" style={{fontSize:'16px'}}>
              Successfully applied for the AICTE - EduSkills Virtual Internship.
              </h3>
              <p className="text-gray-600 text-center">
              Application has been sent for approval
              </p>
            </div>
          </div>
        )}
          {status === "8" && domain1?.data?.message && (
          <div className="flex items-center justify-center  " style={{width:'98%',backgroundColor:'#eee',boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)' ,borderRadius:'12px'}}>
          <div className="max-w-4xl w-full bg-white rounded-lg p-6 text-center" style={{backgroundColor:'#eee'}}>
          <h3 className="text-2xl font-bold text-gray-600 mb-1" style={{fontSize:'16px'}}>
            {domain1.data.message.replace(/http\S+/g, "").trim()}
            </h3>
            {(() => {
              const words = domain1.data.message.split(" ");
              const link = words.find((word) => word.startsWith("http"));
              return (
                link? <button
                  onClick={() => link && window.open(link, "_blank")}
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                >
                  Membership Portal
                </button>:<div></div>
              );
            })()}
          </div>
        </div>
)}

{/* {status === "2" && (
          <Typography color="red" variant="body1" gutterBottom>
            Your application has been rejected. Please contact your SPOC.
          </Typography>
        )} */}

        {status === "3" && (
          // <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
          //   <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg p-6 text-center">
          //     <h1 className="text-2xl font-bold text-gray-800 mb-4">
          //       The internship is not opened to your institution
          //     </h1>
          //     <p className="text-gray-600 text-xl mb-4">
          //       Please contact to your Faculty.
          //     </p>
          //   </div>
          // </div>
          <div className="flex items-center justify-center  " style={{width:'98%',backgroundColor:'#eee',boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)' ,borderRadius:'12px'}}>
          <div className="max-w-4xl w-full bg-white rounded-lg p-6 text-center" style={{backgroundColor:'#eee'}}>
          {/* <div className="flex items-center justify-center  " style={{width:'98%',borderRadius:'12px'}}>
          <div className="max-w-4xl w-full bg-white rounded-lg p-6 text-center" > */}
            <h3 className="text-2xl font-bold text-gray-600 mb-1" style={{fontSize:'16px'}}>
            The internship is not opened to your institution, Please contact to your Faculty.
            </h3>
          </div>
        </div>
        // </div>
        // </div>
        )}

        {/* {status === "5" && (
          <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
            <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg p-6 text-center">
              <h1 className="text-lg text-left text-gray-800 mb-4">
                <span className="font-bold">
                  Congratulations... on completing your {profile} {job} !
                </span>
                <br></br>
                <br></br> You are now an esteemed alumni of EduSkills. We are
                proud of your accomplishments and wish you continued success in
                your career.<br></br>
                <br></br> If you are interested in furthering your education and
                expanding your skillset, we encourage you to explore the various
                courses and opportunities that EduSkills has to offer.
              </h1>
            </div>
          </div>
        )} */}
        {/* {status === "6" && (
          <div className="flex items-center justify-center px-8 py-8 bg-gray-100">
            <div className="max-w-4xl w-full bg-white shadow-lg rounded-lg p-6 text-center">
              <h1 className="text-lg text-left text-gray-800 mb-4">
                We regret to inform you that we have not received your Email ID
                from your College/University for the Cohort-10 Internship
                program.
                <br></br>
                <br></br>We kindly request that you follow up with your College
                / University SPOC to ensure that the necessary information is
                provided to us as soon as possible :<br></br>
                <br></br>
                {edu[0]}
                <br></br>
                {edu[1]}
                <br></br>
                {edu[2]}
                <br></br>
                <br></br> OR else<br></br> <br></br> You can obtain an EduSkills
                Membership for further Process.
              </h1>
            </div>
          </div>
        )} */}
        {/* <Box  display="flex"
      flexDirection="column"> */}
        {status === "0" && (
         
           <div className="flex items-center justify-center  " style={{width:'98%',display:'flex',flexDirection:'column' ,borderRadius:'12px'}}>
            {shouldShowCohortMessage ? (
               <div className="flex items-center justify-center  " style={{width:'98%',backgroundColor:'#eee',boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)' ,borderRadius:'12px'}}>
              <div className="max-w-4xl w-full bg-white rounded-lg p-6 text-center" style={{backgroundColor:'#eee'}}> 
                <h3 className="text-2xl font-bold text-gray-600 mb-1" style={{fontSize:'16px'}}>
                No Domains Available
                </h3>
              </div>
            </div>
            ) : (
             
              <FormControl component="fieldset" fullWidth margin="normal"  sx={{width:'100%' ,backgroundColor:'#eee',boxShadow:'0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12)' ,borderRadius:'12px'}}>
                <Grid container spacing={2}  >
                  {/* Left Column - Preferred Domains */}
                  <Grid item xs={12} sm={12}>
                    <Typography
                      textAlign={"center"}
                      bgcolor={"#e2e2e2"}
                      marginBottom={2}
                      variant="h6"
                      width='100%'
                      sx={{textAlign: "center",
                        fontWeight: "bold",
                        padding: "8px",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                        // borderRadius:0
                        }}
                    >
                      Preferred Domains
                    </Typography>
                    <RadioGroup
                      aria-label="preferred-domain"
                      name="preferred-domain"
                      value={domain}
                      onChange={handleDomainChange}
                    >
                      {loadingDomains ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          marginTop={2}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        preferedDomains?.data.result_domains.map((domainItem) => (
                          <div
                            key={domainItem.domain_id}
                            className="flex items-center justify-left"
                            style={{paddingLeft:'20px'}}
                          >
                            <FormControlLabel
                              sx={{ marginBottom: "10px" }}
                              value={domainItem.domain_id.toString()}
                              control={
                                <Radio
                                  sx={{ padding: 0.5, fontSize: "0.8rem" }}
                                />
                              }
                              label={domainItem.domain_name}
                            />
                            <Button
                              variant="outlined"
                              size="small"
                              color="info"
                              className="text-blue-500 hover:text-blue-700"
                              sx={{ marginBottom: "15px" }}
                              onClick={() =>
                                handleKnowMoreOpenModal(domainItem.domain_id)
                              }
                            >
                              Know More
                            </Button>
                          </div>
                        ))
                      )}
                    </RadioGroup>
                  </Grid>

                  {/* Right Column - Optional Domains */}
                  <Grid item xs={12} sm={12}>
                    <Typography
                      textAlign={"center"}
                      bgcolor={"#e2e2e2"}
                      marginBottom={2}
                      variant="h6"
                      sx={{textAlign: "center",
                        fontWeight: "bold",
                        padding: "8px",
                        // borderTopLeftRadius: "8px",
                        // borderTopRightRadius: "8px",
                        // borderRadius:0
                        }}
                    >
                      Optional Domains
                    </Typography>
                    <RadioGroup
                      aria-label="optional-domain"
                      name="optional-domain"
                      value={domain}
                      onChange={handleDomainChange}
                    >
                      {loadingDomains ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          marginTop={2}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        optionalDomains.map((domainItem) => (
                          <div
                            key={domainItem.domain_id}
                            className="flex items-center justify-left"
                            style={{paddingLeft:'20px'}}
                          >
                            <FormControlLabel
                              value={domainItem.domain_id.toString()}
                              sx={{ marginBottom: "15px" }}
                              control={
                                <Radio
                                  sx={{ padding: 0.5, fontSize: "0.8rem" }}
                                />
                              }
                              label={domainItem.domain_name}
                            />
                            <Button
                              variant="outlined"
                              size="small"
                              color="info"
                              className="text-blue-500 hover:text-blue-700"
                              sx={{ marginBottom: "15px" }}
                              onClick={() =>
                                handleKnowMoreOpenModal(domainItem.domain_id)
                              }
                            >
                              Know More
                            </Button>
                          </div>
                        ))
                      )}
                    </RadioGroup>
                  </Grid>
                </Grid>

              </FormControl>
            
            )}

            {showUrlInput && (
                <>
                    <TextField
                        fullWidth
                        label="Google Gen AI URL"
                        variant="outlined"
                        margin="normal"
                        value={url}
                        onChange={(e) => setUrl(e.target.value)}
                        placeholder="Enter Google Gen AI URL"
                    />
                    <div className="mt-2">
                        <button
                            onClick={handleOpenModal}
                            className="inline-flex items-center bg-red-600 text-white text-sm py-2 px-4 rounded-lg hover:bg-red-700 transition duration-300"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5 mr-2"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414a2 2 0 00-.586-1.414l-4-4A2 2 0 009.586 2H6zM5 4a1 1 0 011-1h3v4a1 1 0 001 1h4v10a1 1 0 01-1 1H6a1 1 0 01-1-1V4zm7 0l3 3h-3V4z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            How to get the URL
                        </button>
                    </div>
                </>
            )}
             <Dialog open={openModal1} onClose={handleCloseModal} fullWidth maxWidth="sm">
                <DialogTitle>{steps[currentStep].title}
                <Button
              onClick={handleCloseModal}
              style={{ position: "absolute", top: 10, right: 10 }}
              size="small"
            >
              Close
            </Button>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
                        {steps[currentStep].content}
                    </Typography>

                    {steps[currentStep].hasScreenshot && (
                      <div style={{ marginTop: 10 }}>
                        <img src={steps[currentStep].image} alt="Step Screenshot" width="100%" />
                      </div>
                    )}
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20 }}>
                        <Button variant="contained" onClick={prevStep} disabled={currentStep === 0}>
                            Previous
                        </Button>
                        <Button variant="contained" onClick={nextStep} disabled={currentStep === steps.length - 1}>
                            Next
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>

            {!shouldShowCohortMessage && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isLoading || loadingDomains}
                fullWidth
                size="large"
                sx={{ marginTop: 2 }}
              >
                {isLoading ? <CircularProgress size={24} /> : "Submit"}
              </Button>
            )}
             </div>
          
        )}
        {/* </Box> */}
        {/* Modal for Domain Details */}
        {/* Modal for Domain Details */}
        {/* Modal for Domain Details */}
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box
            sx={{
              bgcolor: "background.paper",
              p: 4,
              borderRadius: 2,
              boxShadow: 24,
              maxWidth: { xs: "90%", sm: "75%", md: "60%", lg: "50%" },
              width: "100%",
              mx: "auto",
              position: "relative",
              top: { xs: "40%", sm: "50%" }, // Adjust vertical positioning based on screen size
              transform: "translateY(-50%)", // Center vertically by shifting up
            }}
          >
            {/* Close Button - Fixed at Top Right */}
            <Button
              onClick={() => setOpenModal(false)}
              sx={{
                position: "absolute",

                top: "3px",
                right: "3px",
                minWidth: "20px",
                minHeight: "20px",
                padding: "4px",
                color: "#000",
                bgcolor: "#e2e2e2",
                "&:hover": {
                  bgcolor: "#e1e1e1",
                },
              }}
            >
              <X></X>
            </Button>

            <Typography
              id="modal-title"
              variant="h6"
              textAlign="center"
              sx={{
                fontSize: { xs: "1.2rem", sm: "1.4rem" },
                fontWeight: "bold",
                color: "#1a73e8",
                mb: 2,
              }}
            >
              {modalContent.domain_name}
            </Typography>

            <Box
              sx={{
                maxHeight: "60vh",
                overflowY: "auto",
                pr: 1,
                "&::-webkit-scrollbar": { width: "8px" },
                "&::-webkit-scrollbar-track": { bgcolor: "#f1f1f1" },
                "&::-webkit-scrollbar-thumb": {
                  bgcolor: "#1a73e8",
                  borderRadius: "10px",
                },
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                Internship Course/Lab Content:
              </Typography>
              <ul className="list-disc ml-6 mb-4 text-sm">
                {modalContent.content.map((item, index) => (
                  <li className="mb-1" key={index}>
                    {item}
                  </li>
                ))}
              </ul>

              <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                Assessment:
              </Typography>
              <ul className="list-disc ml-6 mb-4 text-sm">
                {modalContent.willDo.map((item, index) => (
                  <li className="mb-1" key={index}>
                    {item}
                  </li>
                ))}
              </ul>

              <Typography sx={{ fontWeight: "bold", fontSize: "1rem" }}>
                What you will get:
              </Typography>
              <ul className="list-disc ml-6 mb-4 text-sm">
                {modalContent.willGet.map((item, index) => (
                  <li className="mb-1" key={index}>
                    {item}
                  </li>
                ))}
              </ul>
            </Box>
          </Box>
        </Modal>

        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white w-[100%] md:w-3/4 lg:w-1/2 p-4 rounded-lg shadow-lg relative">
              <h2 className="text-xl font-bold mb-4">How to get the URL</h2>
              <iframe
                src={`${genai}#toolbar=0`}
                title="PDF"
                className="w-full h-96"
              ></iframe>

              <button
                onClick={handleCloseModal}
                className="absolute top-4 right-4 bg-red-600 text-white py-1 px-3 rounded hover:bg-red-700"
              >
                Close
              </button>
            </div>
          </div>
        )}
      {/* </Box> */}
      {/* <ToastContainer /> */}
    </Box>
  );
};

export default CopyDomainChoose;
