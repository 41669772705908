import React, { useState, useEffect } from "react";
import { IconButton, Badge, Tooltip, Box } from "@mui/material";
import { NotificationsOutlined as NotificationsOutlinedIcon } from "@mui/icons-material";
import { keyframes } from "@emotion/react";
import { styled } from "@mui/material/styles";

// const shake = keyframes`
//   0% { transform: translateX(0); }
//   2% { transform: translateX(1px); }
//   4% { transform: translateX(-1px); }
//   6% { transform: translateX(1px); }
//   8% { transform: translateX(-1px); }
//   10% { transform: translateX(0); }
//   100% { transform: translateX(0); }
// `;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const Overlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  // backgroundColor: "rgba(0, 0, 0, 0.7)",
  zIndex: 1200,
  animation: `${fadeIn} 0.3s ease-in-out`,
});

const SpotlightContainer = styled("div")({
  position: "relative",
  zIndex: 1201,
  display: "inline-block",
});

const SpotlightEffect = styled("div")({
  position: "absolute",
  top: "-4px",
  left: "-4px",
  right: "-4px",
  bottom: "-4px",
  borderRadius: "50%",
  backgroundColor: "lightgreen",
  boxShadow: "0 0 0 9999px rgba(0, 0, 0, 0.7)",
  animation: `${fadeIn} 0.3s ease-in-out`,
  pointerEvents: "none",
  // backgroundC:'red'
});

// const StyledTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   "& .MuiTooltip-tooltip": {
//     backgroundColor: theme.palette.grey[800],
//     fontSize: "0.575rem",
//     padding: "8px 12px",
//     boxShadow: theme.shadows[2],
//     zIndex: 1202,
//     // animation: `${shake} 2.5s ease-in-out infinite`,
//   },
//   "& .MuiTooltip-arrow": {
//     color: theme.palette.grey[800],
//   },
// }));

const NotificationButton = ({ unreadCount = 0, onClick = () => {} }) => {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useEffect(() => {
    if (unreadCount > 0) {
      const timer = setTimeout(() => {
        // setIsHighlighted(true);
        setIsTooltipOpen(true);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [unreadCount]);

  const handleNotificationClick = (event) => {
    event.stopPropagation();
    // setIsHighlighted(false);
    setIsTooltipOpen(false);
    // Pass the event to the parent's onClick handler
    onClick(event);
  };

  const handleOverlayClick = () => {
    // setIsHighlighted(false);
    setIsTooltipOpen(false);
  };

  return (
    <>
      {isHighlighted && <Overlay onClick={handleOverlayClick} />}
      {/* <StyledTooltip
        open={isTooltipOpen}
        title={`You have ${unreadCount} new notifications`}
        arrow
        placement="bottom"
      > */}
        <SpotlightContainer>
          {isHighlighted && <SpotlightEffect />}
          <IconButton
            onClick={handleNotificationClick}
            sx={{
              position: "relative",
              zIndex: 1201,
              "&:hover": {
                // backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <Badge
              badgeContent={unreadCount}
              color="error"
              sx={{
                "& .MuiBadge-badge": {
                  minWidth: "20px",
                  height: "20px",
                  padding: "0 4px",
                },
              }}
            >
              <NotificationsOutlinedIcon />
            </Badge>
          </IconButton>
        </SpotlightContainer>
      {/* </StyledTooltip> */}
    </>
  );
};

export default NotificationButton;
