import React, { useState, useEffect } from "react";
import { TableCell, Tooltip, Link, Box, CircularProgress,Typography } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useTheme } from '@mui/material'; 
import ScheduleIcon from '@mui/icons-material/Schedule';
import { BASE_URL } from "../../services/configUrls";

const isWithinRestrictionPeriod = (startDate, endDate) => {
  if (!startDate || !endDate) return false;

  const currentDate = new Date();
  const restrictionStart = new Date(startDate);
  const restrictionEnd = new Date(endDate);

  return currentDate >= restrictionStart && currentDate <= restrictionEnd;
};
const document_status = [
  {
    status: 2,
    message: "You have completed your courses",
    days: null
  },
  {
    status: 3,
    message: "Access courses using below link",
    days: null
  },
  {
    status: 1,
    message: "Access your courses after",
    days: 5
  },
  {
    status: 1,
    message: "Access your courses after",
    days: 12
  },
  {
    status: 1,
    message: "Access your courses after",
    days: 20
  }
];

// console.log(document_status);



export const DocumentAccessCell = ({ documentData, onDocumentClick ,documentStatus }) => {
  const [isRestricted, setIsRestricted] = useState(false);
  // const [documentStatus, setDocumentStatus] = useState([]);

  useEffect(() => {
    const checkRestriction = () => {
      const restricted = isWithinRestrictionPeriod(
        documentData.documents_restriction_start,
        documentData.documents_restriction_end
      );
      setIsRestricted(restricted);
    };
  

    checkRestriction();
    const interval = setInterval(checkRestriction, 60000);
    return () => clearInterval(interval);
  }, [documentData]);

  // useEffect(() => {

  //   // const fetchCohortDetails = async () => {
      
  //     // }
  //   const fetchDocumentStatus = async () => {

   
  //       if (!cohortId || !domainId) {
  //         console.log("CohortId or DomainId is missing, skipping API call");
  //         return; // Skip fetch if either ID is not available
  //       }
  //     try {
  //       // const formData = new FormData();
  //       // formData.append("cohortId", cohortId);
  //       // formData.append("domainId", domainId);
        
  //       const response = await fetch(`${BASE_URL}/internship/checkStatus`, {
  //         method: 'POST',
  //         headers: {
            
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //         },
  //         body: JSON.stringify({ cohortId, domainId}) // Replace with actual IDs
  //       });
  
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch document status');
  //       }
  
  //       const data = await response.json();
  //       setDocumentStatus(data);
  //     } catch (error) {
  //       console.error('Error fetching document status:', error.message);
  //     }
  //   };
  
  //   fetchDocumentStatus();
  // }, []);

  return (
    // <TableCell
    //   sx={{
    //     fontSize: {
    //       xs: "8px",
    //       sm: "9px",
    //       md: "10px",
    //     },
    //     // padding: {
    //     //   xs: "4px",
    //     //   sm: "8px",
    //     //   md: "12px",
    //     // },
    //     border:'none'
    //   }}
    // >
      <Box sx={{ 
          background: "#fff",
           borderRadius: "10px",
           padding: '6px',
           height: '135px',
           width: "180px",
           boxShadow:3
         }}>
  <Typography sx={{
      fontSize: { xs: "9px", sm: "10px", md: "11px" },
      padding: { xs: "4px", sm: "8px", md: "4px" },
      fontWeight: 'bold',
      display: "flex",
      marginBottom: '10px',
      justifyContent:'center'
    }}>
    {documentStatus?.document_status?.message || ""}
  </Typography>

  <Box sx={{
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 2,
 
  fontSize: { xs: "8px", sm: "9px", md: "11px" },
}}>
{documentStatus?.document_status?.days ? (
  <React.Fragment>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        width: '100%',
      }}
    >
      <span
        style={{
          fontSize: '14px',
          marginBottom: '12px',
          fontWeight: 'bold',
          color: (() => {
            const numericValue = parseInt(documentStatus.document_status.days ?? 0);
            return numericValue <= 5
              ? '#f44336' // Red for values <= 5
              : numericValue <= 15
              ? '#2196f3' // Blue for values <= 15
              : '#4caf50'; // Green for values > 15
          })(),
          padding: '6px 12px',
          width: '100%',
          backgroundColor: (() => {
            const numericValue = parseInt(documentStatus.document_status.days ?? 0);
            return numericValue <= 5
              ? 'rgba(255, 100, 100, 0.2)' // Light red for values <= 5
              : numericValue <= 15
              ? 'rgba(100, 100, 255, 0.2)' // Light blue for values <= 15
              : 'rgba(100, 255, 100, 0.2)'; // Light green for values > 15
          })(),
          borderRadius: '9px',
          backdropFilter: 'blur(10px)', // Glass effect
        }}
      >
        <ScheduleIcon
          sx={{
            marginRight: '5px',
            fontSize: '21px',
            fontWeight: 'bold',
            height: '20px',
            width: '20px',
          }}
        />
        {documentStatus.document_status.days}
      </span>
    </Box>
  </React.Fragment>
) : (
  <p></p>
)}


</Box>

      {documentData.doc_link === "#" ? (
        <Tooltip
          title={
            isRestricted
              ? "Document access is currently restricted"
              : "View Document"
          }
        >
          <Box
            sx={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              cursor: "not-allowed",
              // color: "gray",
              paddingTop:"0 !important",
              justifyContent:'center',
              // marginRight:"10px"
            }}
          >
          <Link
            onClick={() => !isRestricted && onDocumentClick()}
            sx={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              cursor: isRestricted ? "not-allowed" : "pointer",
              color: isRestricted ? "gray" : "inherit",
              opacity: isRestricted ? 0.6 : 1,
            }}
          >
            <PictureAsPdfIcon
              sx={{
                color: isRestricted ? "gray" : "red",
                marginRight: 1,
              }}
            />
            Course Access Document
            {/* <Typography sx={{fontSize: {
                                    xs: "8px",
                                    sm: "9px",
                                    md: "11px",
                                  },
                                  padding: {
                                    xs: "4px",
                                    sm: "8px",
                                    md: "4px",
                                  },
                                  fontWeight: 'bold',}}>
                                  </Typography> */}
          </Link>
          </Box> 
        </Tooltip>
      ) : (
        <Tooltip title="You Will Get Access on 9th January 2025">
          <Box
            sx={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              cursor: "not-allowed",
              // color: "gray",
              paddingTop:"0 !important",
              justifyContent:'center',
              marginRight:"10px"
            }}
          >
            <PictureAsPdfIcon sx={{ color: "gray", marginRight: 1 }} />
            {/* <Typography sx={{fontSize: {
                                    xs: "8px",
                                    sm: "9px",
                                    md: "11px",
                                  },
                                  padding: {
                                    xs: "4px",
                                    sm: "8px",
                                    md: "4px",
                                  },
                                  fontWeight: 'bold',}}>Course Access Document</Typography> */}
                                  Course Access Document
          </Box>
        </Tooltip>
      )}
   </Box>
    // </TableCell>
  );
};
